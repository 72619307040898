import React from 'react';
import { loadCodebook } from '../actions/taskActions';
import { CurrencyAmountField } from '@csas-smart/gti-ui-comps';
import SessionWrapper from '../sessions/sessionWrapper';

const CurrencyAmountFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    const loadCodebookInternal = (codebook, attributes) => {
        return loadCodebook(codebook, attributes, sessionWrapper.getLanguage());
    };

    return <CurrencyAmountField {...props} loadCodebook={loadCodebookInternal} />;
};

export default CurrencyAmountFieldContainer;

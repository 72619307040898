import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import {
    wizardGetActivationQrCode,
    wizardGetActivationComplete,
} from '../../actions/g4bmigrActions';

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui":
 */
const GeorgeIdActivationField = lazy(() =>
    import('@csas-smart/gti-g4bmigr-ui').then((module) => ({
        default: module.GeorgeIdActivationField,
    })),
);

const GeorgeIdActivationFieldContainer = (props) => {
    const dispatch = useDispatch();

    const onActivationQrCodeLoad = () => {
        console.log('GeorgeIdActivationFieldContainer - onActivationQrCodeLoad called');

        return dispatch(wizardGetActivationQrCode());
    };

    const onActivationCompleteCheck = () => {
        console.log('GeorgeIdActivationFieldContainer - onActivationCompleteCheck called');

        return dispatch(wizardGetActivationComplete());
    };

    return (
        <GeorgeIdActivationField
            {...props}
            onActivationQrCodeLoad={onActivationQrCodeLoad}
            onActivationCompleteCheck={onActivationCompleteCheck}
        />
    );
};

export default GeorgeIdActivationFieldContainer;

import React from 'react';
import { PersonInfoListField } from '@csas-smart/gti-ui-comps';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from '../core/utils/taskUtils';

const PersonInfoFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const loadPerson = (inputData) => {
        return RestCaller.httpPost(Resource.getPersonInfo(hashId), inputData);
    };

    return <PersonInfoListField {...props} search={loadPerson} />;
};

export default PersonInfoFieldContainer;

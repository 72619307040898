import React from 'react';
import { LoggingProvider } from './loggingContext';
import logger from '../loggly/gtiLogger';

const LoggableContent = (props) => {
    console.log(props);
    return (
        <LoggingProvider
            value={{
                logger: logger,
            }}
        >
            {props.children}
        </LoggingProvider>
    );
};

export default LoggableContent;

import SessionWrapper from '../../../sessions/sessionWrapper';
import Resource from '../../serverresource';
import gtiLogger from '../../loggly/gtiLogger';

class MepTokenHelper {
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * helper method for storing token data into sessionStorage
     * @param data
     */
    setTokenData = (data: any): Promise<void> => {
        if (!data) {
            return Promise.resolve();
        }

        this.sessionWrapper.setAccessToken(data.accessToken);
        if (data.expiresIn) {
            this.sessionWrapper.setAccessTokenExpiresIn(
                new Date(new Date().getTime() + data.expiresIn * 1000),
            );
        } else {
            gtiLogger.info('cannot resolve expiresIn for value: ' + data.expiresIn);
        }

        Resource.setInterceptor();
        return Promise.resolve();
    };
}

export default MepTokenHelper;

import { Card, ColorDecoration } from '@george-labs.com/design-system';
import React from 'react';

interface Props {
    monthlyRepayment: number;
}
const MonthlyRepaymentCard = ({ monthlyRepayment }: Props) => {
    return (
        <Card>
            <ColorDecoration color={ColorDecoration.COLOR.LIME} />
            <p>
                <strong>Monthly repayment: {monthlyRepayment}</strong>
            </p>
        </Card>
    );
};

export default MonthlyRepaymentCard;

import React, { useEffect, useState } from 'react';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { NgPrintComponent } from '../comp/printing/print-component';
import GdsSpinner from '../core/gds/gds-spinner';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';

export const NgPrintField = (props) => {
    const { field, t, required } = props;
    const { name } = field;
    const hashId = resolveHashIdFromState();
    const recordListAttributeName = props.field.selector.attributes.recordList.name;
    const errorAttributeName = props.field.selector.attributes.error.name;
    const { recordList } = props.attributes;
    const [loading, setLoading] = useState(false);
    //const [selectedDocument, setSelectedDocument] = useState(null);
    //const [recordsList, setRecordsList] = useState([]);

    useEffect(() => {
        //TODO - check for 'needs' of generation call
        setLoading(true);
        const resultArray = [];
        RestCaller.httpGet(Resource.generateRecords(name, hashId))
            .then((records) => {
                console.log('Case records generated: ' + records.length);
                records
                    ?.filter((r) => r.preprint)
                    .forEach((record) => {
                        resultArray.push(record);
                    });
                return Promise.resolve();
            })
            .then(getCaseRecords)
            .then((records) =>
                props.fieldValueChanged({
                    name: recordListAttributeName,
                    value: mergeArrays(resultArray, records),
                }),
            )
            .catch(recordsErrorHandler)
            .finally(() => setLoading(false));
    }, []);

    const mergeArrays = (resultArray, records) => {
        records?.forEach((record) => {
            resultArray.push(record);
        });

        return resultArray;
    };

    const getCaseRecords = () => {
        return RestCaller.httpGet(Resource.getRecords(name, hashId));
    };

    const recordsErrorHandler = (error) => {
        props.fieldValueChanged({ name: errorAttributeName, value: true });
        console.error(error);
    };

    const spinner = (
        <div className="vertically-centered-comp">
            <GdsSpinner />
        </div>
    );

    const component = (
        <>
            <GdsFieldContainer
                field={field}
                t={t}
                required={required}
                hideRequired={true}
                componentId={'ng-print-field'}
            >
                <div className={'field-common-padding'}>
                    <NgPrintComponent records={recordList} field={field} />
                </div>
            </GdsFieldContainer>
        </>
    );

    return loading ? spinner : component;
};

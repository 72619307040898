import React from 'react';
import { SscsConditionsCheckField } from '@csas-smart/gti-bop-ui';
import { useDispatch } from 'react-redux';
import { deleteDocument, uploadFiles } from '../../actions/documentActions';
import { documentDeleted, fireAttrChange } from '../../actions/taskActions';
const SscsConditionsCheckFieldContainer = (props) => {
    const { field } = props;

    const dispatch = useDispatch();

    const uploadConditionFilesHandler = (fieldName, attrName, files) => {
        return uploadFiles(
            field.name,
            attrName,
            files.map((file) => {
                return { file };
            }),
        ).then((result) => {
            return dispatch(fireAttrChange({ name: attrName, value: result, append: true }));
        });
    };

    const deleteConditionDocumentHandler = (attrName, document) => {
        return deleteDocument(field.name, attrName, document).then(() =>
            dispatch(documentDeleted(attrName, document.documentId)),
        );
    };

    return (
        <SscsConditionsCheckField
            {...props}
            uploadDocument={uploadConditionFilesHandler}
            deleteDocument={deleteConditionDocumentHandler}
        />
    );
};

export default SscsConditionsCheckFieldContainer;

import LogglyMessage from './logglyMessage';

class LogglyMessageApi extends LogglyMessage {
    apiData: any;

    constructor(msg) {
        super();
        this.type = 'API';
        this.apiData = msg;
    }
}

export default LogglyMessageApi;

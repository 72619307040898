import SessionWrapper from '../../../sessions/sessionWrapper';
import logger from '../../loggly/gtiLogger';
import MepTokenHelper from '../common/mepTokenHelper';

/**
 * Handler used to handle 'code' request query parameter for accessToken. This token is the one required for web-api communication
 */
class OidcTokenHandler {
    sessionWrapper: SessionWrapper;

    mepTokenHelper: MepTokenHelper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
        this.mepTokenHelper = new MepTokenHelper();
    }

    /**
     * Handler is used if (and only if) there is 'code' request query parameter
     * @param params - Set of params containing caseType, salesChannel etc. By the way there MUST be 'code' parameter too.
     *                 Otherwise handler should not be used.
     * @return {boolean} - true when there exists 'code' query parameteters
     */
    canHandle = (params) => {
        return !!params?.code;
    };

    /**
     * Method get 'code' and 'state' request query parameters and handle 'code' for 'accessToken' from MEP
     * @param params
     * @param oidcRpClient
     * @return {Promise<Promise<void> | Promise<never>>}
     */
    handleParams = (params, oidcRpClient) => {
        logger.debug('OidcTokenHandler execution begins');

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { code, state, session_state } = params;

        //[1] - Prepare sessionStorae flags
        this.sessionWrapper.setLoginCodeReceived();
        const url = `${
            import.meta.env.VITE_FE_APP_URL
        }?code=${code}&state=${state}&session_state=${session_state}`;

        //[2] - Call MEP with 'code' passed in argument 'params'
        return oidcRpClient
            .handleLoginWithRedirectCallback(url)
            .then((response) =>
                response?.type === 'success' ? response : Promise.reject('Unauthorized'),
            )
            .then(this.mepTokenHelper.setTokenData) //[2.a.] - if response type is 'success' then response should contains 'accessToken'
            .then(() => {
                this.sessionWrapper.clearLoginCodeReceived();
                logger.debug('OidcTokenHandler execution finished');
                return Promise.resolve();
            });
    };
}

export default OidcTokenHandler;

import React, { lazy, useEffect, useState } from 'react';
import Resource from '../../core/serverresource';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';
import propertiesloader from '../../core/propertiesloader';
import { resolveHashIdFromState } from '../../core/utils/taskUtils';

// Lazily loading "@csas-smart/gti-corporate-ui":
const SignatoryField = lazy(() =>
    import('@csas-smart/gti-corporate-ui').then((module) => ({ default: module.SignatoryField })),
);

const SignatoryFieldContainer = (props) => {
    const [loading, setLoading] = useState(false);
    const [signatories, setSignatories] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const { field, t, required, fieldValueChanged, completeActivityAction, attributes } = props;
    const showButton = propertiesloader.getProperty(field.editor, 'showAddSignatoryButton');

    const hashId = resolveHashIdFromState();

    useEffect(() => {
        loadSignatories();
    }, []);

    const updateAttribute = async (name: string, value: any) => {
        const attrName = field.selector.attributes[name]?.name;
        if (attrName) {
            return fieldValueChanged({ name: attrName, value: value });
        }
        return Promise.resolve();
    };

    // Uloží do atributu selectedSignatories nové pole
    const signatoriesHandler = (signatoriesData) => {
        console.log(signatoriesData, 'Handler for selectedSignatories');
        updateAttribute('selectedSignatories', signatoriesData);
    };

    const setAllSignatories = (signatoriesData) => {
        console.log(signatoriesData, 'Handler for all signatories');
        updateAttribute('signatories', signatoriesData);
    };

    const addSignatoryHandler = () => {
        updateAttribute('addSignatory', true).then(() =>
            completeActivityAction({ buttonType: 'secondary' }),
        );
    };

    // const setSelectedSignatoriesToComponent = () => {
    //     if(Object.keys(attributes.selectedSignatories).length !== 0) {
    //         if(Object.keys(attributes.searchedPerson).length === 0) {
    //             setSelectedData(attributes.selectedSignatories)
    //         } else {
    //             const data = [...attributes.selectedSignatories, attributes.searchedPerson.cluid];
    //             setSelectedData(data);
    //         }
    //     } else {
    //         if(Object.keys(attributes.searchedPerson).length !== 0) {
    //             console.log(attributes.searchedPerson.cluid, "cluid")
    //             setSelectedData(attributes.searchedPerson.cluid);
    //         }
    //     }
    // }

    const loadSignatories = () => {
        setLoading(true);
        fetch(Resource.getRepresentatives(hashId), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8; Client-Accept-Language=en',
            },
        })
            .then(Resource.checkStatus)
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'response');
                setLoading(false);
                if (attributes.signatories.length === 0) {
                    if (data.value.company.isSelfEmployed) {
                        data.value.company.person.index = 1;
                        setSignatories([data.value.company.person]);
                        setAllSignatories([data.value.company.person]);
                    } else {
                        for (let i = 0; i < data.value.company.representatives.length; i++) {
                            data.value.company.representatives[i].index = i + 1;
                        }
                        setSignatories(data.value.company.representatives);
                        setAllSignatories(data.value.company.representatives);
                    }
                } else {
                    console.log(attributes.searchedPerson);
                    if (Object.keys(attributes.searchedPerson).length === 0) {
                        console.log('searchedPerson is null...');
                        console.log(attributes.signatories);
                        setSignatories(attributes.signatories);
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        attributes.selectedSignatories.length > 0 &&
                            setSelectedData(attributes.selectedSignatories);
                    } else {
                        console.log('searchedPerson is not null...', attributes.searchedPerson);
                        const formattedPerson = {
                            customerId: attributes.searchedPerson.cluid,
                            degree: attributes.searchedPerson.degree,
                            additionalDegree: attributes.searchedPerson.additionalDegree,
                            firstname: attributes.searchedPerson.firstName,
                            lastname: attributes.searchedPerson.surname,
                            birthDate: attributes.searchedPerson.birthDate,
                            index: attributes.signatories.length + 1,
                        };

                        const newSignatories = [...attributes.signatories, formattedPerson];
                        console.log(newSignatories, 'Add formated person to signatories');
                        setSignatories(newSignatories);
                        setAllSignatories(newSignatories);

                        if (attributes.selectedSignatories.length > 0) {
                            console.log(newSignatories, 'Selected data existed...');
                            const newSelectedData = [
                                ...attributes.selectedSignatories,
                                formattedPerson.customerId,
                            ];
                            setSelectedData(newSelectedData);
                        } else {
                            console.log(
                                newSignatories,
                                'No selected data existed, add only new person cluid...',
                            );
                            setSelectedData([formattedPerson.customerId]);
                        }
                    }
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    return (
        <GdsFieldContainer
            t={t}
            field={field}
            required={required}
            componentId={'signatoryComponent'}
        >
            {/*
                A logical error in the company.
                // @ts-ignore */}
            <SignatoryField
                signatories={signatories}
                selected={selectedData}
                loading={loading}
                showAddSignatoryButton={showButton}
                signatoriesHandler={signatoriesHandler}
                addSignatoryHandler={addSignatoryHandler}
            />
        </GdsFieldContainer>
    );
};

export default SignatoryFieldContainer;

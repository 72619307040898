import { GaState } from './types';

const initialState: GaState = {
    isGaActive: undefined,
    externalId: null,
    previousStep: null,
    currentStep: null,
    applicationId: null,
    googleAnalyticsTaskConfiguration: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const gaReducers = (state = initialState, action): GaState => {
    let newState;
    switch (action.type) {
        case 'GA_EXT_ID_LOADED':
            newState = Object.assign({}, { ...state }, { externalId: action.extId });
            return newState;
        case 'GA_APPLICATION_CREATED':
            newState = Object.assign({}, { ...state }, { gaApplicationId: action.applicationId });
            return newState;
        case 'GA_SAVE_PREVIOUS_STEP':
            newState = Object.assign({}, { ...state }, { gaPreviousStep: action.activityName });
            return newState;
        case 'GA_ACTIVITY_LOADED':
            newState = Object.assign({}, { ...state }, { currentStep: action.activityName });
            return newState;
        case 'GA_TASK_CONFIGURATION_LOADED':
            // GA actions are not defined for given GeorgeTask:
            if (action === null) {
                newState = Object.assign({}, { ...state }, { isGaActive: false });
            }
            // GA actions are defined for given GeorgeTask:
            else {
                newState = Object.assign(
                    {},
                    { ...state },
                    { googleAnalyticsTaskConfiguration: action.configuration, isGaActive: true },
                );
            }

            return newState;

        default:
            return state;
    }
};

export default gaReducers;

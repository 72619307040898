import React from 'react';
import TemplateWrapper from '../core/lazy-wrappers/templateWrapper';
import { Alert } from '@george-labs.com/design-system';

/**
 * AlertComp is used by AlertField and ValidationError. It is expects several props according to which it renders styled content.
 * @param {*} props
 *            alertContainerStyle: classNames to style alert container: blue
 *            alertIcon: icon to be displayed in top left corner.
 *            template: text to be displayed wihtin alert container, passed as template.
 *            name: name of the field, to have identifier for component.
 *
 */
const AlertComp = (props) => {
    const { template, name, isStatic, type } = props;

    const evaluateVariant = (alertType) => {
        switch (alertType) {
            case 'INFO':
                return Alert.VARIANT.INFO;
            case 'WARNING':
                return Alert.VARIANT.WARNING;
            case 'ERROR':
                return Alert.VARIANT.ERROR;
        }
    };

    return (
        <Alert variant={evaluateVariant(type)}>
            <p>
                <TemplateWrapper
                    template={template}
                    templateName={'alert-' + name}
                    isStatic={typeof isStatic === 'boolean' ? isStatic : true}
                />
            </p>
        </Alert>
    );
};

export default AlertComp;

import SessionWrapper from '../../sessions/sessionWrapper';

/**
 * USED FOR DEBUG PURPOSES ONLY
 * enhancer sets the 'web-api-cluid' header by value requested as url parameter 'username'.
 * For correct usage it is important to set property VITE_APP_AUTH_HEADER_BTI in current .env file to 'X-Smart-Username' value. Default .env value is empty value.
 */
class WebApiCluid {
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers) {
        if (
            !import.meta.env.VITE_APP_AUTH_HEADER_BTI ||
            !window.sessionStorage.getItem(import.meta.env.VITE_APP_AUTH_HEADER_BTI)
        ) {
            return headers;
        }

        if (!headers) {
            headers = {};
        }

        return {
            ...headers,
            [import.meta.env.VITE_APP_AUTH_HEADER_BTI]: window.sessionStorage.getItem(
                import.meta.env.VITE_APP_AUTH_HEADER_BTI,
            ),
        };
    }
}

export default WebApiCluid;

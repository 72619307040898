import React from 'react';
import { BranchField } from '@csas-smart/gti-ui-comps';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';

const BranchFieldContainer = (props) => {
    const searchBranches = ({ searchText, branchTypes }) => {
        return RestCaller.httpGet(Resource.searchBranches(searchText, branchTypes)).then(
            (response) => response.items,
        );
    };

    const getBranchOrgUnitId = (branchId: string) => {
        return RestCaller.httpGet(Resource.getBranchOrgUnitId(branchId));
    };

    const getBranchId = (orgUnitId: string) => {
        return RestCaller.httpGet(Resource.getBranchId(orgUnitId));
    };

    const getBranch = (branchId: string) => {
        return RestCaller.httpGet(Resource.getBranch(branchId));
    };

    return (
        <BranchField
            {...props}
            searchBranches={searchBranches}
            getBranchOrgUnitId={getBranchOrgUnitId}
            getBranchId={getBranchId}
            getBranch={getBranch}
        />
    );
};

export default BranchFieldContainer;

import React from 'react';
import PropertiesLoader from '../core/propertiesloader';
import { translate } from '../core/i18n';
import authService from '../core/auths/authService';
import { Button, ButtonGroup } from '@george-labs.com/design-system';
import OnceClickableComponentContainer from '../comp/container/once-clickable-component-container';
import { useDispatch } from 'react-redux';
import { fireCaseCancelled } from '../actions/taskActions';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';
import { AppDispatch } from '../core/store';

const LogoutField = (props) => {
    const { field, t, required } = props;

    const buttonLabel = PropertiesLoader.getProperty(props.field.editor, 'buttonLabel', '');
    const buttonLabelTranslated = translate(t, 'field.properties.buttonLabel', buttonLabel);
    const dispatch = useDispatch<AppDispatch>();

    const callHandover = () => {
        return dispatch(fireCaseCancelled(true)).finally(() => {
            // This is common path for both then and catch
            authService.getService().handover();
            // never resolved (due to handover)
            return new Promise(() => {});
        });
    };

    return (
        <GdsFieldContainer field={field} t={t} required={required} hideRequired={true}>
            <OnceClickableComponentContainer
                handleChange={callHandover}
                render={(isDisabled, onClickFnc) => {
                    return (
                        <ButtonGroup>
                            <Button
                                variant={Button.VARIANT.PRIMARY}
                                onClick={() => onClickFnc()}
                                disabled={isDisabled}
                            >
                                {buttonLabelTranslated}
                            </Button>
                        </ButtonGroup>
                    );
                }}
            />
        </GdsFieldContainer>
    );
};

export default LogoutField;

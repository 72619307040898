import React, { lazy, useContext, useEffect, useState } from 'react';
import { resolveCaseTypeFromState, resolveHashIdFromState } from '../../core/utils/taskUtils';
import Resource from '../../core/serverresource';
import FieldContext from '../../core/context/fieldContext';
import RestCaller from '../../core/restCaller';
import { resolveFileExtensions } from '../../core/utils/fileUploadUtils';
import GdsSpinner from '../../core/gds/gds-spinner';
import { getStateFilter, getTypeFilter } from '../../core/utils/conditionFilterUtils';
import GcedrError from './gcedr-error';
import { Col, Row } from '@george-labs.com/design-system';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';

// Lazily loading "@csas-smart/gti-cedr-ui":
const ConditionsCheck = lazy(() =>
    import('@csas-smart/gti-cedr-ui').then((module) => ({ default: module.ConditionsCheck })),
);

const ERROR = {
    SERVICE_UNAVAILABLE: {
        value: 'common:field.gcedr-conditions-check-field.errorMsg',
        type: 'WARNING',
        apology: true,
    },
};

const SERVICE_FEE_CTS = 'gct1421,gct1357,gct1345';

const DEFAULT_FEES = [
    { serviceName: 'gct1421', feeValue: 300 },
    { serviceName: 'gct1345', feeValue: 1500 },
    { serviceName: 'gct1357', feeValue: null },
];

const ConditionsCheckContainer = (props) => {
    const { field, fieldValueChanged, validations, t, required } = props;
    const contextValue = useContext(FieldContext);
    const [data, setData] = useState(null);
    const [services, setServices] = useState();
    const [loading, setLoading] = useState(true);
    const [servicesLoading, setServicesLoading] = useState(true);
    const hashId = resolveHashIdFromState();
    const caseType = resolveCaseTypeFromState();
    const [error, setError] = useState(null);
    const [fees, setFees] = useState([]);

    const extensions = resolveFileExtensions(field);
    const stateFilter = getStateFilter(field);
    const typeFilter = getTypeFilter(field);

    useEffect(() => {
        // notify hashId
        handleChange('taskHashId', hashId);

        fetch(
            `${
                import.meta.env.VITE_APP_GCEDR_RESOURCE_URL
            }/repaymentLoanAgreement/product?hashId=${hashId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            },
        )
            .then(checkStatus)
            .then(Resource.parseJSON)
            .then(function (result) {
                setData(result);
            })
            .catch(function (err) {
                console.error('Error loading loan agreement data', err);
            })
            .finally(() => {
                setLoading(false);
            });

        fetch(
            `${import.meta.env.VITE_APP_GCEDR_RESOURCE_URL}/services/availability?hashId=${hashId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            },
        )
            .then((response) => checkStatus(response, false))
            .then(Resource.parseJSON)
            .then(function (result) {
                setServices(result);
            })
            .catch(function (err) {
                console.error('Error loading available services', err);
                // @ts-ignore
                setServices([]);
            })
            .finally(() => setServicesLoading(false));

        fetch(
            `${
                import.meta.env.VITE_APP_GCEDR_RESOURCE_URL
            }/services/fees/${SERVICE_FEE_CTS}?hashId=${hashId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            },
        )
            .then((response) => checkStatus(response, false))
            .then(Resource.parseJSON)
            .then(function (result) {
                const futureFees = result.map((fee) => ({
                    serviceName: getGeorgeServiceName(fee.serviceId),
                    feeValue: fee.feeValue,
                }));
                setFees(futureFees);
            })
            .catch(function (err) {
                console.error('Error loading fees', err);
                setFees(DEFAULT_FEES);
            });
    }, []);

    useEffect(() => {
        if (data) {
            handleChange(
                'disbursementProcessActive',
                data.repaymentLoanAgreement?.disbursement?.disbursementProcessActive ?? false,
            );
            handleChange(
                'disbursementConditionsDone',
                data.repaymentLoanAgreement?.disbursementConditionsDone ?? false,
            );
            handleChange(
                'postConditionsDone',
                data.repaymentLoanAgreement?.postConditionsDone ?? false,
            );
        }
    }, [data]);

    useEffect(() => {
        handleChange('serviceFees', fees);
    }, [fees]);

    const getGeorgeServiceName = (serviceName) => {
        return 'g' + serviceName.toLowerCase();
    };

    const handleChange = (attrName, attrValue) => {
        const selectedAttribute = field.selector.attributes[attrName];

        if (!selectedAttribute || !selectedAttribute.name) {
            return;
        }

        fieldValueChanged({ name: selectedAttribute.name, value: attrValue });
    };

    const checkStatus = (response, enableError = true) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        if (enableError) {
            setError(ERROR.SERVICE_UNAVAILABLE);
        }

        return response.text().then((text) => {
            throw new Error(text ?? 'Unknown error');
        });
    };

    const uploadFiles = (request) =>
        RestCaller.httpPostWithBinary(
            Resource.uploadDocument(hashId, contextValue.field.name),
            request,
        );
    const deleteFile = (document) =>
        RestCaller.httpDelete(Resource.deleteDocument(field.name, hashId, document));

    if (error) {
        return (
            <Row className="m-3">
                <Col>
                    <GcedrError
                        message={props.t(error.value)}
                        icon={error.icon}
                        t={props.t}
                        apology={error.apology}
                        type={error.type}
                    />
                </Col>
            </Row>
        );
    }

    return (
        <GdsFieldContainer
            field={field}
            t={t}
            required={required}
            componentId="ConditionsCheckField"
        >
            {loading ? (
                <div className="container text-center">
                    <GdsSpinner />
                </div>
            ) : (
                <ConditionsCheck
                    contentData={data.repaymentLoanAgreement}
                    checkedConditions={props.attributes.checkConditions ?? []}
                    onChange={handleChange}
                    onUpload={uploadFiles}
                    onFileDelete={(documentId) => deleteFile({ documentId })}
                    allowedExtensions={extensions}
                    conditionCategory={props.attributes.conditionCategory}
                    validations={validations}
                    fieldName={field.name}
                    typeFilter={typeFilter}
                    stateFilter={stateFilter}
                    caseType={caseType}
                    services={services}
                    orderedServices={props.attributes.servicesConditions}
                    servicesLoading={servicesLoading}
                    serviceFees={fees}
                />
            )}
        </GdsFieldContainer>
    );
};

export default ConditionsCheckContainer;

import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import authService from '../core/auths/authService';

export const fireHandoverToken = () => {
    return () => {
        authService.getService().handover();
    };
};

export const sessionLogin = (cgpHashedId) => {
    return () => {
        return RestCaller.httpPostWithStatusCheck(Resource.sessionLogin(cgpHashedId), null).then(
            (response) => Promise.resolve(response.text()),
        );
    };
};

export const tokenExpiring = () => ({ type: 'TOKEN_EXPIRING' });

export function getActivationQrCode(cluid: string) {
    return () => {
        console.log('Loading qr code.');

        const requestDto = {
            cluid,
            type: 'G4B',
        };

        return RestCaller.httpPost(`${Resource.gbumBaseUrl}/gis/code/create`, requestDto).then(
            (result) => {
                console.log('Response: ', result);
                return Promise.resolve({ activationCode: result.code });
            },
            (error) => {
                console.log('Loading qr code failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function getActivationComplete(cluid: string) {
    return () => {
        console.log('Getting activation status.');
        return RestCaller.httpGet(`${Resource.gbumBaseUrl}/devices/identities/${cluid}`).then(
            (result) => {
                console.log('Response: ', result);
                const activated = result.devices.any(
                    (device) => device.appId === 'GBIZMBA' && device.status === 'ACTIVE',
                );
                return Promise.resolve({ activated });
            },
            (error) => {
                console.log('Getting activation status failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

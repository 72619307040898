import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';
import React, { lazy } from 'react';
import Resource from '../../core/serverresource';

// Lazily loading "@csas-smart/gti-corporate-ui":
const AccountStatementsField = lazy(() =>
    import('@csas-smart/gti-corporate-ui').then((module) => ({
        default: module.AccountStatementsField,
    })),
);

const AccountStatementsFieldContainer = (props) => {
    const { field, t, required, attributes } = props;

    return (
        <GdsFieldContainer
            tooltipKey="tooltipText"
            field={field}
            t={t}
            hideRequired={false}
            required={required}
        >
            <AccountStatementsField
                {...props}
                getStatements={Resource.getAccountStatements}
                getAllowedParams={Resource.getAllowedParams}
                companyEmail={attributes.companyEmail}
                companyAddress={attributes.companyAddress}
            />
        </GdsFieldContainer>
    );
};

export default AccountStatementsFieldContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function GTranslatedComponent(props) {
    const { children, translationType } = props;
    //@ts-ignore
    const { t, i18n } = useTranslation(translationType);

    return React.Children.map(children, (child) =>
        React.createElement(child.type, { ...child.props, t, i18n }),
    );
}

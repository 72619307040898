import React from 'react';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { StartCLCField } from '@csas-smart/gti-corp-landing-ui/dist';

const StartCLCFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const verifyClientAndStartCLC = (registrationNumber: string) => {
        const input = { hashId: hashId, registrationNumber: registrationNumber };
        return RestCaller.httpPurePost(Resource.postSearClientAndStartCLC(), input);
    };

    return <StartCLCField {...props} verifyClientAndStartCLC={verifyClientAndStartCLC} />;
};

export default StartCLCFieldContainer;

import React from 'react';
import { CommentItem, CommentsField, FieldProps } from '@csas-smart/gti-ui-comps';
import { getUserInfo } from '../actions/userActions';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../core/store';

export interface CommentsFieldContainerProps extends FieldProps {
    attributes: {
        comments: CommentItem[];
    };
    getActorFullName: () => Promise<string>;
}

const CommentsFieldContainer = (props: CommentsFieldContainerProps) => {
    const hashId = resolveHashIdFromState();
    const activityName = useSelector<RootState>(
        (state) => state.task.activity.design.name,
    ) as string;

    const getActorFullName = async (): Promise<string> => {
        return getUserInfo(hashId);
    };

    return (
        <CommentsField
            {...props}
            hashId={hashId}
            activityName={activityName}
            getActorFullName={getActorFullName}
        />
    );
};

export default CommentsFieldContainer;

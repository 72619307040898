import React, { useState } from 'react';
import { SscsSelectLoanPurposesField } from '@csas-smart/gti-bop-ui';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';

const SscsSelectLoanPurposesFieldContainer = (props) => {
    const { field, t, required } = props;
    const [isVisible, setIsVisible] = useState(true);

    const hideField = () => {
        setIsVisible(false);
    };

    return isVisible ? (
        <GdsFieldContainer
            field={field}
            t={t}
            required={required}
            componentId={'sscsSelectLoanPurposesFieldContainer'}
        >
            <SscsSelectLoanPurposesField {...props} hideField={hideField} />
        </GdsFieldContainer>
    ) : null;
};

export default SscsSelectLoanPurposesFieldContainer;

import Field from '../field';
import React from 'react';
import SessionWrapper, { GSTATE_JSON_TYPE } from '../../sessions/sessionWrapper';
import { Col, Row } from '@george-labs.com/design-system';

export function resolveHashIdFromState(): string {
    return new SessionWrapper().getHashId();
}

export function resolveCaseTypeFromState() {
    return new SessionWrapper().getCaseType();
}

export function resolveRedirectUriFromState() {
    return new SessionWrapper().getGState(GSTATE_JSON_TYPE)?.redirectUri;
}

//functions Detects IE
export const detectIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
};

export const getActivityFieldsByLocation = (
    activity,
    location,
    completeAction,
    validateActivity,
) => {
    return activity.fields
        .filter((field) => field.location === location)
        .map((field) => (
            <Field
                field={field}
                key={field.name}
                completeActivityAction={completeAction}
                validateActivityAction={validateActivity}
                location={location}
            />
        ));
};

export const getFieldsByLocation = (activity, location) => {
    return activity.fields.filter((field) => field.location === location);
};

export const toFieldElement = (field, completeAction, validateActivity) => {
    return (
        <Field
            field={field}
            key={field.name}
            completeActivityAction={completeAction}
            validateActivityAction={validateActivity}
            location={location}
        />
    );
};

export const toFieldsRowElement = (fields, completeAction, validateActivity) => {
    return (
        <Row>
            {fields.map((field) => {
                return (
                    <Col className={'form-group col-sm-' + field.inSingleRowWidth}>
                        {toFieldElement(field, completeAction, validateActivity)}
                    </Col>
                );
            })}
        </Row>
    );
};

/**
 * Iterate list of fields and put any consequent fields with not-null inSingleRowWidth into one <Row /> element.
 * @param fields
 * @param completeAction
 * @param validateActivity
 */
export const createFieldGroups = (fields, completeAction, validateActivity) => {
    const groupedFields = [];

    let currentGroup = [];

    for (const field of fields) {
        if (field.inSingleRowWidth) {
            // field is in row with other fields, save it in current group array
            currentGroup.push(field);
        } else {
            // single element
            if (currentGroup.length > 0) {
                // current group contains fields, add this group to result
                groupedFields.push(
                    toFieldsRowElement(currentGroup, completeAction, validateActivity),
                );
                currentGroup = [];
            }
            groupedFields.push(toFieldElement(field, completeAction, validateActivity));
        }
    }

    // if there is trailing group of single row fields, add it to result
    if (currentGroup.length > 0) {
        // current group contains fields, add this group to result
        groupedFields.push(toFieldsRowElement(currentGroup, completeAction, validateActivity));
    }

    return groupedFields;
};

import authService, { ALLOWED_ADFS_SALES_CHANNELS } from '../auths/authService';
import SessionWrapper from '../../sessions/sessionWrapper';
import { isUrlMep } from '../utils/urlUtils';

/**
 * enhancer sets the 'web-api-key' header to web-api-key resolved
 */
class WebApiKey {
    HEADER_NAME = 'web-api-key';

    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers, requestedUrl) {
        if (!headers) {
            headers = {};
        }

        if (isUrlMep(requestedUrl)) {
            return headers;
        }

        const resolvedAuthService = authService.getService();
        const resolvedApiKey = resolvedAuthService
            ? resolvedAuthService.getSupportedApiKey()
            : null;
        if (resolvedApiKey) {
            return { ...headers, [this.HEADER_NAME]: resolvedApiKey };
        }

        const isAdfs =
            ALLOWED_ADFS_SALES_CHANNELS.indexOf(this.sessionWrapper.getSalesChannel()) > -1;
        const adfsStarter = this.sessionWrapper.supportsAdfsTokenStarter();
        let apiKeyValue = '';
        if (isAdfs) {
            apiKeyValue = adfsStarter
                ? import.meta.env.VITE_APP_SES_API_KEY
                : import.meta.env.VITE_APP_BTI_API_KEY;
        } else {
            apiKeyValue = import.meta.env.VITE_APP_API_KEY;
        }

        return { ...headers, [this.HEADER_NAME]: apiKeyValue };
    }
}

export default WebApiKey;

import React, { lazy } from 'react';

const CashCompositionField = lazy(() =>
    import('@csas-smart/gti-corporate-ui').then((module) => ({
        default: module.CashCompositionField,
    })),
);

const CashCompositionFieldContainer = (props) => {
    return <CashCompositionField {...props} />;
};

export default CashCompositionFieldContainer;

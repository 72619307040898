import React from 'react';
import { AddressFormField } from '@csas-smart/gti-ui-comps';
import Resource from '../core/serverresource';

/**
 * Component representing a address-form field. For additional info please visit: https://gti-library-int.csint.cz/#/fields/togglegroup-field
 */
const AddressFormFieldContainer = (props) => {
    const loadCountries = async () => {
        const result = await fetch(Resource.getCodebook('CB_Country'), {
            method: 'POST',
            body: JSON.stringify(['CERTIS']),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .catch(function (error) {
                console.log('request failed', error.statusText);
            });
        return result;
    };

    return (
        <div className="alerts-field">
            <AddressFormField {...props} loadCountries={loadCountries} />
        </div>
    );
};

export default AddressFormFieldContainer;

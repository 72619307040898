import React, { lazy } from 'react';
import { Col, Row } from 'reactstrap';
import { RuianComp } from '../../comp/ruian/ruian-comp';
import { AddressFormComp } from '../../comp/ruian/address-form-comp';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';

// Lazily loading "@csas-smart/gti-corporate-ui":
const ButtonWrapper = lazy(() =>
    import('@csas-smart/gti-corporate-ui').then((module) => ({ default: module.ButtonWrapper })),
);

export const RuianField = ({ field, t, validations, required, ...props }) => {
    const onAddressChange = (address) => {
        props.fieldValueChanged({ name: field.selector.attributes.address.name, value: address });
    };

    const onManualChange = (manual) => {
        props.fieldValueChanged({
            name: field.selector.attributes.manualAddress.name,
            value: manual,
        });
        if (!manual) {
            props.fieldValueChanged({ name: field.selector.attributes.address.name, value: {} });
        }
    };

    const ruianComponent = !props.attributes.manualAddress && (
        <GdsFieldContainer field={field} t={t} hideRequired={false} required={required}>
            <Row>
                <Col sm={12}>
                    <RuianComp
                        id={field.name}
                        address={props.attributes.address}
                        t={t}
                        validations={validations}
                        fieldName={field.selector.attributes.address.name}
                        onChange={onAddressChange}
                        required={required}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    sm={12}
                    className={
                        'd-flex justify-content-end cancel-vertical-paddings card-block form-group ml-3 mr-3'
                    }
                >
                    <ButtonWrapper variant="tertiary" onClick={() => onManualChange(true)}>
                        {t('field.properties.labels.manual')}
                    </ButtonWrapper>
                </Col>
            </Row>
        </GdsFieldContainer>
    );

    const addressFormComponent = props.attributes.manualAddress && (
        <>
            <AddressFormComp
                defaultAddress={props.attributes.address}
                fieldName={field.selector.attributes.address.name}
                t={t}
                validations={validations}
                onChange={onAddressChange}
            />
            <div className="d-flex justify-content-end cancel-vertical-paddings card-block form-group ml-3 mr-3">
                <ButtonWrapper variant="tertiary" onClick={() => onManualChange(false)}>
                    {t('field.properties.labels.automatic')}
                </ButtonWrapper>
            </div>
        </>
    );

    return (
        <>
            {ruianComponent}
            {addressFormComponent}
        </>
    );
};

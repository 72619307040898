/**
 * Function that tries to find a card number in a text and then masks it in the following format: 520136XXXXXX5248.
 * @param text Text that potentially might contain a card number.
 */
export const maskCardNumber = (text: string) => {
    return text.replace(
        /\b(\d{4}\s*\d{2}\s*)(\d{2})(\s*)(\d{4})(\s*\d{4})\b/,
        function (match, p1, p2, p3, p4, p5) {
            return `${p1}XX${p3}XXXX${p5}`;
        },
    );
};

import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { Button } from '@george-labs.com/design-system';
import { isMobileApp } from '../core/utils/salesChannelUtils';
import PropertiesLoader from '../core/propertiesloader';
import { isNotEmptyString } from 'validations';
import { AttributeChange, Field } from '@csas-smart/gti-ui-comps';
import { ButtonProps } from '../types/design';
import { AsyncOnceClickableComponentContainerControls } from '@csas-smart/gti-ui-comps';
import gtiLogger from '../core/loggly/gtiLogger';

interface SignButtonProps {
    field: Field;
    fieldValueChanged: (arg: AttributeChange) => Promise<void>;
    option: ButtonProps;
    onSignedHandler: (evt) => Promise<void>;
    validate: () => boolean;
    completeActivityAction: () => Promise<void>;
    disabled: boolean;
    containerControls: AsyncOnceClickableComponentContainerControls;
}
const SignButton = ({
    field,
    fieldValueChanged,
    option,
    disabled,
    onSignedHandler,
    completeActivityAction,
    validate,
    containerControls,
}: SignButtonProps) => {
    const [customAuthorizationOriginApplication, setCustomAuthorizationOriginApplication] =
        useState<boolean>(false);

    const [authorizationHeaders, setAuthorizationHeaders] = useState({});

    /**
     * useEffect for loading custom authorizationOriginApplication from field editor properties and setting up:
     * customAuthorizationOriginApplication flag and authorizationHeaders if needed
     */
    useEffect(() => {
        const authorizationSettings = PropertiesLoader.getProperty(field.editor, 'authorization');
        const toBeCustomAuthorizationOriginApplication =
            authorizationSettings && authorizationSettings.authorizationOriginApplication;
        setCustomAuthorizationOriginApplication(toBeCustomAuthorizationOriginApplication);
        if (toBeCustomAuthorizationOriginApplication) {
            const toBeAuthorizationHeaders = {};
            toBeAuthorizationHeaders['origin-application-system'] =
                authorizationSettings.authorizationOriginApplication;
            toBeAuthorizationHeaders['application-system'] =
                authorizationSettings.authorizationOriginApplication;
            setAuthorizationHeaders(toBeAuthorizationHeaders);
        }
    }, []);

    const authIdAttributeName = field.selector.attributes.authId.name;

    // For applications run within the webview, the sign URL retrived from auth module must be enhanced by the following suffix: https://cnfl.csin.cz/pages/viewpage.action?pageId=1037238762
    const APP_AUTH_SUFFIX = '&mobileDevice=1';

    const [authId, setAuthId] = useState(null);

    //signUrl is returned on response of calling sign auths operation
    //signUrl will be used in iFrame
    const [signUrl, setSignUrl] = useState(null);

    //pollUri is used for polling state of authorized operation
    //pollUri is returned by calling sign auths operation
    const [pollUri, setPollUri] = useState(null);
    const [pollInterval, setPollInterval] = useState(0);

    //param is set if and only if SIGN polling result is received
    const [signed, setSigned] = useState(false);

    let pollTimeout = null;

    const hashId = resolveHashIdFromState();

    /**
     * This useEffect handle change of 'pollUri' and 'pollInterval' state-properties. If they are both set to valid values
     * (pollUri is something like '/v1/smart/...' and pollInterval > 0) then polling-state-timeout will be set and check
     * authorization state.
     *
     * If both properties are cleared then timeout is cleared (for non-cycling polling calls)
     */
    useEffect(() => {
        if (pollUri && pollInterval > 0) {
            setPollTimeout();
        } else if (!pollUri && pollInterval === 0) {
            clearTimeout(pollTimeout);
        }
    }, [pollUri, pollInterval]);

    /**
     * This useEffect handle change of 'signed' state-property. If it change to true then call completeActivity.
     * Otherwise 'just do nothing'
     */
    useEffect(() => {
        if (signed) {
            if (onSignedHandler) {
                fieldValueChanged({ name: authIdAttributeName, value: authId })
                    //close popup after case is signed
                    .then(clearSignUrl);

                const evt = {
                    target: { value: option.key, getAttribute: () => option.buttonType },
                };
                onSignedHandler(evt).then(clearContainerDisabled);
            } else {
                fieldValueChanged({ name: authIdAttributeName, value: authId })
                    .then(completeActivityAction)
                    //close popup after case is signed
                    .then(clearSignUrl)
                    .then(clearContainerDisabled);
            }
        }
    }, [signed]);

    const clearContainerDisabled = () => {
        if (!containerControls || !containerControls.clearContainerDisabled) {
            gtiLogger.warn('Cannot work with container controls');
            return Promise.resolve();
        }

        containerControls.clearContainerDisabled();
    };

    const setContainerDisabled = () => {
        if (!containerControls || !containerControls.setContainerDisabled) {
            gtiLogger.warn('Cannot work with container controls');
            return Promise.resolve();
        }

        containerControls.setContainerDisabled();
    };

    const clearSignUrl = () => {
        setSignUrl(null);
    };

    /**
     * handler is executed on timeout event occured. It checks authorization state and if it is SIGNED then
     * * stop timeout handler
     * * close signing popup
     * * complete current activity
     */
    const callPoll = () => {
        //TODO - az bude znam vystupni objekt, zvalidovat stav auth operace a v pripade SIGNED
        RestCaller.httpGet(Resource.pollUrl(pollUri), authorizationHeaders)
            .then((result) => {
                //state = WAITING_FOR_SIGN
                //state = PROCESSING
                //state = EXPIRED
                //state = REJECTED
                //signed ??? Fnuk ...

                if (result && result.state == 'DONE') {
                    //for correct stop of timeout handling (usage of useEffect [pollUri, pollInterval])
                    setPollInterval(0);
                    setPollUri(null);

                    //for correct complete activity (usage of useEffect [signed])
                    setSigned(true);
                } else if (result && result.state == 'REJECTED') {
                    RestCaller.httpDelete(Resource.cancelAuth(authId, hashId))
                        .then(closePopupInternal)
                        .then(clearContainerDisabled);
                } else {
                    resetTimeout();
                    clearContainerDisabled();
                }
            })
            .catch(clearContainerDisabled);
    };

    const setPollTimeout = () => {
        if (pollInterval <= 0) {
            console.error('Zero or negated value of interval: ' + pollInterval);
            return;
        }

        pollTimeout = setTimeout(callPoll, pollInterval);
    };

    const resetTimeout = () => {
        clearTimeout(pollTimeout);
        setPollTimeout();
    };

    const callSignCase = () => {
        const valid = validate();
        console.log('Valid: ' + valid);
        if (!valid) {
            return;
        }

        setContainerDisabled();

        RestCaller.httpPost(Resource.createAuth(hashId), null)
            .then((result) => {
                //please do not use destruct form - hashId name was not good choice :)
                const theAuthId = result.id;
                setAuthId(theAuthId);

                RestCaller.httpGet(
                    Resource.signAuth(
                        theAuthId,
                        result.hashId,
                        customAuthorizationOriginApplication,
                    ),
                    authorizationHeaders,
                )
                    .then((outcome) => {
                        let urlForSign = outcome.signingUrl;
                        if (isMobileApp()) {
                            console.log('Extending URL to MEP');
                            urlForSign += APP_AUTH_SUFFIX;
                        }
                        setSignUrl(urlForSign);

                        //Z nejakeho duvodu se na responsi vraci url se znakem ':' na konci
                        const pollUriValue = outcome.poll.url.endsWith(':')
                            ? outcome.poll.url.substring(0, outcome.poll.url.length - 1)
                            : outcome.poll.url;
                        setPollUri(pollUriValue);

                        const newPollInterval = isNaN(outcome.poll.interval)
                            ? 5000
                            : outcome.poll.interval;
                        setPollInterval(newPollInterval);
                    })
                    .catch(clearContainerDisabled);
            })
            .catch(clearContainerDisabled);
    };

    const closePopupInternal = () => {
        //for correct stop of timeout handling (usage of useEffect [pollUri, pollInterval])
        setPollInterval(0);
        setPollUri(null);

        //for correct close sign popup
        setSignUrl(null);
    };

    return (
        <>
            <Button
                buttonType={option.buttonType}
                onClick={callSignCase}
                variant={Button.VARIANT.PRIMARY}
                value={option.key}
                disabled={disabled}
            >
                {option.label}
            </Button>

            <Modal
                isOpen={isNotEmptyString(signUrl)}
                backdrop={'static'}
                wrapClassName="g-bs4 g-bootstrap g-store fontsize14"
                modalClassName="g-modal"
                size={'xl'}
            >
                <ModalBody className="g-modal-body-full justify-content-center">
                    <iframe src={signUrl} className={'signIFrame'}></iframe>
                </ModalBody>
            </Modal>
        </>
    );
};

export default SignButton;

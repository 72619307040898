import { resolveHashRoute } from '../../utils/queryUtils';
import { generateState } from '../../utils/objectUtils';
import { getLocale } from '../../i18n';
import SessionWrapper from '../../../sessions/sessionWrapper';
import logger from '../../loggly/gtiLogger';

/**
 * Handler which is used to obtain code id token from MEP
 */
class OidcCodeHandler {
    oidcRpClient;

    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * Can be used only if url query params does NOT contains 'code'
     * @param params - Set of query params passed from url
     * @return {boolean} - true when params.code exists
     */
    canHandle = (params) => {
        return !params?.code;
    };

    /**
     * Method should be used only if method 'canHandle(...)' returns true. It means that params does not contains 'code' parameter.
     * Method will call MEP api through passed oidcRpClient second argument with request body containing informations about
     *  redirectUri - where should be client redirected after the 'code' will be generated. Is used on response "Location" header
     *  state - application state which will be added after the redirectUri/
     *  uiLocales - language mutation of given application
     * @param params
     * @param oidcRpClient
     * @return {Promise<void>}
     */
    handleParams = (params, oidcRpClient) => {
        logger.debug('OidcCodeHandler execution begins');

        const { lang } = params;

        //[1] - clear sessionStorage when accessToken exists and 'code' needs to be handled
        this.sessionWrapper.clearAccessToken();
        this.sessionWrapper.setRouteRedirect(resolveHashRoute(window.location.hash));

        //[2] - Build request for code id call into MEP
        const options = {
            redirectUri: import.meta.env.VITE_FE_APP_URL,
            state: generateState(params),
            loginHint: undefined,
            uiLocales: lang ? lang : getLocale(),
            scope: 'openid',
        };

        //[3] - Call for code id to the MEP. Result of this call contains "Location" header which redirects user to the "redirectUri"
        //      cotaining 'state' parameter too.
        return oidcRpClient.loginWithRedirect(options);
    };
}

export default OidcCodeHandler;

import React, { Suspense, useContext } from 'react';
import { DeviceContext, DeviceContextProps } from '@csas-smart/gti-ui-comps';

const GeorgeChat = React.lazy(() => import('../george-chat'));

const GeorgeChatWrapper = (props) => {
    const contextValue = useContext<DeviceContextProps>(DeviceContext);
    if (contextValue.isDesktop) {
        return (
            <Suspense fallback={null}>
                <GeorgeChat chatType={props.chatType} />
            </Suspense>
        );
    }
    return null;
};

export default GeorgeChatWrapper;

import { ADFS_CLIENT } from '../adfs2019service';

import Resource from '../../serverresource';
import SessionWrapper from '../../../sessions/sessionWrapper';
import { decodeBase64 } from '../../utils/objectUtils';
import RefreshTokenHandler from './refreshTokenHandler';
import AdfsTokenHelper from '../common/adfsTokenHelper';

/**
 * Handler is used in case of adfsCodeHandler was successfully processed and another step is get accessToken and link it
 * with current cluid parameter in state.
 */
class AdfsTokenHandler {
    sessionWrapper: SessionWrapper;

    refreshTokenHandler: RefreshTokenHandler;

    adfsTokenHelper: AdfsTokenHelper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
        this.refreshTokenHandler = new RefreshTokenHandler();
        this.adfsTokenHelper = new AdfsTokenHelper();
    }

    /**
     * Handler can be used if and only if there is the 'code' URL parameter and there is NOT 'access_token' URL parameter at the same time.
     * Ofcourse there must not be accessToken stored in sessionStorage.
     * @param params
     * @returns {*|boolean}
     */
    canHandle = (params) => {
        return params?.code && !params?.access_token && !this.sessionWrapper.getAccessToken();
    };

    /**
     * Handler takes the 'code' URL parameter and call ADFS for get accessToken. When it receives token successfully then
     * it link 'cluid' parameter through OBO enpoint and refresh accessToken
     * @param params
     * @returns {Promise<void>}
     */
    handleParams = (params) => {
        const { state } = params;
        const decodedState = decodeBase64(state);
        const { hashroute } = decodedState;
        this.sessionWrapper.setLoginCodeReceived();

        return this.handleAccessToken(params.code)
            .then(() => this.handleObo(decodedState))
            .then(() => this.refreshTokenHandler.handleRefresh())
            .then(() => {
                this.sessionWrapper.clearLoginCodeReceived();
                const urlParamSeparator = hashroute?.indexOf('?') > -1 ? '&' : '?';
                window.location.replace(
                    `${
                        import.meta.env.VITE_APP_BASE_URL
                    }${hashroute}${urlParamSeparator}state=${state}`,
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /**
     * Helper method for API call for receiving accessToken
     * @param code
     * @returns {Promise<void>}
     */
    handleAccessToken = (code) => {
        const request = {
            code: code,
            clientId: ADFS_CLIENT,
            scope: 'openid',
            redirectUri: import.meta.env.VITE_FE_APP_URL,
        };

        return fetch(Resource.getSesToken(), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'web-api-key': import.meta.env.VITE_APP_BTI_API_KEY,
            },
            method: 'POST',
            body: JSON.stringify(request),
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(this.adfsTokenHelper.setTokenData)
            .catch((err) => {
                console.error('Error adfs token', err);
            });
    };

    /**
     * Helper method for api call to link cluid to accessToken
     * @param params
     * @returns {Promise<Response>}
     */
    handleObo = (params) => {
        const { cluid, hashid } = params;
        if (!cluid && !hashid) {
            return fetch(Resource.deleteObo(), { method: 'DELETE' });
        }

        return fetch(Resource.getOboUrl(hashid), {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            body: cluid ? JSON.stringify(cluid) : null,
        });
    };
}

export default AdfsTokenHandler;

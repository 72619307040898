import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import {
    checklistSearchCrm,
    checklistGetCountries,
    checklistStoreSignatoryPersons,
} from '../../actions/g4bmigrActions';

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui":
 */
const GenerateAndSignDocField = lazy(() =>
    import('@csas-smart/gti-g4bmigr-ui').then((module) => ({
        default: module.GenerateAndSignDocField,
    })),
);

const GenerateAndSignDocFieldContainer = (props) => {
    const dispatch = useDispatch();

    const onSearch = (requestDto) => {
        console.log('GenerateAndSignDocFieldContainer - onSearch called');

        return dispatch(checklistSearchCrm(requestDto));
    };

    const getCountries = () => {
        console.log('GenerateAndSignDocFieldContainer - getCountries called');

        return dispatch(checklistGetCountries());
    };

    const updateSignatories = (requestDto) => {
        console.log('GenerateAndSignDocFieldContainer - updateSignatories called');

        return dispatch(checklistStoreSignatoryPersons(requestDto));
    };

    return (
        <GenerateAndSignDocField
            {...props}
            onSearch={onSearch}
            getCountries={getCountries}
            updateSignatories={updateSignatories}
        />
    );
};

export default GenerateAndSignDocFieldContainer;

import Resource from './serverresource';

//import {showError} from '../actions/taskActions'

class RestCaller {
    constructor() {
        console.debug('Rest caller initialization');
    }

    httpPureGet(url) {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        });
    }

    httpGet(url, headers = {}) {
        if (!headers['Content-Type']) {
            headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpGetWithBinary(url) {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(Resource.checkStatus);
    }

    httpPost(url, request, additionalHeaders = {}) {
        const headers = Object.assign(
            {
                'Content-Type': 'application/json; charset=utf-8',
            },
            additionalHeaders,
        );

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: headers,
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpPurePost(url, request, additionalHeaders = {}) {
        const headers = Object.assign(
            {
                'Content-Type': 'application/json; charset=utf-8',
            },
            additionalHeaders,
        );

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: headers,
        });
    }

    httpPostWithoutBody(url, additionalHeaders = {}) {
        const headers = Object.assign(
            {
                'Content-Type': 'application/json; charset=utf-8',
            },
            additionalHeaders,
        );

        return fetch(url, {
            method: 'POST',
            headers: headers,
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpPostWithoutResponse(url, request) {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(Resource.checkStatus);
    }

    httpPostWithLang(url, request, lang) {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                language: lang,
                'accept-language': lang,
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpRequestWithBinary({
        method,
        url,
        request,
        onProgressChange,
    }: {
        method: 'POST' | 'PUT';
        url: string;
        request: FormData;
        onProgressChange?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    }) {
        // Unfortunately upload progress cannot be implemented via native fetch function, hopefully in the near future
        if (!onProgressChange) {
            return fetch(url, {
                method,
                body: request,
            })
                .then(Resource.checkStatus)
                .then(Resource.parseJSON);
        }

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';
            xhr.upload.onprogress = onProgressChange;
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject();
                }
            };
            xhr.open(method, url, true);
            xhr.send(request);
        });
    }

    httpPostWithBinary(
        url: string,
        request: FormData,
        onProgressChange?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void,
    ) {
        return this.httpRequestWithBinary({
            method: 'POST',
            url,
            request,
            onProgressChange,
        });
    }

    httpPutWithBinary(
        url: string,
        request: FormData,
        onProgressChange?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void,
    ) {
        return this.httpRequestWithBinary({
            method: 'PUT',
            url,
            request,
            onProgressChange,
        });
    }

    httpPostWithStatusCheck(url, request) {
        return fetch(url, {
            method: 'POST',
            body: request,
        });
    }

    httpPostWithUrlEncodedFormData(url, request) {
        return fetch(url, {
            method: 'POST',
            redirect: 'follow',

            //URLSearchParams should automatically add "Content-Type": "application/x-www-form-urlencoded" header
            body: new URLSearchParams(request),
        });
    }

    httpPurePut(url, request) {
        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(Resource.checkStatus);
    }

    httpPut(url, request) {
        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpPutWithoutBody(url) {
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(Resource.checkStatus);
    }

    httpDelete(url) {
        return fetch(url, {
            method: 'DELETE',
        }).then(Resource.checkStatus);
    }
}

export default new RestCaller();

import React from 'react';
import { FocusPage, Grid } from '@george-labs.com/design-system';

// Lazy loaded GDS dependency
/*
const StripeHeader = lazy(() => import("@george-labs.com/design-system"), "StripeHeader")
const FocusPage = lazy(() => import("@george-labs.com/design-system"), "FocusPage")
const StripeTitle = lazy(() => import("@george-labs.com/design-system"), "StripeTitle")
const Button = lazy(() => import("@george-labs.com/design-system"), "Button")
// const BUTTON_VARIANT_TERTIARY = React.lazy(() => import("@george-labs.com/design-system").then(module => ({ default: module.Button.VARIANT.TERTIARY })))
*/

/**
 * The wrapper around 'FocusPage' from GDS. Purpose of the wrapper is to lazy load GDS in order to separate GDS to its own package.
 *
 * @param {ReactElement} children    Content of the FocusPage.
 * @param activity {object}          Activity object.
 */
const GdsFocusPage = ({ children, activity }) => {
    return activity.pageWidth === 'extra-large' ? (
        <Grid fluid>{children}</Grid>
    ) : (
        <FocusPage size={activity.pageWidth || FocusPage.SIZE.LARGE} stripeHeader={null}>
            {children}
        </FocusPage>
    );
};

export default GdsFocusPage;

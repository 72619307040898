/**
 * This file should contain functions, that are shared among multiple components.
 * Usually these functions should contain general service calls to Smart backend with additional logic.
 */

import Resource from '../core/serverresource';

/**
 * Function adds n-days to the given initial date and returns new date in Long format.
 * As-is, function works only with labour units (work units can be easily achieved on FE, no need to make http call). In grave need, BE can be easily changed to
 * work with calendar units as well.
 *
 * @param initialDate Long, initial date in milliseconds
 * @param duration Number, dates to be added to initial date
 * @return Object, {resultDate: calculated date}
 */

export function addLabourDays(initialDate, duration) {
    return new Promise((resolve) => {
        const difference = duration < 0 ? '-P' + duration * -1 + 'D' : 'P' + duration + 'D';
        return callCalendar(initialDate, difference, resolve);
    });
}

function callCalendar(initialDate, duration, resolve) {
    fetch(Resource.addDays(initialDate, duration), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
    })
        .then(Resource.checkStatus)
        .then(Resource.parseJSON)
        .then(function (result) {
            resolve(result);
        });
}

/**
 * Stable sorting algorithm to move all the primary buttons to the beggining.
 */
export const movePrimaryToBegin = (a, b) => {
    return movePrimaryToEnd(a, b) * -1;
};

/**
 * Stable sorting algorithm to move all the primary buttons to the end.
 */
export const movePrimaryToEnd = (a, b) => {
    if (a.buttonType === 'primary' && b.buttonType === 'secondary') {
        return 1;
    } else if (a.buttonType === 'secondary' && b.buttonType === 'primary') {
        return -1;
    }
    return 0;
};

/**
 * Finds filename from response headers.
 * @returns string filename
 */
export const resolveFilenameFromResponseHeaders = (response) => {
    let filename;

    const headersArray: [string, string][] = Array.from(response.headers.entries());

    for (const pair of headersArray) {
        const [key, value] = pair;
        if (key.toLowerCase() === 'content-disposition') {
            const filenamePairFull: string = value.substring(value.indexOf('filename*'));

            if (filenamePairFull) {
                const filenameValue: string = filenamePairFull.split('=')[1];
                if (filenameValue) {
                    filename = filenameValue.substring(filenameValue.indexOf("''") + 2);
                }
            }
        }
    }

    return filename;
};

// @ts-nocheck
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { fireAttrChange } from './taskActions';
import AdfsTokenHandler from '../core/auths/adfs2019/adfsTokenHandler';
import logger from '../core/loggly/gtiLogger';
import { isEmptyString } from 'validations';

export function wizardStoreDispositionRights(requestDto) {
    return (dispatch) => {
        console.log('Loading disposition rights.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPurePut(
            Resource.wizardStoreDispositionRights(hashId),
            requestDto,
        ).then(
            () => {
                console.log('Loading disposition rights finished');
                dispatch(saveCaseData(true));
                return Promise.resolve();
            },
            (error) => {
                console.log('Saving disposition rights failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function wizardGetActivationQrCode() {
    return () => {
        console.log('Loading qr code.');

        const requestDto = {};

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.wizardGetActivationQrCode(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Loading qr code failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function wizardGetActivationComplete() {
    return () => {
        console.log('Getting activation status.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.wizardGetActivationComplete(hashId)).then(
            (result) => {
                console.log('Response: ', result);
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting activation status failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function wizardFolderSign(requestDto) {
    return (dispatch) => {
        console.log('Folder signing.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPurePut(Resource.wizardFolderSign(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Folder signing failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function wizardContractsStatus(requestDto) {
    return (dispatch) => {
        console.log('Getting contracts status.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.wizardContractsStatus(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting contracts status failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function wizardGetDevices(attrNameDevices) {
    return (dispatch) => {
        console.log('Getting devices.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.wizardGetDevices(hashId)).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(
                    fireAttrChange({ name: attrNameDevices, value: result.devices, append: false }),
                );
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting devices failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function migrationActivateDevice(requestDto) {
    return (dispatch) => {
        console.log('Activating device.');
        const { deviceIds } = requestDto;

        return RestCaller.httpPost(Resource.migrationActivateDevice(deviceIds)).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Activating device failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function migrationGetPollingInfo(requestDto) {
    return (dispatch) => {
        console.log('Getting polling info.');
        const { deviceIds, signId } = requestDto;

        return RestCaller.httpGet(Resource.migrationGetPollingInfo(deviceIds, signId)).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting polling info failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function migrationGetPollStatus(requestDto) {
    return (dispatch) => {
        console.log('Getting poll status.');
        const { pollId } = requestDto;

        return RestCaller.httpGet(Resource.migrationGetPollStatus(pollId)).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting poll status failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistGetDevices(requestDto) {
    return (dispatch) => {
        console.log('Getting checklist devices.');
        const { cluid } = requestDto;

        const hashId = resolveHashIdFromState();
        //return RestCaller.httpGet(Resource.checklistGetDevices(hashId), new Headers({ cluid }))
        return fetch(Resource.checklistGetDevices(hashId), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                cluidUser: cluid,
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(
                (result) => {
                    console.log('Response: ', result);
                    dispatch(saveCaseData(true));
                    return Promise.resolve(result);
                },
                (error) => {
                    console.log('Getting checklist devices failed', error);
                    error.skipGlobalExceptionHandler = true;
                    return Promise.reject(error);
                },
            );
    };
}

export function checklistStoreNote(requestDto) {
    return (dispatch) => {
        console.log('Saving note.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPurePut(Resource.checklistStoreNote(hashId), requestDto).then(
            () => {
                console.log('Saving note finished');
                dispatch(saveCaseData(true));
                return Promise.resolve();
            },
            (error) => {
                console.log('Saving note failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistStoreSignatoryPersons(requestDto) {
    return (dispatch) => {
        console.log('Saving signatory persons.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPurePut(
            Resource.checklistStoreSignatoryPersons(hashId),
            requestDto,
        ).then(
            () => {
                console.log('Saving signatory persons finished');
                dispatch(saveCaseData(true));
                return Promise.resolve();
            },
            (error) => {
                console.log('Saving signatoryPersons failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistStoreDispositionRights(requestDto, attrNameDispositionRights) {
    return (dispatch) => {
        console.log('Loading qr code.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPurePut(
            Resource.checklistStoreDispositionRights(hashId),
            requestDto,
        ).then(
            () => {
                console.log('Loading qr code finished');
                dispatch(
                    fireAttrChange({
                        name: attrNameDispositionRights,
                        value: requestDto,
                        append: false,
                    }),
                );
                dispatch(saveCaseData(true));
                return Promise.resolve();
            },
            (error) => {
                console.log('Saving disposition rights failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistMigrateDocuments(requestDto) {
    return (dispatch) => {
        console.log('Migrating documents.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPut(Resource.checklistMigrateDocuments(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Migrating documents failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistGenerateDoc(requestDto) {
    return (dispatch) => {
        console.log('Generating documents.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.checklistGenerateDoc(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Generating documents failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistGetData() {
    return (dispatch) => {
        console.log('Getting client info.');

        const requestDto = {};

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.checklistGetData(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);

                dispatch(
                    fireAttrChange({
                        name: 'companyInfo',
                        value: result.companyInfo,
                        append: false,
                    }),
                );
                dispatch(
                    fireAttrChange({ name: 'ownerList', value: result.ownerList, append: false }),
                );
                dispatch(
                    fireAttrChange({
                        name: 'announcements',
                        value: result.announcements,
                        append: false,
                    }),
                );

                dispatch(
                    fireAttrChange({
                        name: 'disponentList',
                        value: result.disponentList,
                        append: false,
                    }),
                );
                dispatch(
                    fireAttrChange({
                        name: 'disponentsSecurityPhonesValid',
                        value: result.disponentsSecurityPhonesValid,
                        append: false,
                    }),
                );

                dispatch(
                    fireAttrChange({ name: 'dispoData', value: result.dispoData, append: false }),
                );
                dispatch(
                    fireAttrChange({
                        name: 'georgeBusinessContractData',
                        value: result.georgeBusinessContractData,
                        append: false,
                    }),
                );
                dispatch(
                    fireAttrChange({
                        name: 'bankIdentityContractsData',
                        value: result.bankIdentityContractsData,
                        append: false,
                    }),
                );
                dispatch(
                    fireAttrChange({
                        name: 'overLimitMethodContractsData',
                        value: result.overLimitMethodContractsData,
                        append: false,
                    }),
                );

                dispatch(
                    fireAttrChange({
                        name: 'signMethods',
                        value: result.signMethods,
                        append: false,
                    }),
                );
                dispatch(
                    fireAttrChange({
                        name: 'contractsStatuses',
                        value: result.contractsStatuses,
                        append: false,
                    }),
                );

                dispatch(saveCaseData(true));
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting client info failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

/*export function checklistGetClientInfo(attrNameClienrInfo, attrNameRepresentatives, attrNameAnnouncements) {
    return (dispatch) => {
        console.log("Getting client info.");

        const requestDto = {
        };

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.checklistGetData(hashId), requestDto)
            .then((result) => {
                    console.log('Response: ', result);
                    dispatch(fireAttrChange({name: attrNameClienrInfo, value: result.companyInfo, append: false}));
                    dispatch(fireAttrChange({name: attrNameRepresentatives, value: result.ownerList, append: false}));
                    dispatch(fireAttrChange({name: attrNameAnnouncements, value: result.announcements, append: false}));

                    dispatch(saveCaseData(true));
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Getting client info failed', error);
                    error.skipGlobalExceptionHandler = true;
                    return Promise.reject(error);
                });
    }
}

export function checklistGetRepresentatives(attrNameDisponentList, attrNameSecurityPhonesValid) {
    return (dispatch) => {
        console.log("Getting client info.");

        const requestDto = {
        };

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.checklistGetData(hashId), requestDto)
            .then((result) => {
                    console.log('Response: ', result);
                    dispatch(fireAttrChange({name: attrNameDisponentList, value: result.disponentList, append: false}));
                    dispatch(fireAttrChange({name: attrNameSecurityPhonesValid, value: result.disponentsSecurityPhonesValid, append: false}));

                    dispatch(saveCaseData(true));
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Getting client info failed', error);
                    error.skipGlobalExceptionHandler = true;
                    return Promise.reject(error);
                });
    }
}*/

export function checklistSetOBO(requestDto) {
    return () => {
        console.log('Setting OBO.');

        const adfsTokenHandler = new AdfsTokenHandler();

        return adfsTokenHandler
            .handleObo(requestDto)
            .then(() => adfsTokenHandler.refreshTokenHandler.handleRefresh())
            .then(
                (result) => {
                    return Promise.resolve(result);
                },
                (error) => {
                    console.log('Setting OBO failed', error);
                    error.skipGlobalExceptionHandler = true;
                    return Promise.reject(error);
                },
            );
    };
}

export function checklistGetActivationQrCode(cluidUser) {
    return () => {
        console.log('Loading qr code.');

        const requestDto = {};

        const hashId = resolveHashIdFromState();
        return fetch(Resource.checklistGetActivationQrCode(hashId), {
            method: 'POST',
            body: JSON.stringify(requestDto),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                cluidUser: cluidUser,
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(
                (result) => {
                    console.log('Response: ', result);
                    return Promise.resolve(result);
                },
                (error) => {
                    console.log('Loading qr code failed', error);
                    error.skipGlobalExceptionHandler = true;
                    return Promise.reject(error);
                },
            );
    };
}

export function checklistGetActivationComplete(cluidUser) {
    return () => {
        console.log('Getting activation status.');

        const hashId = resolveHashIdFromState();
        return fetch(Resource.checklistGetActivationComplete(hashId), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                cluidUser: cluidUser,
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(
                (result) => {
                    console.log('Response: ', result);
                    return Promise.resolve(result);
                },
                (error) => {
                    console.log('Getting activation status failed', error);
                    error.skipGlobalExceptionHandler = true;
                    return Promise.reject(error);
                },
            );
    };
}

export function checklistSearchCrm(requestDto) {
    return () => {
        console.log('Searching CRM.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.checklistSearchCrm(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Searching CRM failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistGetCountries() {
    return () => {
        console.log('Getting countries.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.checklistGetCountries(hashId)).then(
            (result) => {
                console.log('Response: ', result);
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting countries failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistDownloadDoc() {
    return () => {
        console.log('Downloading doc.');

        const hashId = resolveHashIdFromState();

        return RestCaller.httpGetWithBinary(Resource.checklistDownloadDoc(hashId)).then(
            (response) => {
                return Promise.resolve(response.blob());
            },
            (error) => {
                console.log('Downloading doc. failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );

        /*return fetch(Resource.checklistDownloadDoc(hashId), {
            method: 'GET',
            headers: {
                //"Content-Type": "application/octet-stream"
                //"Content-Type": "application/pdf"
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(Resource.checkStatus)
            .then((result) => {
                    //console.log('Response: ', result);
                    return Promise.resolve(result.blob());
                },
                error => {
                    console.log('Downloading doc failed', error);
                    return Promise.reject(error);
                });*/
    };
}

export function checklistGetContractStatus(attrNameContracts, requestDto) {
    return (dispatch) => {
        console.log('Getting contract status.');

        const { checklistProcessId } = requestDto;

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(
            Resource.checklistGetContractStatus(hashId, checklistProcessId),
        ).then(
            (result) => {
                console.log('Response: ', result);

                if (!checklistProcessId) {
                    dispatch(
                        fireAttrChange({ name: attrNameContracts, value: result, append: false }),
                    );
                    dispatch(saveCaseData(true));
                }
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Getting contract status failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistSetContractsSigned(requestDto) {
    return () => {
        console.log('Setting contracts signed');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPurePut(Resource.checklistSetContractSigned(hashId), requestDto).then(
            () => {
                console.log('Setting contracts signed finished');
                return Promise.resolve();
            },
            (error) => {
                console.log('Setting contracts signed failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export const saveCaseData = (skipGlobalExceptionHandler) => {
    return (dispatch, getState) => {
        const hashId = resolveHashIdFromState();
        const { attributes } = getState().task.activity;

        if (isEmptyString(hashId)) {
            return Promise.reject('Missing hashId parameter');
        }

        return RestCaller.httpPurePut(Resource.saveActivity(hashId), attributes).then(
            () => {
                //dispatch(taskSaved());
                logger.info('Received response for saving case ' + hashId);
                return Promise.resolve();
            },
            (error) => {
                logger.error('Error saving activity: ' + error);
                error.skipGlobalExceptionHandler = !!skipGlobalExceptionHandler;
                return Promise.reject(error);
            },
        );
    };
};

export function checklistErrorCase(requestDto) {
    return () => {
        console.log('Creating error cases');

        return RestCaller.httpPost(Resource.checklistErrorCase(), requestDto).then(
            () => {
                console.log('Error cases created: ' + requestDto.errorIds.join(', '));
                return Promise.resolve();
            },
            (error) => {
                console.log('Creating error cases failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistConfirmContractInRegistry(requestDto) {
    return () => {
        console.log('Confirming contract in registry');

        const hashId = resolveHashIdFromState();

        return RestCaller.httpPut(
            Resource.checklistConfirmContractInRegistry(hashId),
            requestDto,
        ).then(
            () => {
                return Promise.resolve();
            },
            (error) => {
                console.log('Confirming contract in registry failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

export function checklistSearchBanker(requestDto) {
    return () => {
        console.log('Searching banker.');

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPost(Resource.checklistSearchBanker(hashId), requestDto).then(
            (result) => {
                console.log('Response: ', result);
                return Promise.resolve(result);
            },
            (error) => {
                console.log('Searching banker failed', error);
                error.skipGlobalExceptionHandler = true;
                return Promise.reject(error);
            },
        );
    };
}

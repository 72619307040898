import SessionWrapper from '../../sessions/sessionWrapper';
import { MOBILE_APP_SALES_CHANNELS } from '../auths/oidcService';

export const resolveSalesChannel = (salesChannel) => {
    return salesChannel ? salesChannel : new SessionWrapper().getSalesChannel();
};

/**
 * Function that based on the salesChannel evaluates, whether the GeorgeTicketing was
 * started from GeorgeApp or desktop George.
 */
export const isMobileApp = () => {
    const channel = new SessionWrapper().getSalesChannel();
    const isMobile = MOBILE_APP_SALES_CHANNELS.includes(channel);
    return isMobile;
};

import React from 'react';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { LoanModelingExtendedField } from '@csas-smart/gti-corp-landing-ui';

const LoanModelingFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const getModelingData = () => {
        return RestCaller.httpPureGet(Resource.getModelingData(hashId));
    };

    const getDefaultModelingData = () => {
        return RestCaller.httpGet(Resource.getDefaultModelingData(hashId));
    };

    const getByInstallmentCount = (installmentCount: number) => {
        const body = { hashId: hashId, installmentCount: installmentCount };
        return RestCaller.httpPost(Resource.postModelingByInstallmentCount(), body);
    };

    const getByPaymentAmount = (paymentAmount: number) => {
        const body = { hashId: hashId, paymentAmount: paymentAmount };
        return RestCaller.httpPost(Resource.postModelingByPaymentAmount(), body);
    };

    return (
        <LoanModelingExtendedField
            {...props}
            getModelingData={getModelingData}
            getDefaultModelingData={getDefaultModelingData}
            getByInstallmentCount={getByInstallmentCount}
            getByPaymentAmount={getByPaymentAmount}
        />
    );
};

export default LoanModelingFieldContainer;

import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import { fireCaseCancelled } from '../actions/taskActions';
import { DeviceContext, DeviceContextProps } from '@csas-smart/gti-ui-comps';
import { PageHeader } from './page-header';
import { isMobileApp } from './utils/salesChannelUtils';
import { fireHandoverToken } from '../actions/mepActions';
import { CardGroup, Card, StatusInfo, Button, ButtonGroup } from '@george-labs.com/design-system';
import { useAppDispatch } from './hooks/hooks';

const ErrorComponent = (props) => {
    const { message } = props;
    const { t } = props;
    const dispatch = useAppDispatch();
    const contextValue = useContext<DeviceContextProps>(DeviceContext);
    const pageHeader = isMobileApp() ? null : <PageHeader isError={true} />;

    const backToGeorgeHandler = () => {
        dispatch(fireCaseCancelled(undefined)).finally(dispatchHandoverToken);
    };

    const dispatchHandoverToken = () => {
        return dispatch(fireHandoverToken());
    };

    const renderContent = () => {
        return (
            <CardGroup>
                <Card>
                    <StatusInfo
                        variant={StatusInfo.VARIANT.ERROR}
                        title={t('common:popup.error.georgeError')}
                    >
                        {message}
                    </StatusInfo>
                </Card>
                <Card>
                    <ButtonGroup className="text-center">
                        <Button variant={Button.VARIANT.PRIMARY} onClick={backToGeorgeHandler}>
                            {t('common:popup.error.backToOverview')}
                        </Button>
                    </ButtonGroup>
                </Card>
            </CardGroup>
        );
    };

    return (
        <>
            {pageHeader}
            <Container className="appContainer">
                {contextValue.isDesktop ? (
                    <>
                        <div>
                            <p>&nbsp;</p>
                        </div>
                        {renderContent()}
                    </>
                ) : (
                    renderContent()
                )}
            </Container>
        </>
    );
};
export default ErrorComponent;

/**
 * Generic template for validation, that most component will follow. To avoid unnecessary code duplication, function template has been created, where components only need to pass correct parameters.
 * @param value - value to be validated
 * @param currentValue - current error value - true / false. Useful for optimalization, to propagate change only, when error value is about to change.
 * @param compKey - key of Error object, that validation might possibly change.
 * @param required - whether component is required or not.
 * @param contentValidationFnc - function for validating content of component.
 * @param propagationFnc - function for propagating Error object up the component tree.
 * @param requiredValidationFnc - function for validating, whether component has no content and therefore, if not required, it is valid.
 * @returns {boolean}
 */
export function validationTemplate(
    value,
    currentValue,
    compKey,
    required,
    contentValidationFnc,
    propagationFnc,
    requiredValidationFnc = () => {
        return true;
    },
) {
    // 1. Required validation -> component is not required and empty, that is valid state. Meaning of empty can vary for components, therefore it is passed as a parameter.
    if (requiredValidationFnc() && !required) {
        // 1.1 Evaluate, whether error is currently set. If error is set, propagate new Error object up the component tree.
        if (currentValue === true) {
            const newError = {};
            newError[compKey] = false;
            propagationFnc(newError);
        }
        return true;
    }

    // 2. Component is required or not empty -> perform contentValidation
    const validationOutcome = contentValidationFnc(value);
    if (validationOutcome) {
        // 2.1. Evaluate, whether error is currently set and propagate new Error object only on change.
        if (currentValue === true) {
            const newError = {};
            newError[compKey] = false;
            propagationFnc(newError);
        }
        return true;
    } else {
        // 2.2. Evaluate, whether error is currently set and propagate new Error object only on change.
        if (currentValue === false) {
            const newError = {};
            newError[compKey] = true;
            propagationFnc(newError);
        }
        return false;
    }
}

//tohle je nejaky divoky, nestacil by jen ten isNaN ?
export function isNumber(value) {
    return !isNaN(Number(value)) && !isNaN(parseInt(value));
}

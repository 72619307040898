import { isExisting } from 'validations';

/**
 * Helper class which loads the properties of the editor
 */
class PropertiesLoader {
    getProperty(editor, propertyName, defaultValue = null) {
        if (editor.properties) {
            const result = editor.properties.filter((p) => propertyName === p.name);
            if (result && result.length > 0 && isExisting(result[0].value)) {
                return result[0].value;
            }
        }

        return defaultValue;
    }
}

// export singleton
export default new PropertiesLoader();

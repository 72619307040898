import React from 'react';
import { useDispatch } from 'react-redux';
import { RestCallerField } from '@csas-smart/gti-sigma-ui';
import { restcaller } from '../../actions/taskActions';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';
import { AppDispatch } from '../../core/store';

const RestCallerFieldContainer = (props) => {
    const { field, t, required } = props;
    const dispatch = useDispatch<AppDispatch>();

    const callerFunction = () => {
        return dispatch(restcaller(field.name));
    };

    return (
        <GdsFieldContainer field={field} t={t} required={required}>
            <RestCallerField {...props} callerFunction={callerFunction} />
        </GdsFieldContainer>
    );
};

export default RestCallerFieldContainer;

import PropertiesLoader from '../propertiesloader';

const defaultStateFilter = {
    visible: true,
    editable: true,
    preselectUnfulfilled: true,
    preselectOverdue: true,
    preselectToCheck: true,
    preselectFulfilled: true,
};

const defaultTypeFilter = {
    visible: true,
    editable: true,
};

export function getStateFilter(field) {
    if (!field) {
        return defaultStateFilter;
    }

    return PropertiesLoader.getProperty(field.editor, 'stateFilter', defaultStateFilter);
}

export function getTypeFilter(field) {
    if (!field) {
        return defaultTypeFilter;
    }

    return PropertiesLoader.getProperty(field.editor, 'typeFilter', defaultTypeFilter);
}

export function getPreselectDrawing(field) {
    if (!field) {
        return false;
    }

    return PropertiesLoader.getProperty(field.editor, 'preselectDrawing', false);
}

export function getPreselectSubsequent(field) {
    if (!field) {
        return false;
    }

    return PropertiesLoader.getProperty(field.editor, 'preselectSubsequent', false);
}

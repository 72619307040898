import RestCaller from '../restCaller';

export default class TranslationService {
    constructor(private readonly resourceUrl: string, private readonly hashId: string) {
        this.resourceUrl = resourceUrl;
    }

    getProductName() {
        return RestCaller.httpGet(this.resourceUrl + '?hashId=' + this.hashId);
    }
}

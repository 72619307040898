import React from 'react';
import { useSelector } from 'react-redux';
import { StepperField } from '@csas-smart/gti-ui-comps';
import { RootState } from '../core/store';

const StepperFieldContainer = (props) => {
    const activityDesignName = useSelector<RootState>(
        (state) => state.task?.activity?.design?.name,
    );

    return <StepperField currentActivityName={activityDesignName} {...props} />;
};

export default StepperFieldContainer;

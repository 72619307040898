import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { GdsFieldContainer } from '@csas-smart/gti-ui-comps';

// Lazily loading "@csas-smart/gti-mnt-ui":
const PaymentSurchargeV2Field = lazy(() =>
    import('@csas-smart/gti-mnt-ui').then((module) => ({
        default: module.PaymentSurchargeV2Field,
    })),
);

const mapStateToProps = () => {
    return {
        hashId: resolveHashIdFromState(),
    };
};

const PaymentSurchargeV2FieldContainer = (props) => {
    const { field, t, required, attributes, hashId } = props;
    const { payOffDate, paymentAmount, extraInstalmentType } = attributes;
    const localPaymentDate = formatToLocalDateInISO(payOffDate);
    const loadFeeValueCall = () =>
        RestCaller.httpGet(
            Resource.getFairCompensationFee(paymentAmount, localPaymentDate, hashId),
        );
    const loadModelingValueCall = () =>
        RestCaller.httpPost(Resource.extraRepaymentModeling(), {
            amount: paymentAmount,
            dueDate: payOffDate,
            hashId: hashId,
            extraInstalmentType: extraInstalmentType,
        });
    const loadPayOffDetailsCall = () =>
        RestCaller.httpGet(Resource.getPayOffDetails(localPaymentDate, hashId));

    function formatToLocalDateInISO(date) {
        if (date) {
            let dateObj = new Date(date);
            const offset = dateObj.getTimezoneOffset();
            dateObj = new Date(dateObj.getTime() - offset * 60 * 1000);
            return dateObj.toISOString().split('T')[0];
        }
    }

    return (
        <GdsFieldContainer
            tooltipKey="tooltipText"
            field={field}
            t={t}
            required={required}
            componentId={'PaymentSurchargeV2Field'}
        >
            <PaymentSurchargeV2Field
                {...props}
                feeValueCall={loadFeeValueCall}
                modelingValueCall={loadModelingValueCall}
                payOffDetailsCall={loadPayOffDetailsCall}
            />
        </GdsFieldContainer>
    );
};

export default connect(mapStateToProps, null)(PaymentSurchargeV2FieldContainer);

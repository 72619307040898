// polyfill packages
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'whatwg-fetch'; //fetch for older browsers
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';

import React from 'react';
import './styles/george2.css';
import './styles/app.css';
import './styles/gti-mnt-ui.css';
import './styles/gti-cedr-ui.css';
import './styles/gti-kyc-ui.css';

import '@george-labs.com/design-system/dist/bootstrap.min.css';
import '@george-labs.com/design-system/dist/gds-main.min.css';
import '@george-labs.com/design-system/dist/gds-store.min.css';
import '@george-labs.com/design-system/dist/gds-components.min.css';

import 'react-dates/lib/css/_datepicker.css'; // Optional if you want to use DateInput/DateRangeInput
import WebFont from 'webfontloader';
import store from './core/store';
import { Provider } from 'react-redux';
import LoggableContent from './core/context/loggableContet';
import GdsThemeProvider from './core/gds/gds-theme-provider';
import AppWrapper from './core/appWrapper';
import ReactDOM from 'react-dom/client';

WebFont.load({
    custom: {
        families: ['Inter var: 400,normal,600,700,bold,800'],
        urls: ['./inter.css'],
    },
});

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
root.render(
    <LoggableContent>
        <Provider store={store}>
            <GdsThemeProvider>
                <AppWrapper />
            </GdsThemeProvider>
        </Provider>
    </LoggableContent>,
);

export enum FieldsContainerType {
    /**
     * Option, when the content of the activity is not wrapped in any container.
     * This is useful for components already containing a card (Sigma Uber component), rest-caller-fields, or promo page.
     */
    NONE = 'NONE',

    /**
     * Default option, when the content of the activity is wrapped in a card container.
     */
    CARD = 'CARD',
}

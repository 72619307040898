import { TemplateState } from './types';

const initialState: TemplateState = { templates: {} };
export const createInitialState = (): TemplateState => {
    return { ...initialState };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const templateReducers = (state = initialState, action): TemplateState => {
    let newState;
    switch (action.type) {
        case 'TEMPLATE_CHANGED': {
            const templateId = action.payload.name;
            const templatesCopy = { ...state.templates };

            templatesCopy[templateId] = {
                template: action.payload.template,
                stringValue: action.payload.populatedTemplate,
                regenerated: true,
            };

            newState = Object.assign({}, state, { templates: templatesCopy });
            return newState;
        }
        case 'ACTIVITY_DESIGN_LOADED':
            return { ...initialState };
        case 'CLOSE_ACTIVITY':
            return { ...initialState };

        default:
            return state;
    }
};

export default templateReducers;

import React from 'react';
import { PensionsToggleGroupField } from '@csas-smart/gti-pens-ui';
import restCaller from '../core/restCaller';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import Resource from '../core/serverresource';

const PensionsToggleGroupFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const loadCurrentValue = () => {
        return restCaller.httpGet(Resource.getPensionsToggle(props.field.name, hashId));
    };

    return <PensionsToggleGroupField {...props} loadCurrentValue={loadCurrentValue} />;
};

export default PensionsToggleGroupFieldContainer;

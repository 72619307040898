import GdsLanguageProvider from './gds/gds-language-provider';
import { Button, FocusPage, FunctionCard, Icon, Paragraph } from '@george-labs.com/design-system';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const RedirectPageComp = ({ t }: { t: (arg: string) => string }) => {
    const [params, setParams] = useState({
        redirectUrl: '',
        returnUrl: null,
        returnTo: 'george',
        locale: 'cs',
    });
    const [returnUrl, setReturnUrl] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(
            window.location.hash.includes('?') ? window.location.hash.split('?')[1] : '',
        );
        const alreadyRedirected = !!urlParams.get('redirected');
        const redirectUrl = urlParams.get('redirectUrl');

        if (!alreadyRedirected && !!redirectUrl) {
            window.location.replace(window.location.href + '&redirected=true');
            window.open(redirectUrl, '_self');
        }

        setParams({
            redirectUrl,
            returnUrl: urlParams.get('returnUrl'),
            returnTo: urlParams.get('returnTo'),
            locale: urlParams.get('locale'),
        });
    }, []);

    useEffect(() => {
        if (params.returnUrl) {
            setReturnUrl(params.returnUrl);
        } else {
            switch (params.returnTo) {
                case 'b24':
                    setReturnUrl(import.meta.env.VITE_APP_LOGOUT_URL_B24);
                    break;
                default:
                    setReturnUrl(
                        import.meta.env.VITE_APP_LOGOUT_URL.replace(
                            '{lang}',
                            params.locale?.toLowerCase() ?? 'cs',
                        ),
                    );
            }
        }
    }, [params]);

    return (
        <div className="g-bs4 g-bootstrap notranslate">
            <div className="g-store">
                <GdsLanguageProvider>
                    <FocusPage stripeHeader={null}>
                        <FunctionCard
                            heading={<h1>{t('common:page.redirect.title')}</h1>}
                            icon={
                                <Icon
                                    icon={Icon.ICON.CHECKMARK_CIRCLE}
                                    size={Icon.SIZE.SIZE_128}
                                    color={Icon.COLOR.UI_GREEN}
                                />
                            }
                            buttons={[
                                <Button href={returnUrl}>
                                    {t('common:page.redirect.returnBtnLabel')}
                                </Button>,
                            ]}
                        >
                            <Paragraph>{t('common:page.redirect.logoutInfo')}</Paragraph>
                        </FunctionCard>
                    </FocusPage>
                </GdsLanguageProvider>
            </div>
        </div>
    );
}; // http://localhost:9090/#/redirect?redirectUrl=https://www.csin.cz&returnUrl=https://starter-int.csint.cz&appName=George&locale=cs

export const RedirectPage = withTranslation('translation')(RedirectPageComp);

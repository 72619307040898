import { BatchInterceptor } from '@mswjs/interceptors';
import { FetchInterceptor } from '@mswjs/interceptors/fetch';
import { XMLHttpRequestInterceptor } from '@mswjs/interceptors/XMLHttpRequest';
import { G4B_MODULE, NGRUNTIME_MODULE, PENS_MODULE } from './relevantStrategies/modules';
import logger from './loggly/gtiLogger';
import SessionWrapper from '../sessions/sessionWrapper';
import WebApiKey from './headers/web-api-key';
import XSessionId from './headers/x-session-id';
import CorrelationId from './headers/correlation-id';
import WebApiCluid from './headers/web-api-cluid';
import WebApiCorporate from './headers/web-api-corporate';
import XSmartUsername from './headers/x-smart-username';
import AcceptLanguage from './headers/accept-language';
import AuthorizationBearer from './headers/authorization-bearer';
import FeVersion from './headers/fe-version';
import Origin from './headers/smart-origin';

class Resource {
    sessionWrapper = new SessionWrapper();

    baseUrl: string;

    estorageBaseUrl: string;

    csasBaseUrl: string;

    pensionsBaseUrl: string;

    gmntBaseUrl: string;

    sigmaBaseUrl: string;

    g4bmigrBaseUrl: string;

    sigmaOnlineAuthBaseUrl: string;

    kycComponentBaseUrl: string;

    gCorpKycComponentBaseUrl: string;

    zafuUrl: string;

    sesUrl: string;

    adcaBaseUrl: string;

    webApiUrl: string;

    gbumBaseUrl: string;

    fincaseBaseUrl: string;

    initialize = () => {
        this.baseUrl = import.meta.env.VITE_APP_SERVER_RESOURCE_URL;
        this.estorageBaseUrl = import.meta.env.VITE_APP_ESTORAGE_RESOURCE_URL;
        this.csasBaseUrl = import.meta.env.VITE_APP_CSAS_BASE_URL;
        this.pensionsBaseUrl = import.meta.env.VITE_APP_PENSIONS_RESOURCE_URL;
        this.gmntBaseUrl = import.meta.env.VITE_APP_GMNT_RESOURCE_URL;
        this.sigmaBaseUrl = import.meta.env.VITE_APP_SIGMA_RESOURCE_URL;
        this.g4bmigrBaseUrl = import.meta.env.VITE_APP_G4BMIGR_RESOURCE_URL;
        this.sigmaOnlineAuthBaseUrl = import.meta.env.VITE_APP_SIGMA_ONLINE_AUTH_RESOURCE_URL;
        this.kycComponentBaseUrl = import.meta.env.VITE_APP_KYC_COMPONENT_URL;
        this.gCorpKycComponentBaseUrl = import.meta.env.VITE_APP_GKYC_COMPONENT_URL;
        this.zafuUrl = import.meta.env.VITE_APP_ZAFU_RESOURCE_URL;
        this.sesUrl = import.meta.env.VITE_APP_SES_RESOURCE_URL;
        this.adcaBaseUrl = import.meta.env.VITE_APP_ADCA_RESOURCE_URL;
        this.webApiUrl = import.meta.env.VITE_APP_WEB_API_URL;
        this.gbumBaseUrl = import.meta.env.VITE_APP_GBUM_RESOURCE_URL;
        this.fincaseBaseUrl = import.meta.env.VITE_APP_FINCASE_RESOURCE_URL;
    };

    setInterceptor = () => {
        //const resolvedApiKey = apiKey ? apiKey : ALLOWED_ADFS_SALES_CHANNELS.indexOf(this.sessionWrapper.getSalesChannel()) !== -1 ? import.meta.env.VITE_APP_SES_API_KEY : import.meta.env.VITE_APP_API_KEY;
        const initializeConfig = (config) => {
            if (!config) {
                config = {};
            }

            if (!config.headers) {
                config.headers = {};
            }

            return config;
        };

        const logApiCall = (url, config) => {
            const serviceRequest = {
                method: config.method,
                url: url,
                correlationId: config.headers[CorrelationId.HEADER_CORRELATION_ID_NAME],
            };

            logger.api(serviceRequest);
        };

        const interceptor = new BatchInterceptor({
            name: 'serverresource-interceptor',
            interceptors: [new FetchInterceptor(), new XMLHttpRequestInterceptor()],
        });

        interceptor.on('request', ({ request }) => {
            const { url, headers: originalHeaders, method } = request;
            let headers = originalHeaders.entries();
            const config = initializeConfig({ headers, method });

            headers = new WebApiKey().enhanceHeaders(headers, url);
            headers = new Origin().enhanceHeaders(headers, url);
            headers = new XSessionId().enhanceHeaders(headers, url);
            headers = new CorrelationId().enhanceHeaders(headers, url);
            headers = new WebApiCluid().enhanceHeaders(headers);
            headers = new WebApiCorporate().enhanceHeaders(headers);
            headers = new XSmartUsername().enhanceHeaders(headers);
            headers = new AcceptLanguage().enhanceHeaders(headers);
            headers = new AuthorizationBearer().enhanceHeaders(headers);
            headers = new FeVersion().enhanceHeaders(headers, url);

            config.headers = headers;

            logApiCall(url, config);

            for (const [key, value] of Object.entries(config.headers)) {
                request.headers.set(key, String(value));
            }
        });

        interceptor.apply();
    };

    loadDocuments = (hashId: string, fieldName: string) => {
        return this.baseUrl + '/case/document?hashId=' + hashId + '&fieldName=' + fieldName;
    };

    loadDocumentsByDocumentIds = (documentIds: string[], hashId: string, fieldName: string) => {
        return `${
            this.baseUrl
        }/case/document/byIds?fieldName=${fieldName}&hashId=${hashId}&documentIds=${documentIds.join(
            ',',
        )}`;
    };

    uploadDocument = (hashId, fieldName) => {
        return this.baseUrl + '/case/document/' + fieldName + '?hashId=' + hashId;
    };

    uploadDocumentWithType = (hashId: string, fieldName: string, documentType: string) => {
        return `${this.baseUrl}/case/document/upload?fieldName=${fieldName}&hashId=${hashId}&documentType=${documentType}`;
    };

    updateDocument = (hashId: string, documentId: string, fieldName: string) => {
        return `${this.baseUrl}/case/document/${documentId}?hashId=${hashId}&fieldName=${fieldName}`;
    };

    deleteDocument = (fieldName, hashId, document) => {
        return (
            this.baseUrl +
            '/case/document/' +
            document.documentId +
            '?hashId=' +
            hashId +
            '&fieldName=' +
            fieldName
        );
    };

    getDocument = (fieldName, hashId, document) => {
        return (
            this.baseUrl +
            '/case/document/' +
            document.documentId +
            '?hashId=' +
            hashId +
            '&fieldName=' +
            fieldName
        );
    };

    getStaticDocument = (hashId, id) => {
        return this.baseUrl + '/case/document/download/static/' + id + '?hashId=' + hashId;
    };

    getSigmaFolder = (folderId, hashId) => {
        return this.sigmaBaseUrl + '/folder/' + folderId + '?hashId=' + hashId;
    };

    // Has no FE sensitive data
    getSigmaFolderFe = (folderId, hashId) => {
        return this.sigmaBaseUrl + '/folder/' + folderId + '/fe?hashId=' + hashId;
    };

    getSigmaClientFolder = (folderId, hashId) => {
        return this.sigmaBaseUrl + '/folder/' + folderId + '/client?hashId=' + hashId;
    };

    getSigmaFolderByFieldName = (fieldName, hashId) => {
        return this.sigmaBaseUrl + '/field/folder/' + fieldName + '?hashId=' + hashId;
    };

    getSigmaSignMethodsByFieldName = (fieldName, hashId) => {
        return this.sigmaBaseUrl + '/field/sign-methods/' + fieldName + '?hashId=' + hashId;
    };

    getSigmaSignatureMethods = (hashId, folderId) => {
        return this.sigmaBaseUrl + '/folder/' + folderId + '/signature-methods?hashId=' + hashId;
    };

    changeSigmaSigningMethod = (hashId, folderId, method) => {
        return (
            this.sigmaBaseUrl +
            '/folder/' +
            folderId +
            '/signature-methods/' +
            method +
            '?hashId=' +
            hashId
        );
    };

    getSigmaDocument = (hashId, folderId, documentId) => {
        return (
            this.sigmaBaseUrl +
            '/folder/' +
            folderId +
            '/document/' +
            documentId +
            '/content?hashId=' +
            hashId
        );
    };

    getSigmaDocumentByField = (hashId, folderName, documentId) => {
        return (
            this.sigmaBaseUrl +
            '/field/documents/' +
            folderName +
            '/' +
            documentId +
            '/content?hashId=' +
            hashId
        );
    };

    signSigmaDocuments = (hashId, folderId) => {
        return this.sigmaBaseUrl + '/folder/' + folderId + '/sign?hashId=' + hashId;
    };

    getSigmaOnlineAuthFolder = (folderId) => {
        return this.sigmaOnlineAuthBaseUrl + '/folders/' + folderId;
    };

    getSigmaOnlineAuthDocumentBlob = (folderId, documentId) => {
        return this.sigmaOnlineAuthBaseUrl + '/folders/' + folderId + '/documents/' + documentId;
    };

    sigmaOnlineAuthFolderAction = (folderId) => {
        return this.sigmaOnlineAuthBaseUrl + '/folders/' + folderId + '/action';
    };

    sigmaFederatedAuth = (folderId, signId, hash) => {
        return `${this.sigmaOnlineAuthBaseUrl}/sign/${folderId}/${signId}/hash/${hash}`;
    };

    sigmaFederatedAuthPoll = (folderId, signId) => {
        return `${this.sigmaOnlineAuthBaseUrl}/sign/${folderId}/poll/${signId}`;
    };

    startCase = () => {
        return this.baseUrl + '/case';
    };

    killCase = (hashId) => {
        return this.baseUrl + '/case?hashId=' + hashId;
    };

    generateTemplate = (cidla) => {
        return this.baseUrl + '/case/' + cidla + '/template';
    };

    getCodebook = (codebook) => {
        return this.baseUrl + '/codebook/' + codebook;
    };

    getCodebookWithLang = (codebook, lang) => {
        return this.baseUrl + '/codebook/' + codebook + '?lang=' + lang;
    };

    graphql = (hashId, fieldName, module) => {
        if (!module) {
            return null;
        }

        const internalModule = module.toLowerCase();
        if (internalModule == NGRUNTIME_MODULE.name) {
            return NGRUNTIME_MODULE.url + '/graph/' + fieldName + '?hashId=' + hashId;
        } else if (internalModule == PENS_MODULE.name) {
            return PENS_MODULE.url + '/graph/' + fieldName + '?hashId=' + hashId;
        } else if (internalModule == G4B_MODULE.name) {
            return G4B_MODULE.url + '/graph/' + fieldName + '?hashId=' + hashId;
        }

        return null;
    };

    getCodebookValue = (codebook, id) => {
        return this.baseUrl + '/codebook/' + codebook + '/' + id;
    };

    getFairCompensationFee = (paymentAmount, payOffDate, hashId) => {
        const params = new URLSearchParams({
            hashId: hashId,
            paymentDate: payOffDate,
            paymentAmount: paymentAmount,
        });
        return this.gmntBaseUrl + '/call/fair/compensation-gti?' + params.toString();
    };

    extraRepaymentModeling = () => {
        return this.gmntBaseUrl + '/call/extraRepayment/modeling-gti';
    };

    getPayOffDetails = (payOffDate: string, hashId: string) => {
        const params = new URLSearchParams({
            hashId: hashId,
            payOffDate: payOffDate,
        });
        return this.gmntBaseUrl + '/call/loan/payoff-details?' + params.toString();
    };

    getPayOffFees = (prefix, accountNumber, payOffDate, hashId) => {
        return (
            this.baseUrl +
            '/payments/' +
            prefix +
            '/' +
            accountNumber +
            '/' +
            payOffDate +
            '?hashId=' +
            hashId
        );
    };

    checkStatus = (response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            return response.text().then((text) => {
                throw new Error(text ? text : 'Unknown error');
            });
        }
    };

    parseJSON = (response) => {
        return response.json();
    };

    getClientsContacts = (hashId: string, fieldName: string) => {
        return `${this.baseUrl}/runtime/person/contacts?hashId=${hashId}&fieldName=${fieldName}`;
    };

    getApplicationState = (hashId) => {
        return this.baseUrl + '/state?hashId=' + hashId;
    };

    getProductName = (hashId) => {
        return this.baseUrl + '/product/translation?hashId=' + hashId;
    };

    loadProduct = (hashId) => {
        return this.baseUrl + '/product/search?hashId=' + hashId;
    };

    loadEditor = (componentName) => {
        return this.baseUrl + '/editor/' + componentName;
    };

    loadRequiredEditorNames = (caseType, hashId) => {
        if (hashId) {
            return this.baseUrl + '/editor/case?hashId=' + hashId;
        }

        return this.baseUrl + '/editor/caseType/' + caseType;
    };

    loadActor = () => {
        return this.baseUrl + '/actor';
    };

    loadActivity = (hashId) => {
        return this.baseUrl + '/activity?hashId=' + hashId;
    };

    completeActivity = (hashId) => {
        return this.baseUrl + '/activity?hashId=' + hashId;
    };

    saveActivity = (hashId) => {
        return this.baseUrl + '/activity/save?hashId=' + hashId;
    };

    //@deprecated - legacy handover token
    handoverToken = () => {
        return import.meta.env.VITE_APP_MEP_URL + '/mep/fs/fl/oauth2/handover';
    };

    getToken = () => {
        return import.meta.env.VITE_APP_MEP_URL + '/mep/fs/fl/oidc/v2/token';
    };

    revokeToken = () => {
        return import.meta.env.VITE_APP_MEP_URL + '/mep/fs/sts/oauth2/revokeext';
    };

    addDays = (date, duration) => {
        return this.baseUrl + '/calendar/LABOURUNIT/' + date + '/' + duration;
    };

    searchEmployerByName = (query) => {
        return this.baseUrl + '/organization/search/name/' + query;
    };

    searchEmployerByRegistrationNumber = (query) => {
        return this.baseUrl + '/organization/search/registrationNumber/' + query;
    };

    getAppointedPersons = (hashId) => {
        return this.pensionsBaseUrl + '/ticket/appointedpersons?hashId=' + hashId;
    };

    getPensionsToggle = (fieldName, hashId) => {
        return this.pensionsBaseUrl + '/ticket/toggle/' + fieldName + '?hashId=' + hashId;
    };

    sessionLogin = (cgpHashedId) => {
        return this.baseUrl + '/security/authorize/' + cgpHashedId;
    };

    createAuth = (hashId) => {
        return this.estorageBaseUrl + '/auth/create/' + hashId;
    };

    signAuth = (authId, authHashId, withCutomAuthorizationOriginApplication = false) => {
        if (withCutomAuthorizationOriginApplication) {
            return this.baseUrl + '/sign/origin/' + authId + '/hash/' + authHashId;
        }
        return this.baseUrl + '/sign/' + authId + '/hash/' + authHashId;
    };

    cancelAuth = (authId, hashId) => {
        return this.baseUrl + '/auth/cancel/' + authId + '?hashId=' + hashId;
    };

    pollUrl = (uri) => {
        return this.csasBaseUrl + uri;
    };

    getContributionData = (hashId) => {
        return this.pensionsBaseUrl + '/ticket/contributionamount?hashId=' + hashId;
    };

    generateRecords = (fieldName, hashId) => {
        return this.baseUrl + '/printing/' + fieldName + '/generate?hashId=' + hashId;
    };

    pregenerateRecord = (fieldName, templateDefinitionHash, hashId) => {
        return (
            this.baseUrl +
            '/printing/' +
            fieldName +
            '/pre-generate/' +
            templateDefinitionHash +
            '?hashId=' +
            hashId
        );
    };

    getRecords = (fieldName, hashId) => {
        return this.baseUrl + '/printing/' + fieldName + '?hashId=' + hashId;
    };

    getRecordContent = (duid, hashId) => {
        return this.baseUrl + '/record/content?duid=' + duid + '&hashId=' + hashId;
    };

    postEmoticonFeedback = (fieldName, hashId) => {
        return this.baseUrl + '/feedback/' + fieldName + '?hashId=' + hashId;
    };

    updateToggleFieldState = (fieldName, hashId) => {
        return this.baseUrl + '/toggle/' + fieldName + '?hashId=' + hashId;
    };

    restCallerUrl = (fieldName, hashId) => {
        return this.baseUrl + '/caller/' + fieldName + '?hashId=' + hashId;
    };

    getUserInfo = (hashId) => {
        return `${this.baseUrl}/user?hashId=${hashId}`;
    };

    searchUsers = (hashId) => {
        return `${this.baseUrl}/user/search?hashId=${hashId}`;
    };

    /* KYC related integrations */
    getKycInfo = () => {
        return this.kycComponentBaseUrl + '/info';
    };

    getQuestionnarie = () => {
        return this.kycComponentBaseUrl + '/questionnaire';
    };

    saveAttachments = () => {
        return this.kycComponentBaseUrl + '/questionnaire/attachments';
    };

    getPersonInfo = (hashId) => {
        return this.baseUrl + '/runtime/person/search?hashId=' + hashId;
    };

    getCompanies = (hashId) => {
        return this.baseUrl + '/runtime/company?hashId=' + hashId;
    };

    getNextTask = (hashId) => {
        return this.baseUrl + '/next-task?hashId=' + hashId;
    };

    pollNextTask = (hashId) => {
        return this.baseUrl + '/next-task/poll?hashId=' + hashId;
    };

    pollSyncNextTask = (hashId, returnUrl) => {
        const returnUrlParam = returnUrl ? '&returnUrl=' + returnUrl : '';
        return this.baseUrl + '/next-task/sync/poll?hashId=' + hashId + returnUrlParam;
    };

    getRepresentatives = (hashId) => {
        return this.zafuUrl + `/crm/customers?hashId=${hashId}`;
    };

    getAccountStatements = (accountNumber, prefix) => {
        return (
            this.zafuUrl +
            `/statements?accountNumber=${accountNumber}${prefix ? `&accountPrefix=${prefix}` : ''}`
        );
    };

    getAllowedParams = (backendProductId, accountPrefix, accountNumber) => {
        return (
            this.zafuUrl +
            `/statements/allowed-params?backendProductId=${backendProductId}${
                accountPrefix !== null ? `&accountPrefix=${accountPrefix}` : ''
            }${accountNumber !== null ? `&accountNumber=${accountNumber}` : ''}`
        );
    };

    /* GCORPKYC related integrations */
    getGKycInfo = (campaignInstanceId) => {
        return this.gCorpKycComponentBaseUrl + '/info?campaignInstanceId=' + campaignInstanceId;
    };

    getGKycQuestionnarie = (campaignInstanceId) => {
        return (
            this.gCorpKycComponentBaseUrl +
            '/questionnaire?campaignInstanceId=' +
            campaignInstanceId
        );
    };

    getSesToken = () => {
        return `${this.baseUrl}/token`;
    };

    getAdfs2016Token = () => {
        return `${this.baseUrl}/token/p24`;
    };

    refreshSesToken = () => {
        return `${this.baseUrl}/token/refresh`;
    };

    refreshAdfs2016Token = () => {
        return `${this.baseUrl}/token/p24/refresh`;
    };

    getOboUrl = (hashId) => {
        if (!hashId) {
            return `${this.baseUrl}/obo/context`;
        } else {
            return `${this.baseUrl}/obo/context/task?hashId=${hashId}`;
        }
    };

    deleteObo = () => {
        return `${this.baseUrl}/obo/context`;
    };

    postLoanModeling = () => {
        return this.adcaBaseUrl + '/modeling/loan-modeling';
    };

    getModelingData = (hashId) => {
        return this.adcaBaseUrl + '/modeling/data/tu001/' + hashId;
    };

    getDefaultModelingData = (hashId) => {
        return this.adcaBaseUrl + '/modeling/data/default/' + hashId;
    };

    postModelingByInstallmentCount = () => {
        return this.adcaBaseUrl + '/modeling/loan-modeling/installment-count';
    };

    postModelingByPaymentAmount = () => {
        return this.adcaBaseUrl + '/modeling/loan-modeling/payment-amount';
    };

    postSearClientAndStartCLC = () => {
        return this.adcaBaseUrl + '/client/search-client/start-credit-limit-calculation';
    };

    postTransactionSearch = () => {
        return this.fincaseBaseUrl + '/search-payments';
    };

    getFullAgreementText = (sourceSystemRecordId) => {
        return (
            this.csasBaseUrl + '/webapi/api/v2/cms/contents/gdpr/' + sourceSystemRecordId + '/cont'
        );
    };

    getShortText = (agreementType) => {
        return (
            this.csasBaseUrl +
            '/static_store/cs/Common/souhlasy/STORE_Common_souhlasy_' +
            agreementType +
            '_info.htm?format=html'
        );
    };

    getExternalId = () => {
        return this.webApiUrl + '/v2/events/externalIdentifiers';
    };

    wizardStoreDispositionRights = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/wizard/disposition-rights?hashId=${hashId}`;
    };

    wizardGetActivationQrCode = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/wizard/enrollment/george-business/activation/code?hashId=${hashId}`;
    };

    wizardGetActivationComplete = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/wizard/enrollment/george-business/activation/status?hashId=${hashId}`;
    };

    wizardFolderSign = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/wizard/contracts/finalization?hashId=${hashId}`;
    };

    wizardContractsStatus = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/wizard/contracts?hashId=${hashId}`;
    };

    wizardGetDevices = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/wizard/over-limit-method/devices?hashId=${hashId}`;
    };

    checklistGetDevices = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/over-limit-method/devices?hashId=${hashId}`;
    };

    migrationActivateDevice = (deviceIds) => {
        return `${this.webApiUrl}/v1/identity-management/identity/devices/${deviceIds}/over-limit-registration`;
    };

    migrationGetPollingInfo = (deviceIds, signId) => {
        return `${this.webApiUrl}/v1/identity-management/identity/devices/${deviceIds}/over-limit-registration/sign/${signId}`;
    };

    migrationGetPollStatus = (pollId) => {
        return `${this.webApiUrl}/v1/identity-management/sign/poll/${pollId}`;
    };

    checklistStoreNote = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/note?hashId=${hashId}`;
    };

    checklistStoreDispositionRights = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/disposition-rights?hashId=${hashId}`;
    };

    checklistMigrateDocuments = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/finalization?hashId=${hashId}`;
    };

    checklistGenerateDoc = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/generating?hashId=${hashId}`;
    };

    checklistGetData = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist?hashId=${hashId}`;
    };

    /*checklistGetClientInfo = hashId => {
        return `${this.g4bmigrBaseUrl}/migration/checklist?updateSection=CLIENT_INFO&hashId=${hashId}`;
    };

    checklistGetRepresentatives = hashId => {
        return `${this.g4bmigrBaseUrl}/migration/checklist?updateSection=DISPONENTS_LIST&hashId=${hashId}`;
    };*/

    checklistGetActivationQrCode = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/enrollment/george-business/activation/code?hashId=${hashId}`;
    };

    checklistGetActivationComplete = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/enrollment/george-business/activation/status?hashId=${hashId}`;
    };

    checklistSearchCrm = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/signatories?hashId=${hashId}`;
    };

    checklistSearchBanker = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/iam/search-users?hashId=${hashId}`;
    };

    checklistGetCountries = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/countries?hashId=${hashId}`;
    };

    checklistDownloadDoc = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/disposition-rights/legacy?hashId=${hashId}`;
    };

    checklistGetContractStatus = (hashId, checklistProcessId) => {
        if (checklistProcessId) {
            return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/statuses?hashId=${hashId}&checklistProcessId=${checklistProcessId}`;
        } else {
            return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/statuses?hashId=${hashId}`;
        }
    };

    checklistStoreSignatoryPersons = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/george-business/signatories?hashId=${hashId}`;
    };

    checklistSetContractSigned = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/handwritten-signatures?hashId=${hashId}`;
    };

    checklistErrorCase = () => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/error-case`;
    };

    checklistConfirmContractInRegistry = (hashId) => {
        return `${this.g4bmigrBaseUrl}/migration/checklist/contracts/contracts-registry?hashId=${hashId}`;
    };

    /* RKT - transactions */
    getTransactions = (hashId) => {
        return `${this.baseUrl}-rkt/rkt/transactions?hashId=${hashId}`;
    };

    /* DION - validations */
    dionValidatePayment = () => {
        return `${this.webApiUrl}/v1/dion/validatepayment`;
    };

    dionValidateMandatoryFields = () => {
        return `${this.webApiUrl}/v1/dion/mandatoryfields`;
    };

    takeTaskUrl = (hashId) => {
        return `${this.baseUrl}/bpm/take?hashId=${hashId}`;
    };

    loadGaTaskConfiguration = (hashId) => {
        return `${this.baseUrl}/ga/task/configuration?hashId=${hashId}`;
    };

    searchBranches = (
        searchText: string,
        branchTypes: string[],
        detail = 'FULL',
        country = 'CZ',
        page = 0,
    ) => {
        return `${
            this.webApiUrl
        }/v3/places?detail=${detail}&country=${country}&page=${page}&types=${branchTypes.join(
            ',',
        )}&q=${searchText}`;
    };

    getBranchOrgUnitId = (branchId: string) => {
        return `${this.webApiUrl}/v3/places/branches/id/pairs?branchId=${branchId}`;
    };

    getBranchId = (orgUnitId: string) => {
        return `${this.webApiUrl}/v3/places/branches/id/pairs?rdsId=${orgUnitId}`;
    };

    getBranch = (branchId: string) => {
        return `${this.webApiUrl}/v3/places/branches/${branchId}`;
    };

    /* DIA record endpoints */

    searchRecords = (hashId: string, fieldName: string) => {
        return `${this.baseUrl}/record/search?hashId=${hashId}&fieldName=${fieldName}`;
    };

    cancelRecord = (duid: string, hashId: string, fieldName: string) => {
        return `${this.baseUrl}/record/cancel/${duid}?hashId=${hashId}&fieldName=${fieldName}`;
    };

    addExistingRecord = (duid: string, hashId: string, fieldName: string) => {
        return `${this.baseUrl}/record/${duid}/dossiers?hashId=${hashId}&fieldName=${fieldName}`;
    };

    downloadRecord = (duid: string, hashId: string, fieldName: string) => {
        return `${this.baseUrl}/record/${duid}/download?hashId=${hashId}&fieldName=${fieldName}`;
    };

    uploadRecord = (hashId: string, fieldName: string) => {
        return `${this.baseUrl}/record/upload?hashId=${hashId}&fieldName=${fieldName}`;
    };

    updateRecord = (duid: string, hashId: string, fieldName: string) => {
        return `${this.baseUrl}/record/update/${duid}?hashId=${hashId}&fieldName=${fieldName}`;
    };

    // // put
    // uploadRecord = (cidla, recordType, hashId) => {
    //   return `${this.baseUrl}/api/record/${recordType}?hashId=${hashId}`;
    // }
    //
    // // get
    // getRecordDetail = (duid, username, hashId) => {
    //   return `${this.baseUrl}/api/record/${duid}?hashId=${hashId}`;
    // }

    // GET request for the list of similar cases
    similarCasesUrl = (fieldName, hashId) =>
        `${this.webApiUrl}/v1/smart/case/similar/${fieldName}?hashId=${hashId}`;

    getCaseDebugInfo = (hashId) => `${this.baseUrl}/case/debug-info?hashId=${hashId}`;

    getJsonBody(response: Response) {
        if (response.status === 204) {
            return Promise.resolve();
        }

        return response.json();
    }
}

//DEVC
export default new Resource();

//DEVC
//export default new Resource("http://smart-devc-vip.vs.csin.cz:5101/ngruntime/api");

// WEBAPI-INT
//export default new Resource("https://www.csast.csas.cz/webapi/api/v1/smart");

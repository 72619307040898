import React, { lazy } from 'react';
import RestCaller from '../../core/restCaller';
import Resource from '../../core/serverresource';

/**
 * Lazily loading "@csas-smart/gti-fincase-ui":
 */
const TransactionSearchFormField = lazy(() =>
    import('@csas-smart/gti-fincase-ui').then((module) => ({
        default: module.TransactionSearchFormField,
    })),
);

const TransactionSearchFormFieldContainer = (props) => {
    const searchHandler = (request) => {
        return RestCaller.httpPost(Resource.postTransactionSearch(), request);
    };

    return <TransactionSearchFormField {...props} searchHandler={searchHandler} />;
};

export default TransactionSearchFormFieldContainer;

import React, { Fragment, useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import Resource from '../core/serverresource';
import GeorgeSpinner from '../core/georgeSpinner';
import { Select } from '@george-labs.com/design-system';

const SelectCountryComp = (props) => {
    const [options, setOptions] = useState(null);

    useEffect(() => {
        loadCountries();
    }, []);

    const loadCountries = () => {
        fetch(Resource.getCodebook('CB_Country'), {
            method: 'POST',
            body: JSON.stringify(['CERTIS']),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then((result) => {
                setOptions(result.filter((c) => c.valid).map((item) => generateOption(item)));
            })
            .catch(function (error) {
                console.log('request failed', error.statusText);
            });
    };

    const generateOption = (item) => {
        return {
            label: item.value,
            value: item.id,
        };
    };

    const handleChange = (e) => {
        props.onChange(Object.assign({}, { country: e.value }));
    };

    return options ? (
        <Fragment>
            <p style={{ fontWeight: 'bold' }}>{props.label}</p>
            <FormGroup>
                <Select
                    items={options}
                    id={'select-country-comp'}
                    name={'select-country-comp'}
                    value={options.filter((option) => option.value == props.value)}
                    onSelect={handleChange}
                />
            </FormGroup>
        </Fragment>
    ) : (
        <GeorgeSpinner />
    );
};
export default SelectCountryComp;

import React, { useState } from 'react';
import { FesyncPollingField } from '@csas-smart/gti-ui-comps';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from '../core/utils/taskUtils';

const FesyncPollingFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const [error, setError] = useState(false);
    const [appLink, setAppLink] = useState('');

    const pollForSyncCase = () => {
        if (!appLink) {
            return RestCaller.httpPureGet(Resource.pollSyncNextTask(hashId, null))
                .then(Resource.checkStatus)
                .then(Resource.getJsonBody)
                .then((pollResponse) => {
                    console.log(pollResponse);
                    if (pollResponse?.applicationUrl) {
                        setAppLink(pollResponse.applicationUrl?.replace(/\?.*#/, '#'));
                    }
                })
                .catch((err) => {
                    console.error(
                        'Error during polling for the next task: ' + hashId + '. Message: ' + err,
                    );
                    setError(true);
                });
        }
    };

    const isError = () => {
        return error;
    };

    const onError = () => {
        console.error('Cancelling polling for the next task: ' + hashId);
    };

    const onPollingTimeout = () => {
        console.error('Timeout during polling for the next task: ' + hashId);
    };

    const isPollingSuccessful = () => {
        return !!appLink;
    };

    const onPollingSuccessful = () => {
        window.open(appLink, '_self');
        //new SessionWrapper().setHashId(appLink);
    };

    function parseTaskId(urlString: string) {
        const marker = '#/task/';
        const markerIndex = urlString.indexOf(marker);
        if (markerIndex !== -1) {
            return urlString.substring(markerIndex + marker.length);
        }
        return null;
    }

    return (
        <FesyncPollingField
            {...props}
            pollCondition={true}
            onPoll={() => pollForSyncCase()}
            successPollCondition={isPollingSuccessful()}
            onSuccessPoll={() => onPollingSuccessful()}
            failurePollCondition={isError()}
            onFailurePoll={() => onError()}
            onTimeout={() => onPollingTimeout()}
        />
    );
};

export default FesyncPollingFieldContainer;

import React, { lazy } from 'react';
import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import { resolveHashIdFromState } from '../core/utils/taskUtils';

// Lazily loading "@csas-smart/gti-rkt-ui":
const TransactionsSelectField = lazy(() =>
    import('@csas-smart/gti-rkt-ui').then((module) => ({
        default: module.TransactionsSelectField,
    })),
);

const TransactionsFieldContainer = (props) => {
    function getTransactionsField(page, month, merchant) {
        const transactions = {
            page: page,
            lastMonth: month,
            titleFilter: merchant,
        };
        console.log('TransactionsFieldContainer loadFunc', transactions);

        return RestCaller.httpPost(Resource.getTransactions(hashId), transactions).catch(
            (error) => {
                console.log(error);
                return [];
            },
        );
    }

    const hashId = resolveHashIdFromState();
    console.log('TransactionsFieldContainer', props);

    return (
        <div id="transactions-field-container">
            <TransactionsSelectField {...props} loadTransactionsFunc={getTransactionsField} />
        </div>
    );
};

export default TransactionsFieldContainer;

import { isEmptyString } from '../string/string-validations';

/**
 * Simple function validating, whether given string is possibly valid zipCode. Function validates only length of string.
 * @param arg Argument passed as a possible zipCode.
 * @returns {boolean}
 */
export function isValidZipCode(arg: string | undefined | null) {
    if (isEmptyString(arg)) {
        return false;
    }

    // @ts-ignore: arg is not undefined, as it was checked at the beginning of the function.
    return arg.replace(/\s/g, '').length === 5;
}

class LogLevel {
    DEBUG = 'DEBUG';

    INFO = 'INFO';

    WARN = 'WARN';

    ERROR = 'ERROR';
}

export default new LogLevel();

import React, { useEffect, useRef } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import SelectCountryComp from '../select-country-comp';
import { ValidationError } from '@csas-smart/gti-ui-comps';

export const AddressFormComp = ({ defaultAddress, fieldName, onChange, t, validations }) => {
    const addressRef = useRef(defaultAddress);

    useEffect(() => {
        validations.componentMounted(fieldName + '-addressForm', validate);

        //componentUnmount
        return () => {
            validations.componentUnmounted(fieldName + '-addressForm');
        };
    }, []);

    const validate = () => {
        const isStreetValid = validateStreet();
        const isRegistryBuildingNumberValid = validateRegistryBuildingNumber();
        const isCityValid = validateCity();
        const isZipCodeValid = validateZipCode();
        const isCountryValid = validateCountry();
        return (
            isStreetValid &&
            isRegistryBuildingNumberValid &&
            isCityValid &&
            isZipCodeValid &&
            isCountryValid
        );
    };

    const validateStreet = () => {
        if (addressRef.current.street && addressRef.current.street.trim().length < 2) {
            validations.setError({ street: { min: true } });
            return false;
        }
        validations.setError({ street: {} });
        return true;
    };

    const validateRegistryBuildingNumber = () => {
        if (!addressRef.current.registryBuildingNumber) {
            validations.setError({ registryBuildingNumber: { required: true } });
            return false;
        }
        validations.setError({ registryBuildingNumber: {} });
        return true;
    };

    const validateCity = () => {
        if (!addressRef.current.city) {
            validations.setError({ city: { required: true } });
            return false;
        }
        validations.setError({ city: {} });
        return true;
    };

    const validateZipCode = () => {
        if (!addressRef.current.zipCode) {
            validations.setError({ zipCode: { required: true } });
            return false;
        } else if (
            addressRef.current.country === 'CZ' &&
            addressRef.current.zipCode.trim().length !== 5
        ) {
            validations.setError({ zipCode: { cr: true } });
            return false;
        } else if (addressRef.current.zipCode.trim().length < 4) {
            validations.setError({ zipCode: { min: true } });
            return false;
        }
        validations.setError({ zipCode: {} });
        return true;
    };

    const validateCountry = () => {
        if (!addressRef.current.country) {
            validations.setError({ country: { required: true } });
            return false;
        }
        validations.setError({ country: {} });
        return true;
    };

    const handleChange = (key, value) => {
        const newAddress = { ...addressRef.current, [key]: value };
        newAddress.wholeAddress = getWholeAddress(newAddress);
        addressRef.current = newAddress;
        onChange(newAddress);
    };

    const onCountryChange = (value) => {
        handleChange('country', value?.country);
        validateCountry();
    };

    const getWholeAddress = (address) => {
        return `${address.street ? address.street + ' ' : ''}${
            address.buildingNumber ? address.buildingNumber + ', ' : ''
        }${address.zipCode ? address.zipCode + ', ' : ''}${address.city || ''}`;
    };

    return (
        <Row>
            <Col sm={12} md={6}>
                <Label for="street" className="col-form-label">
                    {t('field.properties.labels.street')}
                </Label>
                <Input
                    type="text"
                    name="street"
                    id="street"
                    minLength={2}
                    maxLength={70}
                    defaultValue={addressRef.current.street}
                    onBlur={validateStreet}
                    onChange={(event) => handleChange('street', event.target.value)}
                />
                <ValidationError
                    errorText={t('field.error.required')}
                    error={validations.validationError.street?.required}
                />
                <ValidationError
                    errorText={t('field.error.street.min')}
                    error={validations.validationError.street?.min}
                />
            </Col>
            <Col sm={12} md={3}>
                <Label for="registryBuildingNumber" className="col-form-label">
                    {t('field.properties.labels.registryBuildingNumber')}
                </Label>
                <Input
                    type="text"
                    name="registryBuildingNumber"
                    id="registryBuildingNumber"
                    minLength={1}
                    maxLength={5}
                    defaultValue={addressRef.current.registryBuildingNumber}
                    onBlur={validateRegistryBuildingNumber}
                    onChange={(event) => handleChange('registryBuildingNumber', event.target.value)}
                />
                <ValidationError
                    errorText={t('field.error.required')}
                    error={validations.validationError.registryBuildingNumber?.required}
                />
            </Col>
            <Col sm={12} md={3}>
                <Label for="buildingNumber" className="col-form-label">
                    {t('field.properties.labels.buildingNumber')}
                </Label>
                <Input
                    type="text"
                    name="buildingNumber"
                    id="buildingNumber"
                    maxLength={4}
                    defaultValue={addressRef.current.buildingNumber}
                    onChange={(event) => handleChange('buildingNumber', event.target.value)}
                />
            </Col>
            <Col sm={12} md={6}>
                <Label for="city" className="col-form-label">
                    {t('field.properties.labels.city')}
                </Label>
                <Input
                    type="text"
                    name="city"
                    id="city"
                    minLength={1}
                    maxLength={48}
                    defaultValue={addressRef.current.city}
                    onBlur={validateCity}
                    onChange={(event) => handleChange('city', event.target.value)}
                />
                <ValidationError
                    errorText={t('field.error.required')}
                    error={validations.validationError.city?.required}
                />
            </Col>
            <Col sm={12} md={6}>
                <Label for="zipCode" className="col-form-label">
                    {t('field.properties.labels.zipCode')}
                </Label>
                <Input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    defaultValue={addressRef.current.zipCode}
                    minLength={4}
                    maxLength={15}
                    onBlur={validateZipCode}
                    onChange={(event) => handleChange('zipCode', event.target.value)}
                />
                <ValidationError
                    errorText={t('field.error.required')}
                    error={validations.validationError.zipCode?.required}
                />
                <ValidationError
                    errorText={t('field.error.zipCode.cr')}
                    error={validations.validationError.zipCode?.cr}
                />
                <ValidationError
                    errorText={t('field.error.zipCode.min')}
                    error={validations.validationError.zipCode?.min}
                />
            </Col>
            <Col sm={12} md={12}>
                <Label for="country" className="col-form-label">
                    {t('field.properties.labels.country')}
                </Label>
                <SelectCountryComp value={addressRef.current.country} onChange={onCountryChange} />
                <ValidationError
                    errorText={t('field.error.required')}
                    error={validations.validationError.country?.required}
                />
            </Col>
        </Row>
    );
};

import SessionWrapper from '../../sessions/sessionWrapper';
import { v4 as uuidv4 } from 'uuid';
import { isUrlMep } from '../utils/urlUtils';

/**
 * enhancer sets the 'web-api-transaction-id' and 'X-Smart-CorrelationId' headers to value generated by uuidv4 third-party library
 */
class CorrelationId {
    static HEADER_TRANSACTION_ID_NAME = 'web-api-transaction-id';

    static HEADER_CORRELATION_ID_NAME = 'X-Smart-CorrelationId';

    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers, url) {
        if (!headers) {
            headers = {};
        }

        const correlationId = uuidv4();

        if (!isUrlMep(url)) {
            headers[CorrelationId.HEADER_TRANSACTION_ID_NAME] = correlationId;
            //Debug properties only
            if (
                import.meta.env.VITE_APP_AUTH_HEADER ||
                import.meta.env.VITE_APP_AUTH_HEADER_CORP ||
                import.meta.env.VITE_APP_AUTH_HEADER_BTI
            ) {
                headers[CorrelationId.HEADER_CORRELATION_ID_NAME] = correlationId;
            }
        }

        return { ...headers };
    }
}

export default CorrelationId;

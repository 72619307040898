import React from 'react';
import { SimilarCasesField } from '@csas-smart/gti-ui-comps';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { getSimilarCases } from '../actions/similarCasesAction';

const SimilarCasesFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    /**
     * Function for getting case tasks URLs.
     * @returns Promise with the list of similar cases
     * @param fieldName
     */
    const onLoadCases = async (fieldName: string) => getSimilarCases(fieldName, hashId);

    return <SimilarCasesField {...props} getCases={onLoadCases} />;
};

export default SimilarCasesFieldContainer;

import React, { Component } from 'react';
import { Alert, Button, Col, FormGroup, Row } from 'reactstrap';
import IconWrapper from '../core/lazy-wrappers/iconWrapper';
import InputComp from './input-comp';
import NumberFormat from 'react-number-format';
import Resource from '../core/serverresource';
import { validationTemplate } from '../common/validations';
import { Select } from '@george-labs.com/design-system';
import { GdsFieldContainer, ValidationError } from '@csas-smart/gti-ui-comps';
import { Field } from 'core-types';
import {
    hasNumericalCharacter,
    isEmptyObject,
    isEmptyString,
    isExisting,
    isNotEmptyString,
    isValidZipCode,
} from 'validations';

/**
 * PROPS
 *  - initialViewType, values: [editable, noneditable], desc: what view type is rendered, when component is first loaded.
 *  - allowViewTypeChange, values: [true, false], desc: is it possible to switch between editable and noneditable views of component.
 */

interface Props {
    value: any;
    field: Field;
    customLabels: any;
    tooltipKey?: string;
    validations: any;
    required: boolean;
    t: any;
    allowViewTypeChange: boolean;
    initialViewType: string;
    saveButtonLabel?: string;
    onChange: (value: any) => void;
    editable: boolean;
}

interface State {
    togglePencil: boolean;
    countries: any;
}

class AddressComp extends Component<Props, State> {
    /**       LIFECYCLE METHODS     **/
    constructor(props) {
        super(props);
        // init state
        this.state = { togglePencil: false, countries: [] };
        // bind functions
        this.setState = this.setState.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOfZipCode = this.handleChangeOfZipCode.bind(this);
        this.handleChangeOfCountry = this.handleChangeOfCountry.bind(this);
        this.generateEditableView = this.generateEditableView.bind(this);
        this.generateNonEditableView = this.generateNonEditableView.bind(this);
        this.propagateAddressError = this.propagateAddressError.bind(this);
        this.validateBuildingNumber = this.validateBuildingNumber.bind(this);
        this.validateRegistryBuildingNumber = this.validateRegistryBuildingNumber.bind(this);
        this.validateCity = this.validateCity.bind(this);
        this.validateZipCode = this.validateZipCode.bind(this);
        this.validate = this.validate.bind(this);
    }

    // On mount, component must fetch CB_Country codebook
    componentDidMount() {
        fetch(Resource.getCodebook('CB_Country'), {
            method: 'POST',
            body: JSON.stringify(['CERTIS']),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then((result) => {
                const countries = result.filter((c) => c.valid);
                // Update country if value is not set yet.
                if (isEmptyObject(this.props.value) || !this.props.value.name) {
                    const patch = {
                        country: undefined,
                    };
                    patch.country = countries[0].id;
                    this.handleChange(patch);
                }
                this.setState({ countries: countries });
            })
            .catch(function (error) {
                console.log('request failed', error.statusText);
            });

        // Submit component to validation list
        const { name } = this.props.field;
        this.props.validations.componentMounted(name + 'addressComp', this.validate);
        const defaultError = {
            buildingNumber: false,
            registryBuildingNumber: false,
            city: false,
            zipCode: false,
        };
        this.props.validations.setError({ addressCompError: defaultError });

        // Evaluate initial view type
        if (this.props.initialViewType === 'editable') {
            this.setState({ togglePencil: true });
        }
    }

    componentWillUnmount() {
        const { name } = this.props.field;
        this.props.validations.componentUnmounted(name + 'addressComp');
    }

    /**       INNER LOGIC     **/
    editAddress() {
        this.setState({
            togglePencil: !this.state.togglePencil,
        });
    }

    // Need to patch one part of address with the rest of address object, before passing it up to field.
    propagateAddressError(addressPartError) {
        let newError = { ...this.props.validations.validationError };
        newError = Object.assign(newError.addressCompError, addressPartError);
        this.props.validations.propagateError(newError);
    }

    validate() {
        return (
            // @ts-expect-error: This actually by design, so that each validation takes place even though the previous one failed.
            this.validateBuildingNumber() &
            this.validateRegistryBuildingNumber() &
            // @ts-expect-error: This actually by design, so that each validation takes place even though the previous one failed.
            this.validateCity() &
            // @ts-expect-error: This actually by design, so that each validation takes place even though the previous one failed.
            this.validateZipCode()
        );
    }

    validateBuildingNumber() {
        const { value, required } = this.props;
        return validationTemplate(
            value.buildingNumber,
            this.props.validations.validationError.addressCompError.buildingNumber,
            'buildingNumber',
            required,
            (theValue) => {
                return isEmptyString(theValue) || hasNumericalCharacter(theValue);
            },
            this.propagateAddressError,
            // @ts-expect-error: This is actually weird, how can this work?
            this.isEmptyString,
        );
    }

    // Registry building number form field validation on content - the field must contain at least one numerical character if not empty.
    validateRegistryBuildingNumber() {
        const { value, required } = this.props;
        return validationTemplate(
            value.registryBuildingNumber,
            this.props.validations.validationError.addressCompError.registryBuildingNumberWrong,
            'registryBuildingNumber',
            required,
            (theValue) => {
                return isEmptyString(theValue) || hasNumericalCharacter(theValue);
            },
            this.propagateAddressError,
            // @ts-expect-error: This is actually weird, how can this work?
            this.isEmptyString,
        );
    }

    validateCity() {
        const { value, required } = this.props;
        return validationTemplate(
            value.city,
            this.props.validations.validationError.addressCompError.city,
            'city',
            required,
            isNotEmptyString,
            this.propagateAddressError,
            // @ts-expect-error: This is actually weird, how can this work?
            this.isEmptyString,
        );
    }

    validateZipCode() {
        const { value, required } = this.props;
        return validationTemplate(
            value.zipCode,
            this.props.validations.validationError.addressCompError.zipCode,
            'zipCode',
            required,
            isValidZipCode,
            this.propagateAddressError,
            // @ts-expect-error: This is actually weird, how can this work?
            this.isEmptyString,
        );
    }

    /**       PROPS UPDATING CALLBACKS     **/

    handleChangeOfZipCode(e) {
        const patch = {
            zipCode: undefined,
        };
        patch.zipCode = e.target.value;
        this.handleChange(patch);
    }

    handleChangeOfCountry(e) {
        const patch = {
            country: undefined,
        };
        patch.country = e.value;
        this.handleChange(patch);
    }

    handleChange(patch) {
        const newValue = Object.assign(this.props.value, patch);
        this.props.onChange(newValue);
    }

    /**        COMPONENT RENDERING     **/

    getErrorMessages = (
        validations,
        fieldName,
    ): {
        lineOneErrors?: any;
        lineTwoErrors?: any;
    } => {
        // Initial rendering on server side
        if (
            Object.keys(validations.validationError).length === 0 &&
            validations.validationError.constructor === Object
        )
            return {};

        // Evaluate first line errors
        const lineOneErrors = [];
        if (validations.validationError.addressCompError.buildingNumber === true) {
            const errorText = validations.errorText.buildingNumberError;
            lineOneErrors.push(
                <ValidationError
                    key={fieldName + 'buildingNumberCompError'}
                    errorText={errorText}
                    error={true}
                />,
            );
        }
        if (validations.validationError.addressCompError.registryBuildingNumber === true) {
            const errorText = validations.errorText.registryBuildingNumberError;
            lineOneErrors.push(
                <ValidationError
                    key={fieldName + 'registryBuildingNumberError'}
                    errorText={errorText}
                    error={true}
                />,
            );
        }

        // Evaluate second line errors
        const lineTwoErrors = [];
        if (validations.validationError.addressCompError.city === true) {
            const errorText = validations.errorText.cityError;
            lineTwoErrors.push(
                <ValidationError
                    key={fieldName + 'cityCompError'}
                    errorText={errorText}
                    error={true}
                />,
            );
        }
        if (validations.validationError.addressCompError.zipCode === true) {
            const errorText = validations.errorText.zipCodeError;
            lineTwoErrors.push(
                <ValidationError
                    key={fieldName + 'zipCodeCompError'}
                    errorText={errorText}
                    error={true}
                />,
            );
        }

        return {
            lineOneErrors: lineOneErrors,
            lineTwoErrors: lineTwoErrors,
        };
    };

    genereateCountryOptions = () => {
        return this.state.countries.map((item) => ({ label: item.value, value: item.id }));
    };

    generateEditableView(props) {
        const {
            value,
            field,
            customLabels,
            tooltipKey,
            validations,
            required,
            t,
            allowViewTypeChange,
        } = props;

        const street = (
            <InputComp
                value={value.street}
                editable={true}
                attrName="street"
                name={field.name + 'street'}
                onChange={this.handleChange}
            />
        );

        const buildingNumber = (
            <InputComp
                value={value.buildingNumber}
                attrName="buildingNumber"
                editable={true}
                name={field.name + 'buildingNumber'}
                onChange={this.handleChange}
                maxLength={4}
                onBlur={this.validateBuildingNumber}
            />
        );

        const registryBuildingNumber = (
            <InputComp
                value={value.registryBuildingNumber}
                attrName="registryBuildingNumber"
                editable={true}
                maxLength={4}
                name={field.name + 'registryBuildingNumber'}
                onChange={this.handleChange}
                onBlur={this.validateRegistryBuildingNumber}
            />
        );

        const city = (
            <InputComp
                value={value.city}
                attrName="city"
                editable={true}
                name={field.name + 'city'}
                onChange={this.handleChange}
                onBlur={this.validateCity}
            />
        );
        const zipCode = (
            <NumberFormat
                className={'form-control'}
                value={isExisting(value.zipCode) ? value.zipCode : ''}
                format="### ##"
                name={field.name + 'zipCode'}
                onChange={this.handleChangeOfZipCode}
                onBlur={this.validateZipCode}
            />
        );

        // Save button is displayed only when it is allowed to switch between component editable and non-editable state;
        const saveButton =
            allowViewTypeChange === true ? (
                <Col md={{ size: 3, offset: 3 }}>
                    <FormGroup>
                        <Button
                            className="btn g-btn-outline-secondary w-100 addressSaveButton"
                            onClick={this.editAddress}
                        >
                            {this.props.saveButtonLabel}
                        </Button>
                    </FormGroup>
                </Col>
            ) : null;

        const addressNotFoundLabel = !value ? (
            <Row>
                <Col md={12}>
                    <Alert color="danger"> {customLabels.addressNotFoundLabel}</Alert>
                </Col>
            </Row>
        ) : null;
        const errorMessages = this.getErrorMessages(validations, field.name);

        return (
            <GdsFieldContainer
                field={field}
                componentId={'address'}
                tooltipKey={tooltipKey}
                required={required}
                t={t}
            >
                <Row>
                    <Col md={6}>
                        <p style={{ fontWeight: 'bold' }}>{customLabels.streetLabel}</p>
                        {street}
                    </Col>
                    <Col md={3}>
                        <p style={{ fontWeight: 'bold' }}>
                            {customLabels.registryBuildingNumberLabel}
                        </p>
                        {registryBuildingNumber}
                    </Col>
                    <Col md={3}>
                        <p style={{ fontWeight: 'bold' }}>{customLabels.buildingNumberLabel}</p>
                        {buildingNumber}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>{errorMessages.lineOneErrors}</Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <p style={{ fontWeight: 'bold' }}>{customLabels.cityLabel}</p>
                        {city}
                    </Col>
                    <Col md={6}>
                        <p style={{ fontWeight: 'bold' }}>{customLabels.zipCodeLabel}</p>
                        {zipCode}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>{errorMessages.lineTwoErrors}</Col>
                </Row>
                <Row>
                    <Col md={allowViewTypeChange === false ? 12 : 6}>
                        <p style={{ fontWeight: 'bold' }}>
                            {customLabels.countryLabel ? customLabels.countryLabel : 'Country'}
                        </p>
                        <FormGroup>
                            <Select
                                items={this.genereateCountryOptions()}
                                id={field.name + 'select-country'}
                                name={field.name + 'select-country'}
                                value={this.genereateCountryOptions().filter(
                                    (option) => option.value == value.country,
                                )}
                                onSelect={this.handleChangeOfCountry}
                            />
                        </FormGroup>
                    </Col>
                    {saveButton}
                </Row>
                {addressNotFoundLabel}
            </GdsFieldContainer>
        );
    }

    generateNonEditableView(props) {
        const {
            value,
            field,
            customLabels,
            tooltipKey,
            validations,
            editable,
            required,
            t,
            allowViewTypeChange,
        } = props;

        // Evaluate street placeholder
        let streetPlaceholder;
        if (value.street) streetPlaceholder = value.street;
        else streetPlaceholder = value.city;

        // Evaluate building numbers
        let buildingNumberPlaceholder = '';
        if (value.registryBuildingNumber && value.buildingNumber)
            buildingNumberPlaceholder = value.registryBuildingNumber + '/' + value.buildingNumber;
        else if (value.registryBuildingNumber)
            buildingNumberPlaceholder = value.registryBuildingNumber;
        else if (value.buildingNumber) buildingNumberPlaceholder = value.buildingNumber;

        // Evaluate country translation - once component will mount
        let countryTranslate;
        if (this.state.countries !== undefined && this.state.countries.length > 0) {
            countryTranslate = value.country
                ? this.state.countries.find((c) => c.id === value.country).value
                : null;
        }
        const countryPlaceholder = countryTranslate
            ? ', ' + countryTranslate
            : value.country
            ? ', ' + value.country
            : '';

        const addressNotFoundLabel = !value ? (
            <Row>
                <Col md={12}>
                    <Alert color="danger"> {customLabels.addressNotFoundLabel}</Alert>
                </Col>
            </Row>
        ) : null;

        // Evaluate error messages
        const errorMessages = this.getErrorMessages(validations, field.name);

        // Evaluate editable mode
        const pencilComponent =
            editable && allowViewTypeChange ? (
                <Col md={1}>
                    <Button
                        className="btn btn-sm g-btn-icon-only g-btn-icon-secondary"
                        onClick={this.editAddress}
                    >
                        <IconWrapper name="edit" width={16} />
                    </Button>
                </Col>
            ) : null;

        return (
            <GdsFieldContainer
                field={field}
                componentId={'address'}
                tooltipKey={tooltipKey}
                required={required}
                t={t}
            >
                <Row>
                    <Col md={editable ? 11 : 12}>
                        <p>
                            {streetPlaceholder} {buildingNumberPlaceholder}
                        </p>
                        <p>
                            {value.zipCode} {value.city}
                            {countryPlaceholder}
                        </p>
                    </Col>
                    {pencilComponent}
                </Row>
                <Row>
                    <Col md={12}>
                        {errorMessages.lineOneErrors}
                        {errorMessages.lineTwoErrors}
                    </Col>
                </Row>
                {addressNotFoundLabel}
            </GdsFieldContainer>
        );
    }

    render() {
        const { editable } = this.props;
        // Render correct component version
        if (editable && this.state.togglePencil) return this.generateEditableView(this.props);
        else return this.generateNonEditableView(this.props);
    }
}

export default AddressComp;

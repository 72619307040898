import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { getActivationComplete, getActivationQrCode } from '../../actions/mepActions';

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui":
 */
const GbizIdActivationField = lazy(() =>
    import('@csas-smart/gti-g4bmigr-ui').then((module) => ({
        default: module.GeorgeIdActivationField,
    })),
);

const GbizIdActivationFieldContainer = (props) => {
    const dispatch = useDispatch();

    const onActivationQrCodeLoad = () => {
        console.log('GbizIdActivationFieldContainer - onActivationQrCodeLoad called');

        return dispatch(getActivationQrCode(props.attributes.cluid));
    };

    const onActivationCompleteCheck = () => {
        console.log('GbizIdActivationFieldContainer - onActivationCompleteCheck called');

        return dispatch(getActivationComplete(props.attributes.cluid));
    };

    return (
        <GbizIdActivationField
            {...props}
            onActivationQrCodeLoad={onActivationQrCodeLoad}
            onActivationCompleteCheck={onActivationCompleteCheck}
        />
    );
};

export default GbizIdActivationFieldContainer;

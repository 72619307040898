import { DocumentPdfViewerModal, SigmaThemeWrapper } from '@csas-smart/gti-sigma-ui';
import React, { useState } from 'react';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import GdsLanguageProvider from '../core/gds/gds-language-provider';
import { getLocale } from '../core/i18n';

/**
 * Issues to resolve:
 * 1. DocumentPdfViewerModal does not work correctly with the LanguageProvider - wrong label on the close button.
 * 2. DocumentPdfViewerModal does not allow to set the error message from the outside - czech label every time.
 */

const LinkToFile = ({ idToDownload, label, hashId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const language = getLocale() as 'cs' | 'en';

    /**
     * Function that calls the backend to download linked file.
     * Not a thunk action, as the component is dynamically loaded, so I do not have any context.
     * @param {*} idToDownload Artificial ID of document to be downloaded.
     * @returns Blob of the downloaded file.
     */
    const downloadStaticDocument = () => {
        const url = Resource.getStaticDocument(hashId, idToDownload);
        return RestCaller.httpGetWithBinary(url).then((response) => {
            return Promise.resolve(response.blob());
        });
    };

    const handleButtonClick = () => {
        setLoading(true);
        setIsOpen(true);
        downloadStaticDocument()
            .then((theFile) => {
                setFile(theFile);
            })
            .catch((err) => {
                console.log('The loading of the file failed due to: ', err);
                setError({ stack: 'Failed to load the document.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // TODO documentName={undefined}. Prop looks useless on sigma side.
    return (
        <GdsLanguageProvider>
            <SigmaThemeWrapper language={language}>
                <span>
                    <a role="button" className={'link-to-file'} onClick={handleButtonClick}>
                        {label}
                    </a>
                    <DocumentPdfViewerModal
                        documentName={undefined}
                        documentContent={file}
                        isOpen={isOpen}
                        onClose={() => {
                            setIsOpen(false);
                        }}
                        isLoading={isLoading}
                        error={error}
                    ></DocumentPdfViewerModal>
                </span>
            </SigmaThemeWrapper>
        </GdsLanguageProvider>
    );
};

export default LinkToFile;

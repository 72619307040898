import { ContactsField } from '@csas-smart/gti-ui-comps';
import React from 'react';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { loadCodebookWithLang } from '../actions/taskActions';
import SessionWrapper from '../sessions/sessionWrapper';
import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';

/**
 * Component representing a contacts-field. For additional info please visit: https://gti-library-int.csint.cz/#/fields/contacts-field
 */
const ContactsFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();
    const sessionWrapper = new SessionWrapper();
    const fieldName = props.field?.name;

    const loadCodebookCountriesHandler = (codebook, attributes) => {
        return loadCodebookWithLang(codebook, attributes, sessionWrapper.getLanguage());
    };

    function getClientContactsHandler() {
        return RestCaller.httpGet(Resource.getClientsContacts(hashId, fieldName));
    }

    return (
        <ContactsField
            {...props}
            getClientContacts={getClientContactsHandler}
            loadCodebookCountries={loadCodebookCountriesHandler}
        />
    );
};

export default ContactsFieldContainer;

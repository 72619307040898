import { decodeBase64 } from '../../utils/objectUtils';
import { objectKeysToLowerCase } from '../../utils/queryUtils';
import SessionWrapper from '../../../sessions/sessionWrapper';

/**
 * State manager is used for handling request query parameteres specific for GeorgeTicketing application. Example of
 * these parameteres:
 *  caseType
 *  salesChannel
 *  productId
 *  transactionId
 *  etc.
 *
 * state can hold parameters like code, successURL and many other parameteres. These are not valid with this state manager
 * and this manager not process them
 */
class OidcGtiStateManager {
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * State manager can be used only if request query parameters containing 'state' param and this 'state' param is
     * base64 encoded string.
     * @param params - Request query parameters containing 'state' param and this 'state' param is
     *                 base64 encoded string.
     * @return {boolean} - true when request query parameters containing 'state' param and this 'state' param is
     * base64 encoded string.
     */
    canHandle(params) {
        const { state } = params;

        if (!state || typeof state !== 'string') {
            return false;
        }

        return !!state;
    }

    /**
     * Method accept request query parameters and take the 'state' parameter only. It resolve salesChannel (which is
     * valid application parameter) and transofr all application state keys into lower case
     * @param params
     * @return {{casetype}|{}|null}
     */
    handleParams(params) {
        //[1] - Check for mandatory parameters
        if (!this.canHandle(params)) {
            return null;
        }

        //[2] - decode state parameter
        const { state } = params;
        const decodedState = decodeBase64(state);
        if (!decodedState) {
            return null;
        }

        //[4] - if there is no 'state' contianing 'casetype' parameter then it is George state. Whole George state
        // is thrown in context of 'Gti' StateManager
        const decodedState2lowerCase = objectKeysToLowerCase(decodedState);
        //@ts-ignore
        if (!decodedState2lowerCase?.casetype) {
            return null;
        }

        //[5] - return parsed state
        return decodedState2lowerCase;
    }
}

export default OidcGtiStateManager;

import Ct1736 from './ct1736';
import DefaultCt from './default-ct';

export default class LinkCreatorDelegate {
    availableCreators = [];

    constructor() {
        this.availableCreators.push(new Ct1736());
    }

    getLinkCreator(ctx) {
        if (!ctx) {
            return new DefaultCt();
        }

        for (const creator of this.availableCreators) {
            if (creator.isApplicable(ctx)) {
                return creator;
            }
        }

        return new DefaultCt();
    }
}

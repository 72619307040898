import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { fireCaseCancelled } from '../actions/taskActions';
import authService from '../core/auths/authService';
import { AppDispatch } from '../core/store';

// Lazily loading "@csas-smart/gti-rkt-ui":
const ButtonLinkField = lazy(() =>
    import('@csas-smart/gti-rkt-ui').then((module) => ({ default: module.ButtonLinkField })),
);

const ButtonFieldContainer = (props) => {
    //const hashId = useSelector(state => resolveHashIdFromState(() => state));
    const dispatch = useDispatch<AppDispatch>();
    /*
    function handlerKillCase() {
        return RestCaller.httpDelete(Resource.killCase(hashId));
    }*/

    const handlerKillCase = () => {
        return dispatch(fireCaseCancelled(true)).finally(() => {
            // This is common path for both then and catch
            authService.getService().handover();
            // never resolved (due to handover)
            return new Promise(() => {});
        });
    };

    return (
        <div id="button-field-container">
            <ButtonLinkField {...props} onClick={() => handlerKillCase()} />
        </div>
    );
};

export default ButtonFieldContainer;

import { FieldProps } from '@csas-smart/gti-ui-comps';
import { lazy } from 'react';
import SessionWrapper from '../../sessions/sessionWrapper';

const CompanyPickerFieldLazy = lazy(() =>
    import('@csas-smart/gti-corporate-ui').then((module) => ({
        default: module.CompanyPickerField,
    })),
);

export const CompanyPickerField = (props: FieldProps) => {
    const sessionWrapper = new SessionWrapper();

    return (
        <CompanyPickerFieldLazy
            accessToken={sessionWrapper.getAccessToken()}
            azureApiKey={import.meta.env.VITE_APP_AZURE_FE_SUB_KEY}
            azureApiUrl={import.meta.env.VITE_APP_AZURE_FE_URL}
            environment={import.meta.env.VITE_ENV}
            language={sessionWrapper.getLanguage()}
            {...props}
        />
    );
};

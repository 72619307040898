import { SscsLoanPurposesField } from '@csas-smart/gti-bop-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { resolveHashIdFromState } from '../../core/utils/taskUtils';
import {
    deleteConditionDocument,
    filterFiles,
    loadDocument,
    uploadConditionFiles,
} from '../../actions/documentActions';

const SscsLoanPurposesBopFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();
    const dispatch = useDispatch();

    const loadDocumentHandler = (fieldName, document) => {
        return dispatch(loadDocument(fieldName, document));
    };

    const uploadConditionFilesHandler = (
        fieldName,
        attrName,
        files,
        filesSize,
        records,
        record,
    ) => {
        return dispatch(
            uploadConditionFiles(fieldName, attrName, files, filesSize, records, record),
        );
    };

    const deleteConditionDocumentHandler = (fieldName, attrName, document, records) => {
        return dispatch(deleteConditionDocument(fieldName, attrName, document, records));
    };

    const filterFilesHandler = (file, extensions) => {
        return filterFiles(file, extensions);
    };

    return (
        <SscsLoanPurposesField
            {...props}
            hashId={hashId}
            loadDocumentHandler={loadDocumentHandler}
            uploadConditionFilesHandler={uploadConditionFilesHandler}
            deleteConditionDocumentHandler={deleteConditionDocumentHandler}
            filterFilesHandler={filterFilesHandler}
        />
    );
};

export default SscsLoanPurposesBopFieldContainer;

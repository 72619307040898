import React from 'react';
import { Container } from 'reactstrap';
import { isMobileApp } from './utils/salesChannelUtils';
import { CardGroup, Card, StatusInfo } from '@george-labs.com/design-system';

const FinishedComponent = (props) => {
    const renderContent = () => {
        return (
            <CardGroup noLine>
                <Card className="g-card-checkout g-card-checkout-confirm">
                    <StatusInfo variant={StatusInfo.VARIANT.SUCCESS} title="Hotovo!" />
                </Card>
            </CardGroup>
        );
    };

    return (
        <>
            <Container className="appContainer">
                {!isMobileApp() ? (
                    <>
                        <div>
                            <p>&nbsp;</p>
                        </div>
                        {renderContent()}
                    </>
                ) : (
                    renderContent()
                )}
            </Container>
        </>
    );
};
export default FinishedComponent;

import SessionWrapper from '../../../sessions/sessionWrapper';
import { ADFS_CLIENT } from '../adfs2019service';
import Resource from '../../serverresource';
import AdfsTokenHelper from '../common/adfsTokenHelper';

class RefreshTokenHandler {
    sessionWrapper: SessionWrapper;

    adfsTokenHelper: AdfsTokenHelper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
        this.adfsTokenHelper = new AdfsTokenHelper();
    }

    canHandle = () => {
        return this.sessionWrapper.getRefreshToken() !== null;
    };

    handleRefresh = () => {
        const refreshToken = this.sessionWrapper.getRefreshToken();

        const request = {
            clientId: ADFS_CLIENT,
            scope: 'openid',
            refreshToken: refreshToken,
            grantType: 'refresh_token',
        };

        return fetch(Resource.refreshSesToken(), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(request),
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then((data) => {
                data.refresh_token = refreshToken;
                return data;
            })
            .then(this.adfsTokenHelper.setTokenData);
    };
}

export default RefreshTokenHandler;

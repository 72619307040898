import SessionWrapper from '../../../sessions/sessionWrapper';
import Resource from '../../serverresource';
import gtiLogger from '../../loggly/gtiLogger';

class SsoLegacyTokenHelper {
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * helper method for storing token data into sessionStorage
     * @param data
     */
    setTokenData = (data: any): Promise<void> => {
        if (!data) {
            return Promise.resolve();
        }

        this.sessionWrapper.setAccessToken(data.access_token);
        if (data.expires_in) {
            this.sessionWrapper.setAccessTokenExpiresIn(
                new Date(new Date().getTime() + data.expires_in * 1000),
            );
        } else {
            gtiLogger.info('cannot resolve expires_in for value: ' + data.expires_in);
        }

        Resource.setInterceptor();
        return Promise.resolve();
    };
}

export default SsoLegacyTokenHelper;

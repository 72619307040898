const START_SEQUENCE = '{{linkToFile ';
const END_SEQUENCE = '}}';

/**
 * Function that replaces all of the ''{{linkToFile id-parameter label-parameter}} placeholders from one template and replaces them with the 'parameter' part.
 * @param {*} template Handlerbar template possibly containing multiple '{{linkToFile id-parameter label-parameter}}' helper parts.
 * @returns Handlebar template, where all of the '{{linkToFile' construct are replaced by the 'parameter' within.
 */
export function replaceLinksToFile(template) {
    while (template.indexOf(START_SEQUENCE) > 0) {
        template = replaceLinkToFile(template);
    }
    return template;
}

/**
 * Function, that replaces one presence of the '{{linkToFile id-parameter label-parameter}}' placeholder and replaces it with the 'parameter' part.
 * @param {*} template Handlerbar template possibly containing the '{{linkToFile id-parameter label-parameter}}' helper part.
 * @returns Handlebar template, where the one of the '{{linkToFile' construct is replaced by the 'parameter' within.
 */
function replaceLinkToFile(template) {
    // ex: "The file is {{linkToFile id1 here}}."

    // ex: startIndex = 11
    const startIndex = template.indexOf(START_SEQUENCE);

    // ex: remainTemplate = {{linkToFile id1 here}}.
    const remainTemplate = template.substring(startIndex);

    // ex: endIndex = 29
    const endIndex = remainTemplate.indexOf(END_SEQUENCE);

    // ex: parameter = id1 here
    const parameterSection = remainTemplate.substring(START_SEQUENCE.length, endIndex);
    const idParameter = parameterSection.split(' ')[0];
    const labelParameter = parameterSection
        .split(' ')
        .slice(1)
        .join(' ')
        .replaceAll('"', '')
        .replaceAll("'", '');

    // ex: templateSuffix = "."
    const templateSuffix = template.substring(
        startIndex + START_SEQUENCE.length + parameterSection.length + END_SEQUENCE.length,
    );

    // ex: return "The file is here."
    return template
        .substring(0, startIndex)
        .concat(createHtmlLinkToFile(idParameter, labelParameter))
        .concat(templateSuffix);
}

function createHtmlLinkToFile(id, label) {
    return "<span id='" + id + "'>" + label + '</span>';
}

/**
 * Recursive function, that finds all the `linkToFile` constructs within the template.
 * @param {*} template The handlebar template possibly containing multiple `linkToFile` constructs.
 * @returns Array of links to be replaced / empty array. The result has following structure: [{id: 'id1', label: 'Label'}, {id: 'id2', label: 'Label 2'}, ]
 */
export function findLinksToFiles(template) {
    // ex: startIndex = 11
    const startIndex = template.indexOf(START_SEQUENCE);

    if (startIndex < 0) {
        return [];
    }

    // ex: remainTemplate = {{linkToFile id1 here}}.
    const remainTemplate = template.substring(startIndex);

    // ex: endIndex = 29
    const endIndex = remainTemplate.indexOf(END_SEQUENCE);

    // ex: parameter = id1 here
    const parameterSection = remainTemplate.substring(START_SEQUENCE.length, endIndex);
    const idParameter = parameterSection.split(' ')[0].replaceAll('"', '').replaceAll("'", '');
    const labelParameter = parameterSection
        .split(' ')
        .slice(1)
        ?.join(' ')
        ?.replaceAll('"', '')
        ?.replaceAll("'", '');

    const additionalLinks = findLinksToFiles(
        remainTemplate.substring(endIndex + END_SEQUENCE.length),
    );
    return [...additionalLinks, { id: idParameter, label: labelParameter }];
}

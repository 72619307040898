import React from 'react';
import { Button, ButtonGroup, Card, CardGroup, Col, Row } from '@george-labs.com/design-system';
import IconWrapper from '../core/lazy-wrappers/iconWrapper';
import SessionWrapper from '../sessions/sessionWrapper';
import authService from '../core/auths/authService';

interface TokenExpiredCompProps {
    destroyHandler: () => void;
    t: any;
}

/**
 * Component representing an alert. For additional info please visit: https://gti-library-int.csint.cz/#/fields/alerts-field
 */
const TokenExpired = ({ destroyHandler, t }: TokenExpiredCompProps) => {
    const refreshPageHandler = () => {
        const sessionWrapper = new SessionWrapper();
        sessionWrapper.setAccessTokenExpiresIn(null);
        sessionWrapper.clearAccessToken();
        location.reload();
    };

    const logout = () => {
        destroyHandler();
        authService.getService().logout();
    };

    return (
        <CardGroup>
            <Card>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center mb-4'}>
                        <IconWrapper
                            name="error-circle-xl"
                            className={'svg-container'}
                            style={{ color: 'red' }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center mb-2'}>
                        <h2>{t('common:screen.token-expired.logged-out.message')}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center'}>
                        {t('common:screen.token-expired.refresh-or-back.message')}
                    </Col>
                </Row>
            </Card>
            <Card>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center'}>
                        <ButtonGroup>
                            <Button
                                variant={Button.VARIANT.SECONDARY}
                                className={'m-1'}
                                onClick={logout}
                            >
                                {t('common:screen.token-expired.button.back-to-service-zone')}
                            </Button>
                            <Button
                                variant={Button.VARIANT.PRIMARY}
                                className={'m-1'}
                                onClick={refreshPageHandler}
                            >
                                {t('common:screen.token-expired.button.login')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Card>
        </CardGroup>
    );
};

export default TokenExpired;

import SessionWrapper from '../../../sessions/sessionWrapper';
import Resource from '../../serverresource';

class AdfsTokenHelper {
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * helper method for storing token data into sessionStorage
     * @param data
     */
    setTokenData = (data: any): Promise<void> => {
        if (!data) {
            return Promise.resolve();
        }

        this.sessionWrapper.setAccessToken(data.access_token);
        this.sessionWrapper.setRefreshToken(data.refresh_token);
        this.sessionWrapper.setAccessTokenExpiresIn(
            new Date(new Date().getTime() + data.expires_in * 1000),
        );

        Resource.setInterceptor();
        return Promise.resolve();
    };
}

export default AdfsTokenHelper;

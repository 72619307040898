import React from 'react';
import { LoanLimitSliderField } from '@csas-smart/gti-corp-landing-ui';
import SessionWrapper from '../sessions/sessionWrapper';

const LoanLimitSliderFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    return <LoanLimitSliderField {...props} language={sessionWrapper.getLanguage()} />;
};

export default LoanLimitSliderFieldContainer;

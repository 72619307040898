import axios from 'axios';

/**
 * The Product field is validated based on presence of a document.
 * @param setError Method for setting an error object into FE store.
 * @param required Evaluated required flag of the given field.
 * @param attributes Attributes of the given field.
 * @param field The definition of the product-field.
 * @returns true - is valid / false / is invalid.
 */
export function demoCalculatorValidationFnc(
    setError: (arg: Record<string, unknown>) => void,
    required: boolean,
    attributes: any,
): boolean {
    if (!required) {
        setError({ error: false });
        return true;
    }

    const url =
        'https://www.csast.csas.cz/webapi/api/v1/smart-demo/ngruntime/api/calculator/demo/validate';
    const headers = {
        'web-api-key': '41797e40-12d8-49fe-8214-8a46aa21fb67',
    };
    axios
        .post(
            url,
            {
                loanAmount: attributes.loanAmount,
                loanTerm: attributes.loanTerm,
            },
            { headers },
        )
        .then((result: any) => {
            if (result.data.valid) {
                setError({ error: false });
                return true;
            } else {
                setError({ error: true, message: result.data.reason });
                return false;
            }
        });
}

import React from 'react';
import {
    Badge,
    Card,
    Collapsible,
    CollapsibleContent,
    CollapsibleHeader,
    ColorDecoration,
    Paragraph,
    TabPanel,
    Tabs,
} from '@george-labs.com/design-system';
import { Field } from 'core-types';

interface Props {
    f: React.ReactElement;
    field: Field;
    required: boolean;
    visible: boolean;
    editable: boolean;
    attributes: any;

    validationError: any;
}

const FieldDebugPanel = ({
    f,
    field,
    editable,
    visible,
    required,
    attributes,
    validationError,
}: Props) => {
    return (
        <Card>
            <ColorDecoration color={ColorDecoration.COLOR.LIME} />
            {f}
            <Collapsible>
                <CollapsibleHeader>
                    <Paragraph>
                        {field.name}{' '}
                        <Badge variant={Badge.VARIANT.SUCCESS}>{field.editor.name}</Badge>{' '}
                        <Badge variant={Badge.VARIANT.STATE}>
                            {required ? 'required' : 'not required'}
                        </Badge>{' '}
                        <Badge variant={Badge.VARIANT.STATE}>
                            {visible ? 'visible' : 'not visible'}
                        </Badge>{' '}
                        <Badge variant={Badge.VARIANT.STATE}>
                            {editable ? 'editable' : 'not editable'}
                        </Badge>{' '}
                    </Paragraph>
                </CollapsibleHeader>
                <CollapsibleContent>
                    <Tabs
                        items={[
                            { label: 'Attributes' },
                            { label: 'Design' },
                            { label: 'Validation' },
                        ]}
                    >
                        <TabPanel>
                            <Card>
                                <pre>
                                    <code>{JSON.stringify(attributes, null, 2)}</code>
                                </pre>
                            </Card>
                        </TabPanel>
                        <TabPanel>
                            <Card>
                                <pre>
                                    <code>{JSON.stringify(field, null, 2)}</code>
                                </pre>
                            </Card>
                        </TabPanel>
                        <TabPanel>
                            <Card>
                                <pre>
                                    <code>{JSON.stringify(validationError, null, 2)}</code>
                                </pre>
                            </Card>
                        </TabPanel>
                    </Tabs>
                </CollapsibleContent>
            </Collapsible>
        </Card>
    );
};

export default FieldDebugPanel;

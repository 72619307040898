import React from 'react';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { AppointedPersonsField } from '@csas-smart/gti-pens-ui';

const AppointedPersonsFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const getAppointedPersons = () => {
        return RestCaller.httpGet(Resource.getAppointedPersons(hashId))
            .then((result) => {
                return result;
            })
            .catch((err) => {
                console.log('Fetching appointed persons failed: ', err);
                throw err; // Rethrow to ensure the catch block in AppointedPersonsField is executed
            });
    };

    const loadCountries = async () => {
        return fetch(Resource.getCodebook('CB_Country'), {
            method: 'POST',
            body: JSON.stringify(['CERTIS']),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .catch(function (error) {
                console.log('CB_Country codebook load request failed: ', error.statusText);
            });
    };

    return (
        <AppointedPersonsField
            {...props}
            getAppointedPersons={getAppointedPersons}
            loadCountries={loadCountries}
        />
    );
};

export default AppointedPersonsFieldContainer;

import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    Paragraph,
} from '@george-labs.com/design-system';
import React, { useContext } from 'react';
import FieldContext from '../context/fieldContext';
import { unescapeBrackets } from '../i18n';

const TogglePopup = ({ withPopup, name, isOpen, setOpen, onClick }) => {
    const contextValue = useContext(FieldContext);

    const onConfirmModal = (e) => {
        if (!e.target.value) {
            e.target.value = withPopup.key;
        }
        return onClick(e);
    };

    const createMarkup = (template) => {
        return { __html: template };
    };

    const template = unescapeBrackets(contextValue.t('field.properties.withPopup.template'));

    return (
        <Modal isOpen={isOpen} title={name} onClose={() => setOpen(false)}>
            <ModalBody>
                <Paragraph>
                    <span dangerouslySetInnerHTML={createMarkup(template)} />
                </Paragraph>
            </ModalBody>
            <ModalFooter>
                <ButtonGroup>
                    <Button
                        variant={Button.VARIANT.SECONDARY}
                        onClick={() => setOpen(false)}
                        value={withPopup?.key}
                    >
                        {contextValue.t('field.properties.withPopup.backButtonLabel')}
                    </Button>
                    <Button onClick={onConfirmModal}>
                        {contextValue.t('field.properties.withPopup.confirmButtonLabel')}
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </Modal>
    );
};
export default TogglePopup;

// @ts-nocheck

import React, { Component, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { objectKeysToLowerCase } from './utils/queryUtils';
import Resource from './serverresource';
import { fireCaseCancelled, hideError, loadEditors } from '../actions/taskActions';
import { withTranslation } from 'react-i18next';
import { getLocale, refreshTranslator, setDateFnsLocale } from './i18n';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { AppContextHandler, DeviceTypeHandler, isNotEmpty } from '@csas-smart/gti-ui-comps';
import GeorgeChatWrapper from './lazy-wrappers/george-chat-wrapper';
import { sessionLogin, tokenExpiring } from '../actions/mepActions';
import { isNumber } from '../common/validations';
import ErrorComponent from './error-comp';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/cs';
import authService from './auths/authService';
import GdsLanguageProvider from './gds/gds-language-provider';
import logger from './loggly/gtiLogger';
import { decodeBase64 } from './utils/objectUtils';
import SessionWrapper from '../sessions/sessionWrapper';
import GaTagManager from './ga-tag-manager';
import { loadActor } from '../actions/userActions';
import 'virtual:svg-icons-register';

import TokenExpired from '../comp/token-expired';
import GdsFocusPage from './gds/gds-focus-page';
import FinishedComponent from './finished-comp';

const HandlebarUtil = React.lazy(() => import('./handlebars'));
const Task = React.lazy(() => import('./task'));
const NextTask = React.lazy(() => import('./next-task'));
const ActualTask = React.lazy(() => import('./actual-task'));
const NewTask = React.lazy(() => import('./new-task'));

const mapDispatchToProps = (dispatch) => {
    return {
        loadEditors: (t, caseType, hashId) => dispatch(loadEditors(t, caseType, hashId)),
        closeErrorButton: () => dispatch(hideError()),
        fireCaseCancelled: (skipGlobalExceptionHandler) =>
            dispatch(fireCaseCancelled(skipGlobalExceptionHandler)),
        sessionLogin: (cgpHashedId) => dispatch(sessionLogin(cgpHashedId)),
        loadActor: () => dispatch(loadActor()),
        tokenExpiring: () => dispatch(tokenExpiring()),
    };
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.root.errorMessage,
        blockScreen: state.root.blockScreen,
        hashId: state.task.hashId,
        chatType: state.task.activity.chatType,
        task: state.task.georgeTask,
        actor: state.user.actor,
        tokenExpired: state.root.tokenExpired,
    };
};

interface Props {
    mergedParams: any;
    fireCaseCancelled: (_: boolean) => Promise<void>;
    loadActor: () => Promise<void>;
    loadEditors: (t: any, caseType: string, hashId: string) => Promise<void>;
    t: (arg: string) => string;
}

class App extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = { loginStatus: false, initialization: true };
        //@ts-ignore
        this.events = ['load', 'mousedown', 'click', 'keypress'];
        //@ts-ignore
        this.sessionWrapper = new SessionWrapper();
    }

    componentDidMount() {
        const mergedParams = this.props.mergedParams;
        Resource.initialize();

        authService
            .getService()
            .withTokenReceivedHandler(this.tokenReceivedHandler)
            .init(mergedParams);
    }

    tokenReceivedHandler = (params) => {
        dayjs.locale(getLocale());

        //Alien could not provide any state or any params - this code block will be executed only if params exists
        if (params) {
            //check if params is base64encoded
            if (params.state && typeof params.state === 'string') {
                console.log('app.js parse state after token receive: ' + params.state);
                const parsedParams = decodeBase64(params.state);
                const loweredParsedParams = objectKeysToLowerCase(parsedParams);
                // @ts-ignore
                if (loweredParsedParams.casetype) {
                    params = loweredParsedParams;
                }
            }

            //chat storage
            if (
                import.meta.env.VITE_APP_AUTH_HEADER &&
                params[import.meta.env.VITE_APP_URL_AUTH_PARAM]
            ) {
                window.sessionStorage.setItem(
                    import.meta.env.VITE_APP_AUTH_HEADER,
                    params[import.meta.env.VITE_APP_URL_AUTH_PARAM],
                );
            }

            if (
                import.meta.env.VITE_APP_AUTH_HEADER_CORP &&
                params[import.meta.env.VITE_APP_URL_AUTH_PARAM_CORP]
            ) {
                window.sessionStorage.setItem(
                    import.meta.env.VITE_APP_AUTH_HEADER_CORP,
                    params[import.meta.env.VITE_APP_URL_AUTH_PARAM_CORP],
                );
            }

            if (
                import.meta.env.VITE_APP_AUTH_HEADER_BTI &&
                params[import.meta.env.VITE_APP_URL_AUTH_PARAM_USER]
            ) {
                window.sessionStorage.setItem(
                    import.meta.env.VITE_APP_AUTH_HEADER_BTI,
                    params[import.meta.env.VITE_APP_URL_AUTH_PARAM_USER],
                );
            }

            this.sessionWrapper.setFromParams(params);
        }

        this.registerTokenExpireTimer();

        //Load actor
        return this.props.loadActor().then(() => {
            this.setState({ loginStatus: true, initialization: false });
        });
    };

    registerTokenExpireTimer = () => {
        if (this.sessionWrapper.getAccessTokenExpiresIn() > 0 && !this.isTokenExpired()) {
            this.tokenExpireTimer = setInterval(this.tokenExpiredHandler, 5000);
        } else if (this.isTokenExpired()) {
            this.tokenExpiredHandler();
        }
    };

    tokenExpiredHandler = () => {
        if (this.isTokenExpired()) {
            authService
                .getService()
                .refreshToken()
                .catch(() => {
                    if (this.tokenExpireTimer) {
                        clearInterval(this.tokenExpireTimer);
                    }
                    this.props.tokenExpiring();
                });
        }
    };

    isTokenExpired = () => {
        return new Date().getTime() >= this.sessionWrapper.getAccessTokenExpiresIn();
    };

    registerEvents = () => {
        //@ts-ignore
        for (const i in this.events) {
            //@ts-ignore
            window.addEventListener(this.events[i], this.resetTimeout);
        }
    };

    initComplete = () => {
        //@ts-ignore
        return this.props
            .loadEditors(
                this.props.t,
                this.sessionWrapper.getCaseType(),
                this.sessionWrapper.getHashId(),
            )
            .then(() => {
                refreshTranslator();
                this.registerEvents();
                this.setTimeout();
            });
    };

    shouldComponentUpdate() {
        return true;
    }

    resetTimeout = () => {
        this.clearTimeout();
        this.setTimeout();
    };

    clearTimeout = () => {
        // @ts-ignore
        if (this.logoutTimeout) {
            // @ts-ignore
            clearTimeout(this.logoutTimeout);
        }
    };

    setTimeout = () => {
        const strLogoutTimeout = authService.getService().getLogoutTimeout();
        const logoutTimeout =
            isNotEmpty(strLogoutTimeout) && isNumber(strLogoutTimeout)
                ? Number(strLogoutTimeout) * 1000
                : -1;

        if (logoutTimeout > 0) {
            this.logoutRetries = 0;
            this.logoutTimeout = setTimeout(this.logout, logoutTimeout);
        }
    };

    logout = () => {
        // Send a logout request to the API
        logger.info('Sending a logout request to the API...');
        this.destroy();

        authService.getService().logout().catch(this.handleLogoutError);
    };

    handleLogoutError = (err) => {
        logger.info(`logout failed: ${err}`);
        this.logoutRetries++;

        const baseRetryDelta = import.meta.env.VITE_APP_LOUGOUT_RETRY_BASE_DELTA_SECS || 0.5;
        const retryDeltaCap = import.meta.env.VITE_APP_LOGOUT_RETRY_MAX_DELTA_SECS || 10;
        const retryDelta = Math.min(
            retryDeltaCap,
            baseRetryDelta * Math.pow(2, this.logoutRetries - 1),
        );

        logger.info(`Next Retry #${this.logoutRetries} in ${retryDelta} secs`);
        // intentionally not storing timeout handle in this.logoutTimeout to prevent interrupting logout flow
        setTimeout(this.logout, retryDelta * 1000);
    };

    destroy = () => {
        this.clearTimeout();

        for (const i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    };

    closeErrorButton = () => {
        this.props.closeErrorButton();
    };

    render() {
        setDateFnsLocale();

        if (this.state.initialization) {
            return null; //todo - tady by to chtelo mzurku
        }

        //TODO - login failed - invalid token, non-existing sessionId
        if (!this.state.loginStatus) {
            this.logout();
            return null;
        }

        const helmet = <Helmet htmlAttributes={{ lang: getLocale() }} />;

        const { chatType, actor } = this.props;
        const chatWrapper =
            chatType && chatType !== 'NONE' ? <GeorgeChatWrapper chatType={chatType} /> : null;
        const tokenExpiredComp = (
            <GdsFocusPage activity={{}}>
                <TokenExpired destroyHnadler={this.destroy} t={this.props.t} />
            </GdsFocusPage>
        );

        const content = this.props.blockScreen ? (
            <ErrorComponent message={this.props.errorMessage} t={this.props.t} />
        ) : this.props.tokenExpired ? (
            tokenExpiredComp
        ) : (
            <div className="g-bs4 g-bootstrap notranslate">
                <Suspense fallback={null}>
                    <HandlebarUtil />
                </Suspense>
                <div className="g-store">
                    <Suspense fallback={null}>
                        <Routes>
                            <Route
                                path="task/next/:hashId"
                                element={<NextTask initCompleteCallback={this.initComplete} />}
                            />
                            <Route
                                path="task/:hashId"
                                element={<ActualTask initCompleteCallback={this.initComplete} />}
                            />
                            <Route
                                path="/"
                                element={<NewTask initCompleteCallback={this.initComplete} />}
                            />

                            <Route path="zadost" element={<Task />} />
                            <Route path="finished" element={<FinishedComponent />} />
                        </Routes>
                    </Suspense>
                </div>

                <Modal
                    isOpen={this.props.errorMessage != null && !this.props.blockScreen}
                    backdrop={'static'}
                    wrapClassName="g-bs4 g-bootstrap g-store fontsize14"
                    modalClassName="g-modal"
                >
                    <ModalHeader>{this.props.t('common:popup.error.title')}</ModalHeader>
                    <ModalBody>
                        {this.props.errorMessage != null &&
                        this.props.errorMessage.split(' ').length == 1
                            ? this.props.t('common:popup.error.' + this.props.errorMessage)
                            : this.props.errorMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'primary'} onClick={this.closeErrorButton}>
                            {this.props.t('common:popup.error.button.close')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );

        return (
            <GdsLanguageProvider>
                <AppContextHandler actor={actor}>
                    <DeviceTypeHandler>
                        {helmet}
                        {chatWrapper}
                        {content}
                        <GaTagManager />
                    </DeviceTypeHandler>
                </AppContextHandler>
            </GdsLanguageProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(App));

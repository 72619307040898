import { UploadFiles, UpdateFile, getRandomString } from '@csas-smart/gti-ui-comps';
import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import { fireAttrChange } from './taskActions';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { isEmptyArray } from 'validations';

export function loadDocument(fieldName, document) {
    return () => {
        const hashId = resolveHashIdFromState();
        const url = Resource.getDocument(fieldName, hashId, document);

        return RestCaller.httpGetWithBinary(url).then((response) => {
            const contentDispositionHeaderMatch = response.headers
                .get('Content-Disposition')
                .match(/filename="([^"]+)"/);
            const contentDispositionHeader = contentDispositionHeaderMatch
                ? contentDispositionHeaderMatch[1]
                : null;
            return Promise.resolve({
                filename: contentDispositionHeader,
                blob: response.blob(),
            });
        });
    };
}

export function getRecord(duid) {
    return () => {
        const hashId = resolveHashIdFromState();

        return RestCaller.httpGetWithBinary(Resource.getRecordContent(duid, hashId));
    };
}

export function pregenerateRecord(fieldName, templateDefinitionHash) {
    return () => {
        const hashId = resolveHashIdFromState();

        return RestCaller.httpGetWithBinary(
            Resource.pregenerateRecord(fieldName, templateDefinitionHash, hashId),
        );
    };
}

export function loadDocumentsByDocumentIds(
    documentIds: string[],
    recordTypeFilters,
    fieldName: string,
) {
    console.log('Loading document by documentIds:', fieldName, recordTypeFilters, documentIds);
    const hashId = resolveHashIdFromState();

    return RestCaller.httpGet(
        Resource.loadDocumentsByDocumentIds(documentIds, hashId, fieldName),
    ).then(
        (result) =>
            isEmptyArray(recordTypeFilters) || isEmptyArray(result)
                ? result
                : result.filter((item) => recordTypeFilters.includes(item.documentType)),
        (error) => {
            console.log('request failed', error);
            return Promise.reject(error);
        },
    );
}

const updateBopRecords = (records, record) => {
    const recordIndex = records?.findIndex((r) => r.conditionUuid === record.conditionUuid);
    records[recordIndex] = record;
    return [...records];
};

export function uploadConditionFiles(fieldName, attrName, files, filesSize, records, record) {
    return (dispatch) => {
        const request = new FormData();
        files.forEach((file) => request.append('file', file));

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPostWithBinary(
            Resource.uploadDocument(hashId, fieldName),
            request,
        ).then(
            (result) => {
                //Check if we are adding first document to the array -> set statuses to IN_PROGRESS/UPLOADED
                if (record.documents && record.documents.length === 0) {
                    record.status = 'UPLOADED';
                }
                record.documents = [...record.documents, ...result];
                const updatedRecords = updateBopRecords(records, record);
                dispatch(fireAttrChange({ name: attrName, value: updatedRecords, append: false }));
            },
            (error) => {
                console.log('request failed', error);
                error.skipGlobalExceptionHandler = true; // Showing the error must be handled by the caller component.
                return Promise.reject(error);
            },
        );
    };
}

export const uploadFiles: UploadFiles = (fieldName, attrName, files, setUploadProgress) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setUploadProgress && setUploadProgress();
    const hashId = resolveHashIdFromState();
    const promises = files.map(async (record) => {
        const tmpId = getRandomString(6);

        try {
            const formData = new FormData();
            formData.append('file', record.file);

            if ('type' in record) {
                const response = await RestCaller.httpPostWithBinary(
                    Resource.uploadDocumentWithType(hashId, fieldName, record.type),
                    formData,
                    (event) => {
                        setUploadProgress(tmpId, {
                            tmpId,
                            fileName: record.file.name,
                            percent: Math.ceil(((event.loaded + event.loaded) / event.total) * 100),
                        });
                    },
                );

                // TODO: Middleware is not returning versionCreated when uploading new record. MW team is working on the issue.
                // So this "hack" should be removed in the near future
                if (
                    typeof response === 'object' &&
                    'versionCreated' in response &&
                    !response.versionCreated
                ) {
                    response.versionCreated = Date.now();
                }

                return response;
            }

            // This endpoint allows to upload multiple documents so the response is also array.
            // But in our case we are uploading them one by one so .shift() prevents returning nested array
            return (
                (await RestCaller.httpPostWithBinary(
                    Resource.uploadDocument(hashId, fieldName),
                    formData,
                    setUploadProgress
                        ? (event) =>
                              setUploadProgress(tmpId, {
                                  tmpId,
                                  fileName: record.file.name,
                                  percent: Math.round((event.loaded / record.file.size) * 100),
                              })
                        : undefined,
                )) ?? []
            ).shift();
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            setUploadProgress &&
                setUploadProgress(tmpId, {
                    tmpId,
                    fileName: record.file.name,
                    percent: 0,
                    error: 'Nepodařilo se nahrát dokument.',
                });
            return null;
        }
    });
    return Promise.all(promises).then((records) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setUploadProgress && setUploadProgress();

        const uploadedRecords = records.filter(Boolean);

        if (!uploadedRecords.length) {
            throw new Error('Failed to upload any document.');
        }
        return uploadedRecords;
    });
};

export const updateFile: UpdateFile = async (
    documentId,
    fieldName,
    { file, type },
    setUploadProgress,
) => {
    if (setUploadProgress) {
        setUploadProgress();
    }
    const hashId = resolveHashIdFromState();
    const tmpId = getRandomString(6);

    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await RestCaller.httpPutWithBinary(
            Resource.updateDocument(hashId, documentId, fieldName),
            formData,
            (event) => {
                setUploadProgress(tmpId, {
                    tmpId,
                    fileName: file.name,
                    percent: Math.ceil(((event.loaded + event.loaded) / event.total) * 100),
                });
            },
        );

        // TODO: Middleware is not returning versionCreated when uploading new record. MW team is working on the issue.
        // So this "hack" should be removed in the near future
        if (
            typeof response === 'object' &&
            'versionCreated' in response &&
            !response.versionCreated
        ) {
            response.versionCreated = Date.now();
        }
        if (typeof response === 'object' && 'fileName' in response && !response.fileName) {
            response.fileName = file.name;
        }
        if (typeof response === 'object' && 'documentName' in response && !response.documentName) {
            response.documentName = file.name;
        }

        return response;
    } catch (e) {
        console.error(e);
        if (setUploadProgress) {
            setUploadProgress(tmpId, {
                tmpId,
                fileName: file.name,
                percent: 0,
                error: 'Nepodařilo se nahrát dokument.',
            });
        }
        return null;
    }
};

export function deleteConditionDocument(fieldName, attrName, document, records) {
    return (dispatch) => {
        console.log('Deleting document ' + document);
        const hashId = resolveHashIdFromState();
        return RestCaller.httpDelete(Resource.deleteDocument(fieldName, hashId, document)).then(
            () => dispatch(fireAttrChange({ name: attrName, value: records, append: false })),
            (error) => {
                console.log('deleting document ' + document + ' failed', error);
                return Promise.reject(error);
            },
        );
    };
}

export async function deleteDocument(fieldName, attrName, document) {
    console.log('Deleting document ' + document);
    const hashId = resolveHashIdFromState();
    await RestCaller.httpDelete(Resource.deleteDocument(fieldName, hashId, document));
}

export const extensionsMapper = {
    'image/jpeg': '.jpeg', //also for jfif / jfif-tbnl
    'image/png': '.png',
    'image/jpg': '.jpg',
    'image/jpe': '.jpe',
    'image/gif': '.gif',
    'application/pdf': '.pdf',
    'application/zip': '.zip',
    'application/x-zip-compressed': '.zip',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/msword': '.doc',
    'application/vnd.ms-word.template.macroEnabled.12': '.dotm',
};

export function filterFiles(file, extensions) {
    return !!extensionsMapper[file.type] && extensions.includes(extensionsMapper[file.type]); //filtering files with unsupported extensions
}

import React, { Fragment, useEffect, useState } from 'react';
import EmployerSearchComp from '../comp/employer-search-comp';
import GeorgeSpinner from '../core/georgeSpinner';
import AddressComp from '../comp/address-comp';
import MonthYearSelectComp from '../comp/month-year-select-comp';
import { isExisting } from 'validations';

const getTranslations = (
    t,
): {
    streetLabel: string;
    buildingNumberLabel: string;
    registryBuildingNumberLabel: string;
    cityLabel: string;
    zipCodeLabel: string;
    countryLabel: string;
    employerAddress: string;
    incomeTransfer: string;
    buildingNumberError: string;
    registryBuildingNumberEmptyError: string;
    regustryBuildingNumberWrongError: string;
    zipCodeError: string;
    cityError: string;
    customLabels: {
        name: string;
        registrationNumber: string;
    };
} => {
    return {
        streetLabel: t('common:comp.address-comp.streetLabel'),
        buildingNumberLabel: t('common:comp.address-comp.buildingNumberLabel'),
        registryBuildingNumberLabel: t('common:comp.address-comp.registryBuildingNumberLabel'),
        cityLabel: t('common:comp.address-comp.city'),
        zipCodeLabel: t('common:comp.address-comp.zipCode'),
        countryLabel: t('common:comp.address-comp.country'),
        employerAddress: t('common:field.employer-search-field.employerAddress'),
        incomeTransfer: t('common:field.employer-search-field.incomeTransfer'),
        buildingNumberError: t('common:field.employer-search-field.buildingNumberError'),
        registryBuildingNumberEmptyError: t(
            'common:field.employer-search-field.registryBuildingNumberEmptyError',
        ),
        regustryBuildingNumberWrongError: t(
            'common:field.employer-search-field.registryBuildingNumberWrongError',
        ),
        zipCodeError: t('common:field.employer-search-field.zipCodeError'),
        cityError: t('common:field.employer-search-field.cityError'),
        customLabels: {
            name: t('common:field.employer-search-field.mainTitle'),
            registrationNumber: t('common:field.employer-search-field.registrationNumber'),
        },
    };
};

const EmployerSearchField = (props) => {
    const { field, t, required, editable, validations } = props;

    const [addressValidationFnc, setAddressValidationFnc] = useState<unknown>(function () {});
    const [translations] = useState(getTranslations(t));

    const attrName = field.selector.attributes.thirdParties.name;
    const value =
        props.attributes.thirdParties && props.attributes.thirdParties[0]
            ? props.attributes.thirdParties[0]
            : null;

    useEffect(() => {
        // When attribute empty, init attribute value
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !props.attributes.thirdParties || props.attributes.thirdParties.length === 0
            ? propagateAttributeChange({})
            : null;
    }, []);

    const propagateAttributeChange = (newValue) => {
        props.fieldValueChanged({ name: attrName, value: [newValue] });
    };

    /** Validations **/
    const propagateError = (error) => {
        const newError = Object.assign(validations.validationError, error);
        validations.setError(newError);
    };

    const propagateAddressError = (error) => {
        propagateError({ addressCompError: error });
    };

    // Change attribute from search field
    const handleChangeFromSearch = (newCompany) => {
        const newValue = Object.assign(newCompany, {
            incomeTransferDate: value.incomeTransferDate,
        });
        props.fieldValueChanged({ name: attrName, value: [newValue] });
    };

    // On blur from search comp, perform address validation
    const handleSearchBlur = (newCompany) => {
        const newValue = Object.assign(newCompany, {
            incomeTransferDate: value.incomeTransferDate,
        });
        props.fieldValueChanged({ name: attrName, value: [newValue] });
        // Revalidate address field, when there is an error in address comp
        if (validations.validationError && validations.validationError.addressCompError) {
            const isAddressError = Object.values(
                validations.validationError.addressCompError,
            ).reduce((a, b) => a || b);
            if (isAddressError && addressValidationFnc instanceof Function) {
                console.log('Evaluating addrress call due to: ' + isAddressError);
                addressValidationFnc.call(null);
            } else {
                console.log('Not evaluating address due to: ' + isAddressError);
            }
        }
    };

    // Change attribute from address field
    const handleChangeManual = (newCompany) => {
        const newValue = Object.assign(newCompany, {
            incomeTransferDate: value.incomeTransferDate,
        });
        props.fieldValueChanged({ name: attrName, value: [newValue] });
    };

    // Change incoming from month-year-comp
    const onIncomeChange = (newIncome) => {
        const patch = { incomeTransferDate: newIncome };
        const newValue = Object.assign(value, patch);
        handleChangeFromSearch(newValue);
    };

    const addressComponentMounted = (componentName, validationFnc) => {
        setAddressValidationFnc(() => validationFnc);
        props.validations.componentMounted(componentName, validationFnc);
    };

    /** ADDRESS COMPONENT SETUP **/

    const addressValidations = {
        ...validations,
        propagateError: propagateAddressError,
        componentMounted: addressComponentMounted,
        errorText: translations,
    };

    const searchCompanyValidations = {
        ...validations,
        propagateError: propagateError,
    };

    // When value is not yet initialized, render George Spinner. Otherwise render component view.
    const componentView = isExisting(value) ? (
        <Fragment>
            <EmployerSearchComp
                field={field}
                t={t}
                required={required}
                editable={editable}
                value={value}
                handleChange={handleChangeFromSearch}
                handleBlur={handleSearchBlur}
                validations={searchCompanyValidations}
                customLabels={translations.customLabels}
                isRegNumberRequired={false}
                defaultValue={{ isManual: true, country: 'CZ' }}
            />

            <AddressComp
                field={{ ...field, label: translations.employerAddress }}
                value={value}
                editable={editable}
                required={true}
                onChange={handleChangeManual}
                customLabels={translations}
                validations={addressValidations}
                allowViewTypeChange={false}
                initialViewType={'editable'}
                t={t}
            />

            <MonthYearSelectComp
                field={{ ...field, label: translations.incomeTransfer }}
                t={t}
                required={required}
                value={value.incomeTransferDate}
                onChange={onIncomeChange}
            />
        </Fragment>
    ) : (
        <GeorgeSpinner />
    );

    return componentView;
};

export default EmployerSearchField;

import React from 'react';
import { FieldProvider } from './fieldContext';
import { useTranslation } from 'react-i18next';

const FieldContextHandler = (props) => {
    const { t, i18n } = useTranslation<any>(props.field.name);

    return (
        <FieldProvider
            value={{
                field: props.field,
                i18n: i18n,
                t: t,
                activityAttributes: props.activityAttributes,
            }}
        >
            {props.children}
        </FieldProvider>
    );
};

export default FieldContextHandler;

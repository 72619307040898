import React, { useState } from 'react';
import { RecordListField } from '@csas-smart/gti-ui-comps';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { useAppSelector } from '../core/hooks/hooks';
import { loadCodebookWithLang } from '../actions/taskActions';
import { CombinedDocumentViewer } from '@csas-smart/gti-sigma-ui';
import { resolveFilenameFromResponseHeaders } from '../common/reusableFunctions';
type SetDownloadProgress = (duid: string, percent: number, error?: string) => void;
type DownloadRecord = (duid: string, setDownloadProgress: SetDownloadProgress) => Promise<void>;

const RecordListFieldContainer = (props) => {
    const hashId = useAppSelector(() => resolveHashIdFromState());
    const { attributes } = useAppSelector((state) => state.task.activity);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [document, setDocument] = useState(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState(null);
    const { t } = props;

    /**
     * Method that searches records according to field properties.
     * @param fieldName The name of the field used for the search.
     * @returns List of records according to defined 'loadByExpressions'.
     */
    // FIXME: Export RecordDto from gti-ui-comps to use as return type.
    // FIXME: Export Request from gti-ui-comps to as parameter.
    const searchRecords = (request: any): Promise<any[]> => {
        return RestCaller.httpPost(Resource.searchRecords(hashId, request.fieldName), attributes);
    };

    const loadCodebook = (codebook: string, attrs: any) => {
        return loadCodebookWithLang(codebook, attrs, 'cs');
    };

    const deleteRecord = (duid: string) => {
        return RestCaller.httpPostWithoutResponse(
            Resource.cancelRecord(duid, hashId, props.field.name),
            attributes,
        );
    };

    const attachExistingRecord = (duid: string) => {
        return RestCaller.httpPut(
            Resource.addExistingRecord(duid, hashId, props.field.name),
            attributes,
        );
    };

    const getRecordDetail = async (request: any) => {
        setDocument(null);
        setLoading(true);
        setIsOpen(true);

        try {
            const response = await RestCaller.httpGetWithBinary(
                Resource.downloadRecord(request.duid, hashId, props.field.name),
            );
            const contentType = response.headers.get('Content-Type');
            let blob = null;
            let mimeType = null;

            if (contentType.startsWith('application/pdf')) {
                mimeType = CombinedDocumentViewer.SUPPORTED_TYPES.APPLICATION_PDF;
                blob = await response.blob();
            } else if (
                contentType.startsWith(
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                )
            ) {
                mimeType = CombinedDocumentViewer.SUPPORTED_TYPES.DOCX;
                blob = await response.blob();
            } else {
                mimeType = '';
            }

            setDocument({
                blob,
                mimeType,
            });
        } catch (err) {
            setError({ stack: t('common:field.recordlist-field.documentLoadFailed') });
        }
        setLoading(false);
    };

    const uploadRecord = (request) => {
        console.log('Upload request, desired attributes: ', attributes);
        const promises = request.records.map((record) => {
            const formData = new FormData();
            formData.append('file', record.file);
            const payload = {
                recordType: record.type,
                uploadAttributes: [], // Sending 'attributes' causes an issue on firewall, as max number of params is exceeded. We probably need to filter them out. ??
            };
            formData.append(
                'json',
                new Blob([JSON.stringify(payload)], {
                    type: 'application/json',
                }),
            );
            return RestCaller.httpPostWithBinary(
                Resource.uploadRecord(hashId, props.field.name),
                formData,
            );
        });
        return Promise.all(promises).then((records) => {
            console.log('UPLOAD:', records);
            return records;
        });
    };

    const downloadRecord: DownloadRecord = async (duid, setDownloadProgress) => {
        const res = await RestCaller.httpGetWithBinary(
            Resource.downloadRecord(duid, hashId, props.field.name),
        );
        const contentLength = res.headers.get('content-length');
        const fileName = resolveFilenameFromResponseHeaders(res);
        let loaded = 0;

        if (!fileName) {
            setError({ stack: t('common:field.recordlist-field.failedToDownloadRecord') });
            setDownloadProgress(
                duid,
                100,
                t('common:field.recordlist-field.failedToDownloadRecord'),
            );
            setLoading(false);
            return;
        }

        const response = await new Response(
            new ReadableStream({
                start(controller) {
                    const reader = res.body.getReader();

                    read();
                    function read() {
                        reader.read().then((progressEvent) => {
                            if (progressEvent.done === true) {
                                controller.close();
                                return;
                            }
                            loaded += progressEvent.value.byteLength;
                            const downloadedPercent = (loaded / contentLength) * 100;
                            setDownloadProgress(duid, Math.round(downloadedPercent));
                            controller.enqueue(progressEvent.value);
                            read();
                        });
                    }
                },
            }),
        );

        const blob = await response.blob();
        const url1 = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url1;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url1);
    };

    const updateRecord = (request: any) => {
        const formData = new FormData();
        formData.append('file', request.record.file);

        return RestCaller.httpPostWithBinary(
            Resource.updateRecord(request.duid, hashId, props.field.name),
            formData,
        );
    };

    return (
        <>
            <RecordListField
                {...props}
                searchRecords={searchRecords}
                loadCodebook={loadCodebook}
                deleteRecord={deleteRecord}
                addRecord={attachExistingRecord}
                displayFile={getRecordDetail}
                uploadRecord={uploadRecord}
                updateRecord={updateRecord}
                downloadRecord={downloadRecord}
            />

            <CombinedDocumentViewer
                document={document}
                documentName={undefined}
                isOpen={isOpen}
                isLoading={isLoading}
                error={error}
                onClose={() => {
                    setIsOpen(false);
                }}
                t={t}
            />
        </>
    );
};

export default RecordListFieldContainer;

/**
 * Function validates, that given string has a content: is not undefined, null and contains at least one char.
 * @param arg
 * @returns {boolean}
 */
export function isNotEmptyString(arg: string | undefined | null) {
    return arg !== undefined && arg !== null && arg.trim().length > 0;
}

/**
 * Function validates, that given string has no content: is undefined, null or empty.
 * @param arg
 * @returns {boolean}
 */
export function isEmptyString(arg: string | undefined | null) {
    return !isNotEmptyString(arg);
}

/**
 * Function validates, that given value contains at least one numerical character.
 * @param arg Argument passed into function to be tested.
 * @returns {boolean}
 */
export function hasNumericalCharacter(arg: string | null | undefined) {
    if (isEmptyString(arg)) {
        return false;
    }

    return /\d/.test(<string>arg);
}

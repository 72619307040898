import React from 'react';
import { UserField } from '@csas-smart/gti-ui-comps';
import SessionWrapper from '../sessions/sessionWrapper';
import { searchUsers } from '../actions/userActions';

const UserFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();
    const hashId = sessionWrapper.getHashId();

    const searchUsersInternal = (request) => {
        return searchUsers(hashId, request);
    };

    return <UserField {...props} search={searchUsersInternal} />;
};

export default UserFieldContainer;

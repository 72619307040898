import React from 'react';
import { PromoImageField } from '@csas-smart/gti-ui-comps';
import { Col, Row } from '@george-labs.com/design-system';

const PromoImageFieldContainer = (props) => {
    return (
        <Row>
            <Col className={'p-0'}>
                <PromoImageField {...props} />
            </Col>
        </Row>
    );
};

export default PromoImageFieldContainer;

import oidcService from './oidcService';
import legacyService from './legacyService';
import mockService from './mockService';
import adfs2019Service from './adfs2019service';
import adfs2016Service from './adfs2016service';
import SessionWrapper from '../../sessions/sessionWrapper';
import adfsService from './adfsService';
import gtiLogger from '../loggly/gtiLogger';

export const ALLOWED_ADFS_SALES_CHANNELS = ['STARTER', 'FZ', 'CSHUB', 'PU', 'BRANCH_PERSONAL', 'B'];
export const ALLOWED_STORE_SALES_CHANNELS = ['STORE', 'STORE_GO'];
export const LEGACY_SALES_CHANNELS = ['B24'];

class AuthService {
    authServices = [];

    authService;

    sessionWrapper: SessionWrapper;

    constructor() {
        this.authServices.push(oidcService);
        this.authServices.push(legacyService);
        this.authServices.push(adfsService);
        this.authServices.push(adfs2019Service);
        this.authServices.push(adfs2016Service);
        this.authServices.push(mockService);

        this.sessionWrapper = new SessionWrapper();
    }

    getService() {
        if (import.meta.env.VITE_APP_AUTH_TYPE === 'mock') {
            return mockService;
        }

        const authType = this.sessionWrapper.getAuthType()
            ? this.sessionWrapper.getAuthType()
            : import.meta.env.VITE_APP_AUTH_TYPE;
        const supportedAuthService = this.authServices.filter((service) =>
            service.supportType(authType),
        );

        if (supportedAuthService.length === 1) {
            this.sessionWrapper.setAuthType(supportedAuthService[0].getSupportedAuthType());
            return supportedAuthService[0];
        }

        gtiLogger.error(
            `Found more than 1 supported authService: ${supportedAuthService.map(
                (service) => service.SUPPORTED_AUTH_TYPE,
            )}`,
        );
        return null;
    }
}

export default new AuthService();

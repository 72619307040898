import { DeviceContext, DeviceContextProps, Field } from '@csas-smart/gti-ui-comps';
import React, { useContext } from 'react';
import { movePrimaryToBegin, movePrimaryToEnd } from '../../common/reusableFunctions';
import SignButton from '../../comp/sign-button';
import ToggleButton from '../../comp/toggle-button';
import { ButtonGroup } from '@george-labs.com/design-system';
import gtiLogger from '../../core/loggly/gtiLogger';
import { AsyncOnceClickableComponentContainerControls } from '@csas-smart/gti-ui-comps/dist/container/types';
import { ButtonProps } from '../../types/design';
import {
    AttributeChange,
    CompleteActivityContext,
} from '@csas-smart/gti-ui-comps/dist/types/types';

interface FooterSignToggleGroupProps {
    buttons: ButtonProps[];
    onClick: (evt) => Promise<void>;
    btnWidth: number;
    isDisabled: boolean;
    editable: boolean;
    field: Field;
    fieldValueChanged: (arg: AttributeChange) => Promise<void>;
    completeActivityAction: (context?: CompleteActivityContext) => Promise<void>;
    containerControls: AsyncOnceClickableComponentContainerControls;
    validateActivityAction: () => boolean;
}

const FooterSignTogglegroup = ({
    buttons,
    onClick,
    btnWidth,
    isDisabled,
    editable,
    field,
    fieldValueChanged,
    completeActivityAction,
    validateActivityAction,
    containerControls,
}: FooterSignToggleGroupProps) => {
    const deviceContext = useContext<DeviceContextProps>(DeviceContext);
    let options = [...buttons];
    options = options.sort(deviceContext.isMobile ? movePrimaryToBegin : movePrimaryToEnd);

    const handleRejection = async (evt) => {
        if (!containerControls.setContainerDisabled || !containerControls.clearContainerDisabled) {
            gtiLogger.warn('Cannot work with container controls');
        }

        containerControls?.setContainerDisabled();
        await onClick(evt);
        containerControls?.clearContainerDisabled();
    };

    const generateSecondaryButton = (option) => {
        return (
            <ToggleButton
                option={option}
                onClick={handleRejection}
                width={btnWidth}
                disabled={isDisabled || !editable}
            />
        );
    };

    const generatePrimaryButton = (option) => {
        return (
            <SignButton
                field={field}
                option={option}
                onSignedHandler={onClick}
                disabled={isDisabled || !editable}
                fieldValueChanged={fieldValueChanged}
                completeActivityAction={completeActivityAction}
                validate={validateActivityAction}
                containerControls={containerControls}
            />
        );
    };

    return (
        <ButtonGroup>
            {options.map((option) => {
                if (option.buttonType === 'primary') {
                    return generatePrimaryButton(option);
                } else {
                    return generateSecondaryButton(option);
                }
            })}
        </ButtonGroup>
    );
};

export default FooterSignTogglegroup;

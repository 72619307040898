import React from 'react';
import { ContributionAmountField } from '@csas-smart/gti-ui-comps';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { loadCodebook, loadContributionData } from '../actions/taskActions';
import SessionWrapper from '../sessions/sessionWrapper';

const ContributionAmountFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();
    const sessionWrapper = new SessionWrapper();
    const loadCodebookInternal = (codebook, attributes) => {
        return loadCodebook(codebook, attributes, sessionWrapper.getLanguage());
    };

    return (
        <ContributionAmountField
            {...props}
            hashId={hashId}
            loadContributionData={loadContributionData}
            loadCodebook={loadCodebookInternal}
        />
    );
};

export default ContributionAmountFieldContainer;

/**
 * A common gateway for publishing events into the GA 'window.dataLayer'.
 * This is where all GA events go through.
 */
class GaDataLayer {
    createInitialEvent = (data) => {
        const event = { event: 'initial', ...data };
        console.log('GA:', event);
        window.dataLayer.push(event);
    };

    createPageViewEvent = (data) => {
        const event = {
            event: 'page_view',
            documentPath: '/zadost',
            ...data,
        };
        console.log('GA:', event);
        window.dataLayer.push(event);
    };

    createApplicationEvent = (data) => {
        const event = {
            event: 'application',
            ...data,
        };
        console.log('GA:', event);
        window.dataLayer.push(event);
    };

    createBusinessPhase = (data) => {
        const event = {
            event: 'business_phase',
            ...data,
        };
        console.log('GA:', event);
        window.dataLayer.push(event);
    };

    createPageErrorEvent = (data) => {
        const event = {
            event: 'page_error',
            ...data,
        };
        console.log('GA:', event);
        window.dataLayer.push(event);
    };
}

export default new GaDataLayer();

import { generateState } from '../../utils/objectUtils';
import { stringify } from 'query-string';
import { ADFS_CLIENT } from '../adfs2019service';
import SessionWrapper from '../../../sessions/sessionWrapper';

/**
 * Handler used for api call to ADFS to get code id parameter.
 */
class AdfsCodeHandler {
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * Condition for use this handler says that there must not be the code neither access_token in URL. At the same time
     * there must not be the accessToken parameter in sessionStorage.
     * @param params
     * @returns {boolean}
     */
    canHandle = (params) => {
        return !params?.code && !params?.access_token && !this.sessionWrapper.getAccessToken();
    };

    /**
     * Simple redirection into ADFS_BASE_URL/adfs/oauth2/authorize with specific request parameters.
     * @param params
     */
    handleParams = (params) => {
        this.sessionWrapper.clearLoginCodeReceived();

        const AUTH_PARAMS = {
            response_type: 'code',
            client_id: ADFS_CLIENT,
            scope: 'openid',
            state: generateState(params),
            redirect_uri: import.meta.env.VITE_FE_APP_URL,
        };

        window.location.replace(
            `${import.meta.env.VITE_APP_ADFS_BASE_URL}/adfs/oauth2/authorize?${stringify(
                AUTH_PARAMS,
            )}`,
        );
    };
}

export default AdfsCodeHandler;

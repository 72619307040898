import React, { lazy } from 'react';
import { FieldProps } from '@csas-smart/gti-ui-comps';

const CardLimitsFieldLazy = lazy(() =>
    import('@csas-smart/gti-ui-comps').then((module) => ({ default: module.CardLimitsField })),
);

export const CardLimitsFieldContainer = (props: FieldProps & { attributes: unknown }) => {
    return <CardLimitsFieldLazy webApiUrl={import.meta.env.VITE_APP_WEB_API_URL} {...props} />;
};

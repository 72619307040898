import React from 'react';
import { Spinner } from '@george-labs.com/design-system';

// Lazy loaded GDS dependency
// const Spinner = React.lazy(() => import("@george-labs.com/design-system").then(module => ({default: module.Spinner})))

const GdsSpinner = (props) => {
    return <Spinner screenreaderText={props.header} />;
};

export default GdsSpinner;
